import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Button,
  Typography,
  Card,
  CardContent,
  LinearProgress,
  styled,
  IconButton,
} from "@mui/material";
import { Container, Row, Col } from "reactstrap";
import Modal from "react-modal";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { postApi } from "services/axiosInstance";
import { API_PATH } from "services/apipath";
import { getApi } from "services/axiosInstance";
import { CloseOutlined } from "@mui/icons-material";
import AudioPlayer from "./AudioPlayerAns";
import TextToSpeechPlayer from "./QuestionPlayer";
import { toast } from "react-toastify";

const Analysis = () => {
  const navigate = useNavigate();
  const { id, studentId } = useParams();

  const id1 = window.location.pathname.split("/")[2];

  const handleBack = () => {
    navigate(-1);
  };
  const [loading, setLoading] = useState(true);
  const [attempts, setAttempts] = useState([]);
  const [marksAttempts, setMarksAttempts] = useState([]);
  useEffect(() => {
    const fetchAttempts = async (data) => {
      setLoading(true);
      try {
        const res = await getApi(`${API_PATH.MOCK_TEST.FULL_ATTEMPT}/${id}`);
        if (res.status === 200) {
          setAttempts(res.data);
        } else {
          throw new Error("Failed to fetch data");
        }
      } catch (err) {
        console.log(err, "Error in data ");
      } finally {
        setLoading(false);
      }
    };

    const fetchOverallMarks = async (data) => {
      setLoading(true);
      try {
        const res = await getApi(`${API_PATH.MOCK_TEST.OVERALL}/${id}`);
        if (res.status === 200) {
          setMarksAttempts(res.data.data);
        } else {
          throw new Error("Failed to fetch data");
        }
      } catch (err) {
        console.log(err, "Error in data ");
      } finally {
        setLoading(false);
      }
    };
    fetchAttempts();
    fetchOverallMarks();
  }, []);

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalData, setModalData] = useState([]);

  const [feedbackModal, setFeedbackModal] = useState(false);
  const [feedbutton, setFeedbackButton] = useState(true);
  const [feedback, setfeedback] = useState("");

  const openModal = (data) => {
    setModalIsOpen(true);
    setModalData(data);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setModalData([]);
    setfeedback("");
    setFeedbackButton(true);
  };

  const submitFeedback = async () => {
    setLoading(true);
    try {
      const res = await postApi(`${API_PATH.ADMIN.FEEDBACK}`, {
        studentId: attempts.studentId._id,
        fullTestId: attempts.fullTestId._id,
        questionId: modalData.questionId._id,
        feedback: feedback,
      });
      if (res.status === 200) {
        toast.success("Feedback added successfully");
        setFeedbackModal(false);
        setFeedbackButton(false);
      } else {
        throw new Error("Failed to fetch data");
      }
    } catch (err) {
      console.log(err, "Error in data ");
    } finally {
      setLoading(false);
    }
  };

  const BorderLinearProgress = styled(LinearProgress)({
    height: 8,
    borderRadius: 4,
    backgroundColor: "#e0e0e0",
    "& .MuiLinearProgress-bar": {
      borderRadius: 4,
      backgroundColor: "#4caf50",
    },
  });

  const BorderLinearProgresModal = styled(LinearProgress)({
    height: 8,
    borderRadius: 4,
    backgroundColor: "#e0e0e0",
    "& .MuiLinearProgress-bar": {
      borderRadius: 4,
      backgroundColor: "#4caf50",
    },
  });
  const speakingData = [
    { label: "Read Aloud", score: 60 },
    { label: "Repeat Sentence", score: 30 },
    { label: "Describe Image", score: 52 },
    { label: "Retell Lecture", score: 10 },
    { label: "Short Answer", score: 15 },
  ];

  const writingData = [
    { label: "Essay", score: 30 },
    { label: "Summarize Text", score: 59 },
  ];

  const listeningData = [
    { label: "Summarize Text", score: 60 },
    { label: "Multiple Answers", score: 79 },
    { label: "Fill in the Blanks", score: 60 },
    { label: "Highlight Summary", score: 60 },
    { label: "Single Answer", score: 99 },
    { label: "Missing Words", score: 60 },
    { label: "Incorrect Words", score: 60 },
    { label: "Write From Dictation", score: 60 },
  ];

  const readingData = [
    { label: "R/W Fill in Blanks", score: 60 },
    { label: "Multiple Answers", score: 79 },
    { label: "Reorder Paragraph", score: 59 },
    { label: "Fill in Blanks", score: 60 },
    { label: "Single Answer", score: 99 },
  ];

  const [progress, setProgress] = useState({
    ...speakingData.reduce((acc, item) => ({ ...acc, [item.label]: 0 }), {}),
    ...writingData.reduce((acc, item) => ({ ...acc, [item.label]: 0 }), {}),
    ...listeningData.reduce((acc, item) => ({ ...acc, [item.label]: 0 }), {}),
    ...readingData.reduce((acc, item) => ({ ...acc, [item.label]: 0 }), {}),
  });

  useEffect(() => {
    const allData = [
      ...speakingData,
      ...writingData,
      ...listeningData,
      ...readingData,
    ];
    allData.forEach((item) => {
      setTimeout(() => {
        setProgress((prev) => ({
          ...prev,
          [item.label]: item.score,
        }));
      }, 300);
    });
  }, []);

  const ProgressCard = ({ category, data }) => {
    const per = (score) => {
      const MAX_SCORE = 90;
      const normalizedValue = (score / MAX_SCORE) * 100;
      return normalizedValue;
    };
    return (
      <Card
        sx={{
          flex: "1 1 500px",
          boxShadow: "4px 4px #0000007a",
          minWidth: "300px",
        }}
      >
        <CardContent>
          <Typography variant="h5" sx={{ textAlign: "center", mb: 2 }}>
            {category}
          </Typography>
          {Object.entries(data).map(([label, { overallScore }]) => (
            <Box key={label} sx={{ mb: 3 }}>
              <Box
                sx={{ display: "flex", justifyContent: "space-between", mb: 1 }}
              >
                <Typography variant="body2" color="text.secondary">
                  {label}
                </Typography>
                <Typography variant="body2" color="text.primary">
                  {overallScore}
                </Typography>
              </Box>
              <BorderLinearProgress
                variant="determinate"
                value={per(overallScore)}
              />
            </Box>
          ))}
        </CardContent>
      </Card>
    );
  };

  const progressData = {
    Listening: {
      "Summarize Text": { overallScore: 9, questionCount: 1 },
      "Multiple Answers": { overallScore: 0, questionCount: 1 },
    },
    Speaking: {
      "Read Aloud": { overallScore: 45, questionCount: 1 },
      "Repeat Sentence": { overallScore: 30, questionCount: 1 },
    },
  };
  return (
    <Container className="" fluid>
      <Row
        className="border-bottom shadow py-lg-2 py-2"
        style={{
          backgroundColor: "cadetblue",
          verticalAlign: "middle !important",
        }}
      >
        <Col xl="2">
          <Button
            variant="outlined" 
            style={{ marginTop: "13px" , border:"none" }}
            onClick={handleBack}
          >
            <ArrowBackIosIcon /> Back
          </Button>
        </Col>

        <Col xl="8" className="planon">
          <h2 className="text-center text-white display-3">
            {/* Analysis for Attempt {attempts?.fullTestId.TestName} */}
          </h2>
        </Col>

        <Col xl="2"></Col>
      </Row>

      <Row
        className=" shadow py-lg-2 py-2"
        style={{ backgroundColor: "cadetblue" }}
      >
        <Col xl="12">
          <h1 className="text-center text-white">Categories</h1>
        </Col>
      </Row>

      <Row
        className="shadow py-lg-4 py-4"
        style={{ backgroundColor: "cadetblue" }}
      >
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap", // Ensures items wrap on smaller screens
            gap: 4, // Maintains spacing between cards
            mx: "auto", // Centers the container
            justifyContent: "center",
            width: "100%", // Ensures responsiveness
            maxWidth: { xs: "90%", sm: "80%", md: "75%", lg: "1200px" }, // Adjusts width based on screen size
          }}
        >
          {Object.entries(marksAttempts).map(([category, data]) => (
            <ProgressCard key={category} category={category} data={data} />
          ))}
        </Box>
      </Row>

      <Row
        className="border-top shadow py-lg-2 py-2"
        style={{ backgroundColor: "cadetblue" }}
      >
        <Col xl="12" className="planon">
          <h1 className="text-center text-white">Questions</h1>
        </Col>
      </Row>

      <Row
        className="shadow py-lg-4 py-4 d-flex justify-content-center"
        style={{ backgroundColor: "cadetblue" }}
      >
        {attempts?.report?.map((data, i) => (
          <Row
            key={i + 1}
            style={{
              backgroundColor: "#1c1e29",
              borderRadius: "8px",
              padding: "10px",
              width: "90%",
            }}
            className=" align-items-center mb-4"
          >
            <div className="col-6">
              <Typography variant="h6" sx={{ color: "#d1d5db" }}>
                {data.questionId.question_title} - {data.module}{" "}
                {data.sub_module}
              </Typography>
            </div>

            <div className="col-6 d-flex justify-content-end">
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "green",
                  color: "white !important",
                  borderRadius: "8px",
                  fontWeight: "bold",
                  paddingX: "40px",
                }}
                onClick={() => openModal(data)}
              >
                {data.score.overall | 0}/{data.score.total | 0}
              </Button>
            </div>
          </Row>
        ))}

        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          contentLabel="Read Aloud Modal"
          style={{
            content: {
              top: "50%",
              left: "50%",
              right: "auto",
              bottom: "auto",
              marginRight: "-50%",
              transform: "translate(-50%, -50%)",
              backgroundColor: "#2d2f3a",
              color: "#d1d5db",
              borderRadius: "8px",
              padding: "20px",
              // width: "70%",
            },
            overlay: {
              backgroundColor: "rgba(0, 0, 0, 0.75)",
            },
          }}
        >
          <div style={{ textAlign: "center" }}>
            <div className="d-flex justify-content-between">
              <Typography
                variant="h5"
                sx={{ color: "#d1d5db", marginBottom: "20px" }}
              >
                {modalData.sub_module}
              </Typography>

              <IconButton>
                <CloseOutlined
                  onClick={closeModal}
                  sx={{
                    color: "red",
                  }}
                />
              </IconButton>
            </div>

            <div className="d-flex align-items-center justify-content-evenly mt-3">
              <div className=" ">
                {modalData.sub_module === "Describe Image" && (
                  <img
                    src={`${process.env.REACT_APP_BASE_URL}/${modalData.questionId.image}`}
                    width={300}
                    alt=""
                  />
                )}
                {/* Scoreing section  */}
                <div
                  style={{
                    // display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "15px",
                  }}
                >
                  <div className="d-flex  align-items-center justify-content-center">
                    <Typography variant="h6" sx={{ color: "#d1d5db" }}>
                      Score : {modalData?.calculatedOverallScore | 0}/
                      {modalData?.score?.total | 0}
                    </Typography>
                  </div>
                  <div className="d-flex  align-items-center">
                    <Typography variant="p" sx={{ color: "#d1d5db" }}>
                      Content - {modalData?.score?.content} /{" "}
                      {modalData?.score?.total}
                    </Typography>
                    <Box
                      sx={{
                        flex: 2,
                        height: "6px",
                        bgcolor: "#F0F0F0",
                        borderRadius: "3px",
                        position: "relative",
                        mx: 2,
                        width: "60%",
                      }}
                    >
                      <Box
                        sx={{
                          position: "absolute",
                          left: 0,
                          top: 0,
                          height: "100%",
                          paddingRight: `${
                            (modalData?.score?.content /
                              modalData?.score?.total) *
                            100
                          }%`,
                          bgcolor: "#00E676",
                          borderRadius: "3px",
                        }}
                      />
                    </Box>
                  </div>
                  <div className="d-flex  align-items-center ">
                    <Typography variant="p" sx={{ color: "#d1d5db" }}>
                      Pronun - {modalData?.score?.pronunciation} /{" "}
                      {modalData?.score?.total}
                    </Typography>

                    <Box
                      sx={{
                        flex: 2,
                        height: "6px",
                        bgcolor: "#F0F0F0",
                        borderRadius: "3px",
                        position: "relative",
                        mx: 2,
                        width: "60%",
                      }}
                    >
                      <Box
                        sx={{
                          position: "absolute",
                          left: 0,
                          top: 0,
                          height: "100%",
                          paddingRight: `${
                            (modalData?.score?.pronunciation /
                              modalData?.score?.total) *
                            100
                          }%`,
                          bgcolor: "#00E676",
                          borderRadius: "3px",
                        }}
                      />
                    </Box>
                  </div>
                  <div className="d-flex  align-items-center ">
                    <Typography variant="p" sx={{ color: "#d1d5db" }}>
                      Fluency - {modalData?.score?.fluency} /{" "}
                      {modalData?.score?.total}
                    </Typography>

                    <Box
                      sx={{
                        flex: 2,
                        height: "6px",
                        bgcolor: "#F0F0F0",
                        borderRadius: "3px",
                        position: "relative",
                        mx: 2,
                        width: "60%",
                      }}
                    >
                      <Box
                        sx={{
                          position: "absolute",
                          left: 0,
                          top: 0,
                          height: "100%",
                          paddingRight: `${
                            (modalData?.score?.fluency /
                              modalData?.score?.total) *
                            100
                          }%`,
                          bgcolor: "#00E676",
                          borderRadius: "3px",
                        }}
                      />
                    </Box>
                  </div>
                </div>

                {/* question and ans */}
                {modalData && modalData.sub_module !== "Describe Image" && (
                  <div className="d-flex">
                    {modalData?.questionId?.audio_text && (
                      <div className="me-5">
                        <TextToSpeechPlayer
                          text={
                            modalData?.questionId?.audio_text
                              ? modalData?.questionId?.audio_text
                              : "hello world"
                          }
                        />
                      </div>
                    )}

                    {modalData?.answer_audio && (
                      <div className="">
                        <AudioPlayer
                          src={`${process.env.REACT_APP_BASE_URL}/${modalData?.answer_audio}`}
                        />
                      </div>
                    )}
                  </div>
                )}
              </div>
              <div
                className="  border border-success  rounded"
                style={{
                  marginLeft: "10px",
                  width: "300px",
                  height: "200px",
                }}
              >
                {modalData.student_ans}
              </div>
            </div>
            <div className="d-flex justify-content-between mt-4">
              {modalData.feedback ? (
                <p>
                  {modalData.feedback && "Feedback -"} {modalData.feedback}
                </p>
              ) : (
                <p>
                  {feedback && "Feedback -"} {feedback}
                </p>
              )}

              {feedbutton === true && modalData.feedback === "" && (
                <button
                  className="btn btn-success"
                  onClick={() => setFeedbackModal(true)}
                >
                  Add Feedback
                </button>
              )}
            </div>
          </div>
        </Modal>

        <Modal
          isOpen={feedbackModal}
          onRequestClose={() => {
            setFeedbackModal(false);
            setfeedback("");
            setFeedbackButton(true);
          }}
          contentLabel="Read Aloud Modal"
          style={{
            content: {
              top: "50%",
              left: "50%",
              right: "auto",
              bottom: "auto",
              marginRight: "-50%",
              transform: "translate(-50%, -50%)",
              backgroundColor: "#2d2f3a",
              color: "#d1d5db",
              borderRadius: "8px",
              padding: "20px",
              width: "70%",
            },
            overlay: {
              backgroundColor: "rgba(0, 0, 0, 0.75)",
            },
          }}
        >
          <div style={{ textAlign: "center" }}>
            <div className="d-flex justify-content-between">
              <Typography
                variant="h5"
                sx={{ color: "#d1d5db", marginBottom: "20px" }}
              >
                Add Feedback
              </Typography>

              <IconButton>
                <CloseOutlined
                  onClick={() => setFeedbackModal(false)}
                  sx={{
                    color: "red",
                  }}
                />
              </IconButton>
            </div>

            <div className="d-flex align-items-center justify-content-evenly mt-3">
              <textarea
                className="w-100 bg-transparent text-white"
                name=""
                id=""
                rows={9}
                resize="none"
                onChange={(e) => setfeedback(e.target.value)}
              />
            </div>

            <div className="d-flex justify-content-end mt-4">
              <button
                className="btn btn-success"
                onClick={() => submitFeedback()}
              >
                Submit Feedback
              </button>
            </div>
          </div>
        </Modal>
      </Row>
    </Container>
  );
};

export default Analysis;
