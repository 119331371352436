import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { Typography } from '@mui/material';
import { postApi } from 'services/axiosInstance';
import { API_PATH } from 'services/apipath';

import ReadAloudMock from '../speaking/RAQ/answers';
import RepeatSentenceAnswersMock from '../speaking/RS/answers';
import ImageDescriptionMock from '../speaking/DIQ/answers';
import ShortAnswerMock from '../speaking/SAQ/answers';
import RespondToSituationMock from '../speaking/RTS/answers';
// import EssayAnswersMock from '../writing/ESSAY/answers';

import SummarizeTextAcedimicMock from '../writing/STA/answers';
import WriteEmailMock from '../writing/WE/answers';

import FillInBlanksSelectMock from '../reading/RWF/answers';
import SingleAnswerReadingMock from '../reading/SA/answers';
import MultipleAnswerReadingMock from '../reading/MA/answers';
import ReorderParagraphsMock from '../reading/ROP/answers';
import FillInBlanksMock from '../reading/FITB/answers';


import SingleAnswerListeningMock from '../listening/SA/answers';
import MultipleAnswerListeningMock from '../listening/MA/answers';
import DictationAnswersMock from '../listening/WFD/dictationanswers';
import HighlightSummaryAnswerMock from '../listening/HSQ/answers';
import IncorrectWordsMock from '../listening/IWQ/answers';
import FillInBlanksAnswerMock from '../listening/FTBQ/answers';
import MissingWordAnswerMock from '../listening/MWQ/answers';
import {
  Box,
  Container,
} from "@mui/material";
import AccessAlarmsIcon from '@mui/icons-material/AccessAlarms';

import FullMockTest from '../fulltest/answers';

export default function MockTestQuestionRender() {
  const { category, subcategory, base } = useParams();

  const [questionDta, setQuestionsDta] = useState([]);

  // Countdown logic
  const [timeRemaining, setTimeRemaining] = useState(category === 'Full Test' ? 140 * 60 : 30 * 60); // 30 minutes in seconds
  useEffect(() => {
    if (timeRemaining > 0) {
      const timer = setTimeout(() => setTimeRemaining((prev) => prev - 1), 1000);
      return () => clearTimeout(timer); // Cleanup on unmount or update
    }
  }, [timeRemaining]);

  // Convert timeRemaining (in seconds) to minutes and seconds
  const formatTime = () => {
    const hours = Math.floor(timeRemaining / 3600);
    const minutes = Math.floor((timeRemaining % 3600) / 60);
    const seconds = timeRemaining % 60;

    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
  };

  // <============================>
  const GetIds = async () => {
    try {
      const res = await postApi(
        `${API_PATH.STUDENTS.GET_QUESTIONS_IDS_BY_CATEGORY}/${category}`,
        {
          subcategory: subcategory,
          base: base !== 'undefined' ? base : 'Core'
        }
      );
      if (res.status === 200) {
        setQuestionsDta(res.data.data);
      }
    } catch (error) { }
  };

  useEffect(() => {
    GetIds();
  }, []);

  const PickPage = () => {
    // Define the mapping of category-subcategory to components
    const categorySubcategoryMap = {
      "Speaking": {
        "Read Aloud": <ReadAloudMock questionData={questionDta} />,
        "Repeat Sentence": <RepeatSentenceAnswersMock questionData={questionDta} />,
        "Describe Image": <ImageDescriptionMock questionData={questionDta} />,
        "Respond to Situation": <RespondToSituationMock questionData={questionDta} />,
        "Short Answer": <ShortAnswerMock questionData={questionDta} />,
      },

      "Writing": {
        // "Essay": <EssayAnswersMock questionData={questionDta} />,
        "Summarize Text": <SummarizeTextAcedimicMock questionData={questionDta} />,
        "Write Email": <WriteEmailMock questionData={questionDta} />,
      },

      "Reading": {
        "RW Fill in Blanks": <FillInBlanksSelectMock questionData={questionDta} />,
        "Multiple Answers": <MultipleAnswerReadingMock questionData={questionDta} />,
        "Reorder Paragraph": <ReorderParagraphsMock questionData={questionDta} />,
        "Fill in Blanks": <FillInBlanksMock questionData={questionDta} />,
        "Single Answer": <SingleAnswerReadingMock questionData={questionDta} />,
      },

      "Listening": {
        "Single Answer": <SingleAnswerListeningMock questionData={questionDta} />,
        "Multiple Answers": <MultipleAnswerListeningMock questionData={questionDta} />,
        "Write From Dictation": <DictationAnswersMock questionData={questionDta} />,
        "Highlight Summary": <HighlightSummaryAnswerMock questionData={questionDta} />,
        "Incorrect Words": <IncorrectWordsMock questionData={questionDta} />,
        "Fill in The Blanks": <FillInBlanksAnswerMock questionData={questionDta} />,
        "Missing Word": <MissingWordAnswerMock questionData={questionDta} />,
        // "Summarize Text": <SummarizeTextAcedimicMock questionData={questionDta} />,
        // "Summarize Text Core": <SummarizeTextCore questionData={questionDta} />,
      }
    };

    // Check if category and subCategory exist in the map
    const categoryMap = categorySubcategoryMap[category];


    if (categoryMap) {
      const component = categoryMap[subcategory];
      if (component) {
        return component;
      }
    } else if (category === "Full Test") {
      return <FullMockTest category={'Speaking'} subcategory={'Read Aloud'} base={'core'} />;
    }

    // Default case when no match is found
    return <div>No component for this category</div>;
  };

  const header = () => {
    const date = new Date()
    const currentMonthAndDate = `${String(date.getMonth() + 1).padStart(2, "0")}-${date.getFullYear()}`;
    return (<header>
      <Box
        sx={{
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bgcolor: "white",
          zIndex: 1000,
        }}
      >
        <Container maxWidth="lg">
          <Box
            sx={{
              p: 2,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              borderBottom: "5px solid #80CBC4",
              verticalAlign: "middle",
            }}
          >
            {/* <IconButton sx={{ color: "#fff" }} onClick={() => navigate(-1)}>
              <i className="fas fa-arrow-left" />
            </IconButton> */}

            <Typography variant="h6" sx={{ color: "black", ml: "20px" }}>
              {currentMonthAndDate} #123
            </Typography>

            <Box
              sx={{ display: "flex", alignItems: "center", gap: 1, ml: "auto" }}
            >
              <Typography sx={{ color: "black", fontSize: "15px", marginRight: "16px" }}>
                {/* {currentQuestion}/{questionData.totalQuestions} */}
                1/28
              </Typography>

              <Box sx={{ color: "#000000", border: "1px solid grey", paddingX: "5px", paddingY: "3px", borderRadius: "6px", display: "flex", justifyContent: "space-evenly", verticalAlign: "middle !important", }}>
                <AccessAlarmsIcon sx={{ marginRight: "5px", fontSize: "16px !important", marginTop: "4px" }} />

                <Typography sx={{ fontSize: "15px" }}>
                  {formatTime()}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>

    </header>)
  }
  return <>{header()}{PickPage()}</>;

}
