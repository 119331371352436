import Card from "@mui/material/Card";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Select from "react-select";
import { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import SendIcon from "@mui/icons-material/Send";
import { Tabs, Tab } from "@mui/material";
import { School, Assignment } from "@mui/icons-material";
import LocalLibraryIcon from "@mui/icons-material/LocalLibrary";
import SchoolIcon from "@mui/icons-material/School";
import TestRecorderModal from "components/Modal/TestRecorder";
import StartTestModal from "components/Modal/StartTestModal";
import StartFullTestModal from "components/Modal/StartFullTestModal";
import { postApi } from "services/axiosInstance";
import { API_PATH } from "services/apipath";

import Pagination from '@mui/material/Pagination';

function Mocktest() {
  const options = [
    { value: "Full Test", label: "Full Test" },
    { value: "Listening", label: "Listening" },
    { value: "Speaking", label: "Speaking" },
    { value: "Reading", label: "Reading" },
    { value: "Writing", label: "Writing" },
  ];

  const subCategoryOptions = [
    { label: "Full Test", subCategory: "Full Test" },
    { label: "Listening", subCategory: "Single Answer" },
    { label: "Speaking", subCategory: "Read Aloud" },
    { label: "Reading", subCategory: "Reorder Paragraph" },
    { label: "Writing", subCategory: "Summarize Text" },
  ];

  const [status, setStatus] = useState("Full Test"); // selected dropdown option
  const [activeTab, setActiveTab] = useState(0); // active tab (Core or Academic)

  const handleTabChange = (event, newValue) => {
    console.log(newValue);
    setActiveTab(newValue);
  };

  const [mockTestData, setMockTestData] = useState([]);
  const [open, setOpen] = useState(false);
  const [startModalOpen, setStartModalOpen] = useState(false);
  const [startFullTestOpen, setStartFullTestOpen] = useState(false); // For Start Full Test Modal
  const [path, setPath] = useState("/mocktest");
  const [type, setType] = useState(0);
  const openModal = (type, base, id) => {
    setType(type);
    setOpen(true);
    const option = options.find((opt) => opt.value === type);
    const subCategory = subCategoryOptions.find(
      (opt) => opt.label === option.label
    );
    setPath(`/mock-test/${id}`)
  };
  const openStartModal = () => {
    if (type === "Full Test") {
      // Open StartFullTestModal
      setStartFullTestOpen(true);
    } else {
      // Open StartTestModal
      setStartModalOpen(true);
    }
  };

  // Core Plan Data
  const corePlanTests = [
    {
      id: "Question 125",
      questions: "60 Questions",
      timing: "60 Min",
      type: 0,
      base: "Core",
    },
    {
      id: "Question 124",
      questions: "30 Questions",
      timing: "30 Min",
      type: 1,
      base: "Core",
    },
    {
      id: "Question 123",
      questions: "40 Questions",
      timing: "60 Min",
      type: 1,
      base: "Core",
    },
    {
      id: "Question 122",
      questions: "50 Questions",
      timing: "50 Min",
      type: 1,
      base: "Core",
    },
    {
      id: "Question 121",
      questions: "30 Questions",
      timing: "20 Min",
      type: 1,
      base: "Core",
    },
    {
      id: "Question 120",
      questions: "60 Questions",
      timing: "60 Min",
      type: 2,
      base: "Core",
    },
    {
      id: "Question 119",
      questions: "50 Questions",
      timing: "45 Min",
      type: 2,
      base: "Core",
    },
    {
      id: "Question 118",
      questions: "30 Questions",
      timing: "30 Min",
      type: 2,
      base: "Core",
    },
    {
      id: "Question 117",
      questions: "40 Questions",
      timing: "30 Min",
      type: 2,
      base: "Core",
    },
    {
      id: "Question 116",
      questions: "50 Questions",
      timing: "60 Min",
      type: 3,
      base: "Core",
    },
    {
      id: "Question 115",
      questions: "60 Questions",
      timing: "60 Min",
      type: 3,
      base: "Core",
    },
    {
      id: "Question 114",
      questions: "30 Questions",
      timing: "20 Min",
      type: 3,
      base: "Core",
    },
    {
      id: "Question 113",
      questions: "40 Questions",
      timing: "30 Min",
      type: 3,
      base: "Core",
    },
    {
      id: "Question 112",
      questions: "40 Questions",
      timing: "45 Min",
      type: 4,
      base: "Core",
    },
    {
      id: "Question 111",
      questions: "35 Questions",
      timing: "35 Min",
      type: 4,
      base: "Core",
    },
    {
      id: "Question 110",
      questions: "30 Questions",
      timing: "30 Min",
      type: 4,
      base: "Core",
    },
    {
      id: "Question 109",
      questions: "50 Questions",
      timing: "45 Min",
      type: 4,
      base: "Core",
    },
    {
      id: "Question 108",
      questions: "10 Questions",
      timing: "20 Min",
      type: 4,
      base: "Core",
    },
  ];

  // Academic Plan Data
  const academicPlanTests = [
    {
      id: "Question 200",
      questions: "60 Questions",
      timing: "90 Min",
      type: 0,
      base: "Academic",
    },
    {
      id: "Question 199",
      questions: "50 Questions",
      timing: "45 Min",
      type: 1,
      base: "Academic",
    },
    {
      id: "Question 198",
      questions: "40 Questions",
      timing: "40 Min",
      type: 1,
      base: "Academic",
    },
    {
      id: "Question 197",
      questions: "30 Questions",
      timing: "30 Min",
      type: 1,
      base: "Academic",
    },
    {
      id: "Question 196",
      questions: "40 Questions",
      timing: "40 Min",
      type: 1,
      base: "Academic",
    },
    {
      id: "Question 195",
      questions: "50 Questions",
      timing: "50 Min",
      type: 1,
      base: "Academic",
    },
    {
      id: "Question 194",
      questions: "40 Questions",
      timing: "40 Min",
      type: 2,
      base: "Academic",
    },
    {
      id: "Question 193",
      questions: "50 Questions",
      timing: "60 Min",
      type: 2,
      base: "Academic",
    },
    {
      id: "Question 192",
      questions: "60 Questions",
      timing: "60 Min",
      type: 2,
      base: "Academic",
    },
    {
      id: "Question 191",
      questions: "30 Questions",
      timing: "30 Min",
      type: 2,
      base: "Academic",
    },
    {
      id: "Question 190",
      questions: "60 Questions",
      timing: "60 Min",
      type: 3,
      base: "Academic",
    },
    {
      id: "Question 189",
      questions: "40 Questions",
      timing: "50 Min",
      type: 3,
      base: "Academic",
    },
    {
      id: "Question 188",
      questions: "20 Questions",
      timing: "20 Min",
      type: 3,
      base: "Academic",
    },
    {
      id: "Question 187",
      questions: "40 Questions",
      timing: "30 Min",
      type: 3,
      base: "Academic",
    },
    {
      id: "Question 186",
      questions: "50 Questions",
      timing: "50 Min",
      type: 4,
      base: "Academic",
    },
    {
      id: "Question 185",
      questions: "40 Questions",
      timing: "60 Min",
      type: 4,
      base: "Academic",
    },
    {
      id: "Question 184",
      questions: "30 Questions",
      timing: "30 Min",
      type: 4,
      base: "Academic",
    },
    {
      id: "Question 183",
      questions: "35 Questions",
      timing: "40 Min",
      type: 4,
      base: "Academic",
    },
  ];

  const testData = activeTab === 0 ? corePlanTests : academicPlanTests;

  const filteredTestData =
    status === 0 ? testData : testData.filter((test) => test.type === status);

  const getAllTests = async () => {
    try {
      const res = await postApi(`${API_PATH.MOCK_TEST.ALL_TESTS}`, {
        category: status,
        base: activeTab,
      });
      if (res.status === 200) {
        setMockTestData(res.data.data);
      }
    } catch (error) { }
  };

  useEffect(() => {
    getAllTests();
  }, [status, activeTab]);




  const [page, setPage] = useState(1);
  const [itemsPerPage] = useState(8);

  // Add this pagination change handler after other handlers
  const handlePageChange = (event, value) => {
    setPage(value);
  };
  return (
    <DashboardLayout>
      <Tabs
        value={activeTab}
        onChange={handleTabChange}
        centered
        TabIndicatorProps={{
          style: { display: "none" },
        }}
      >
        <Tab
          icon={
            <LocalLibraryIcon
              sx={{
                backgroundColor: "white",
                color: "white",
                borderRadius: "5px",
                width: "20%",
                height: "30px",
              }}
            />
          }
          label="Core Plan"
          sx={{
            minWidth: 80,
            textTransform: "none",
            height: 80,
            fontWeight: "500",
            fontSize: "20px",
            marginRight: "10px",
            border: "1px solid black",
            backgroundColor: activeTab === 0 ? "#FC8A70" : "#FDC4B6", // Change color when active
          }}
        />

        <Tab
          icon={
            <SchoolIcon
              sx={{
                backgroundColor: "white",
                color: "white",
                borderRadius: "5px",
                width: "20%",
                height: "30px",
              }}
            />
          }
          label="Academic Plan"
          sx={{
            minWidth: 80,
            textTransform: "none",
            height: 80,
            fontWeight: "500",
            fontSize: "20px",
            border: "1px solid black",
            backgroundColor: activeTab === 1 ? "#3DC4E3" : "#B5F0FA", // Change color when active
          }}
        />
      </Tabs>

      <SoftBox pt={3}>
        <SoftBox >
          <Card sx={{ height: "650px" }}>
            <SoftBox
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              p={3}
            >
              <SoftTypography variant="h5">
                {activeTab === 0 ? (
                  <>
                    <School style={{ verticalAlign: "middle", marginRight: 6, fontSize: "24px !important" }} />
                    Core Plan Tests ({options.find((opt) => opt.value === status)?.label})
                  </>
                ) : (
                  <>
                    <Assignment style={{ verticalAlign: "middle", marginRight: 6, fontSize: "24px !important" }} />
                    Academic Plan Tests ({options.find((opt) => opt.value === status)?.label})
                  </>
                )}
              </SoftTypography>

              <Select
                className="dropdown"
                options={options}
                onChange={(e) => setStatus(e.value)}
              />
            </SoftBox>
            {/* Display Filtered Test Data */}
            <SoftBox sx={{
              // minHeight: '340px'
            }}>
              <Grid
                container
                direction="row"
                sx={{ justifyContent: "center", alignItems: "center" }}
              >
                <Grid
                  item
                  xs={3}
                  sm={6}
                  xl={3}
                  sx={{
                    textAlign: "left",
                    paddingLeft: "22px",
                    fontWeight: "500",
                  }}
                >
                  Question ID/Name
                </Grid>
                <Grid
                  item
                  xs={3}
                  sm={6}
                  xl={3}
                  sx={{ textAlign: "center", fontWeight: "500" }}
                >
                  Total Questions
                </Grid>
                <Grid
                  item
                  xs={3}
                  sm={6}
                  xl={3}
                  sx={{ textAlign: "center", fontWeight: "500" }}
                >
                  Timing
                </Grid>
                <Grid
                  item
                  xs={3}
                  sm={6}
                  xl={3}
                  sx={{ textAlign: "center", fontWeight: "500" }}
                >
                  Action
                </Grid>
              </Grid>

              {mockTestData
                .slice((page - 1) * itemsPerPage, page * itemsPerPage)
                .map((test, index) => (
                  <Grid
                    container
                    direction="row"
                    key={index}
                    sx={{
                      justifyContent: "center",
                      alignItems: "center",
                      marginY: "15px",
                    }}
                  >
                    <Grid
                      item
                      xs={3}
                      sm={6}
                      xl={3}
                      sx={{
                        textAlign: "left",
                        paddingLeft: "22px",
                        fontSize: "16px",
                      }}
                    >
                      {test.TestName}
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      sm={6}
                      xl={3}
                      sx={{ textAlign: "center", fontSize: "16px" }}
                    >
                      {test.QuestionCount}
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      sm={6}
                      xl={3}
                      sx={{ textAlign: "center", fontSize: "16px" }}
                    >
                      {test.TotalTime}
                    </Grid>
                    <Grid item xs={3} sm={6} xl={3} sx={{ textAlign: "center" }}>
                      <Button
                        variant="contained"
                        className="btnmock"
                        endIcon={<SendIcon />}
                        onClick={() => openModal(test.TestCategory, test.planType, test._id)}
                      >
                        Start
                      </Button>
                    </Grid>
                  </Grid>
                ))}
            </SoftBox>

            {/* Add Pagination */}
            <SoftBox
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                py: 2,
                borderTop: "1px solid #eee"
              }}
            >
              <Pagination
                count={Math.ceil(mockTestData.length / itemsPerPage)}
                page={page}
                onChange={handlePageChange}
                color="primary"
                size="large"
                showFirstButton
                showLastButton
                sx={{
                  // "& .MuiPaginationItem-root": {
                  //   backgroundColor: "#FC8A70", // Change background color
                  //   color: "white", // Change text color
                  // },
                  "& .MuiPaginationItem-root.Mui-selected": {
                    backgroundColor: "#D66A56",
                    color: "white",
                  },
                  "& .MuiPaginationItem-root:hover": {
                    backgroundColor: "#E07B65",
                  },
                }}
              />

            </SoftBox>
          </Card>
        </SoftBox>
      </SoftBox>

      <TestRecorderModal
        open={open}
        setOpen={setOpen}
        openStartModal={openStartModal}
      />
      <StartTestModal
        startModalOpen={startModalOpen}
        setStartModalOpen={setStartModalOpen}
        path={path}
      />
      <StartFullTestModal
        open={startFullTestOpen}
        setOpen={setStartFullTestOpen}
        path={path}
      />
    </DashboardLayout>
  );
}
export default Mocktest;