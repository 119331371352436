// @mui material components
import Grid from "@mui/material/Grid";

//  Dashboard React components
import SoftBox from "components/SoftBox";

//  Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

import "../../style.css";

import BillingInformation from "layouts/history/components/BillingInformation";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { useState } from "react";
import PropTypes from "prop-types";
import MockHistory from "layouts/mock-history/MockHistory";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 1 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function History() {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <DashboardLayout>
      <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
        <Tabs value={value} onChange={handleChange} centered>
          <Tab
            label="Practice"
            {...a11yProps(0)}
            sx={{
              ".css-17p3bg5-MuiButtonBase-root-MuiTab-root ": {
                color: "red !important",
              },
            }}
          />
          <Tab label="Mock test" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <SoftBox>
          <SoftBox>
            <Grid container>
              <Grid item xs={12} md={12}>
                <BillingInformation />
              </Grid>
            </Grid>
          </SoftBox>
        </SoftBox>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <MockHistory />
      </CustomTabPanel>
    </DashboardLayout>
  );
}

export default History;
