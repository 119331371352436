import React, { useEffect, useState } from "react";
import { Tabs, Tab, Card, Typography, Box } from "@mui/material";
import CampaignIcon from "@mui/icons-material/Campaign";
import HearingIcon from "@mui/icons-material/Hearing";
import EditIcon from "@mui/icons-material/Edit";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import { Chip } from "@mui/material";

import "../../style.css";

import { Link, useLocation, useNavigate } from "react-router-dom";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import styled from "@emotion/styled";

const HeaderContainer = styled(Box)(({ theme }) => ({
  backgroundColor: "#F0F8FF",
  margin: "12px 0",
  // cursor: "pointer",
  // padding: "16px",
  // backgroundColor: "#2A2D3E",
  borderRadius: "16px",
  padding: theme.spacing(3),
  position: "relative",
  overflow: "hidden",
  minHeight: "100px",
  display: "flex",
  alignItems: "center",
}));

const AcademicChip = styled(Chip)(({ theme, text }) => ({
  backgroundColor: text === "Core" ? "#FFB300 !important" : "#03A9F4",
  color: "white",
  fontWeight: "bold",
  borderRadius: "4px",
  height: "28px",
}));

const AIChip = styled(Chip)(({ theme }) => ({
  backgroundColor: "#FFB300",
  color: "white",
  fontWeight: "bold",
  borderRadius: "4px",
  height: "28px",
  position: "absolute",
  right: theme.spacing(3),
  top: theme.spacing(3),
}));

function Dashboard() {
  const [activeTab, setActiveTab] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const tabParam = params.get("tab");

  useEffect(() => {
    // Get the tab parameter from URL

    // Map tab names to their index
    const tabMapping = {
      Speaking: 0,
      Writing: 1,
      Reading: 2,
      Listening: 3,
    };
    if (tabParam) {
      setActiveTab(tabMapping[tabParam]);
    }
    // Set active tab if valid parameter exists
    if (tabParam && tabMapping.hasOwnProperty(tabParam.toLowerCase())) {
      setActiveTab(tabMapping[tabParam.toLowerCase()]);
    }
  }, [tabParam]);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
    const tabNames = ["Speaking", "Writing", "Reading", "Listening"];
    localStorage.setItem("currentPage", 1);
    navigate(`?tab=${tabNames[newValue]}`);
  };

  const handleCardClick = (path) => {
    navigate(path);
  };

  // Modify the handleCardClick to navigate instead of alerting
  const handleCardClickpath = (category, subCategory) => {
    const path = `/${category}/${subCategory}`;
    navigate(path);
  };

  // Speaking section items
  const speakingItems = [
    {
      label: "Read Aloud",
      path: "/speaking/RAQ",
      chips: ["New", "Prediction", "Exam", "VIP"],
    },

    {
      label: "Repeat Sentence",
      path: "/speaking/RS",
      message: "Repeat Sentence card clicked!",
      chips: ["New", "VIP"],
    },
    {
      label: "Describe Image",
      message: "Describe Image card clicked!",
      path: "/speaking/DIQ",
    },
    // {
    //   label: "Retell Lecture",
    //   message: "Re-tell Lecture card clicked!",
    //   base: "Core",
    // },
    {
      label: "Short Answer",
      message: "Short Answer card clicked!",
      path: "/speaking/SAQ",
    },
    {
      label: "Retell Lecture",
      message: "Re-tell Lecture card clicked!",
      base: "Academic",
    },
    {
      label: "Respond to Situation",
      message: "Respond to Situation card clicked!",
      base: "Core",
    },
  ];

  // Writing section items
  const writingItems = [
    {
      label: "Essay",
      message: "Essay card clicked!",
      path: "/writing/ESSAY",
      base: "Academic",
    },
    {
      label: "Summarize Text",
      message: "Summarize Text card clicked!",
      base: "Academic",
      ai: true,
    },
    {
      label: "Write Email",
      message: "Write Email card clicked!",
      base: "Core",
    },
    {
      label: "Summarize Text",
      message: "Summarize Text card clicked!",
      base: "Core",
      ai: true,
    },
  ];

  // Reading section items
  const readingItems = [
    { label: "Single Answer", message: "Single Answer card clicked!" },
    {
      label: "Multiple Answers",
      message: "Multiple Answers card clicked!",
      path: "/reading/MA",
    },
    {
      label: "Reorder Paragraph",
      message: "Reorder Paragraph card clicked!",
      path: "/reading/ROP",
    },
    { label: "Fill in Blanks", path: "/reading/FITB" },
    {
      label: "RW Fill in Blanks",
      message: "RW Fill in Blanks card clicked!",
      path: "/reading/RWF",
    },
  ];

  // Listening section items
  const listeningItems = [
    {
      label: "Single Answer",
      message: "Single Answer card clicked!",
      path: "/listening/SMCQ",
    },
    {
      label: "Multiple Answers",
      message: "Multiple Answers card clicked!",
      path: "/listening/MCQ",
    },
    {
      label: "Write From Dictation",
      message: "Write From Dictation card clicked!",
      path: "/listening/WFD",
    },
    {
      label: "Highlight Summary",
      message: "Highlight Summary card clicked!",
      path: "/listening/HSQ",
      base: "Academic",
    },
    {
      label: "Incorrect Words",
      message: "Incorrect Words card clicked!",
      path: "/listening/IWQ",
    },
    {
      label: "Fill in The Blanks",
      message: "Fill in The Blanks card clicked!",
      path: "/listening/FTBQ",
    },
    {
      label: "Summarize Text",
      message: "Summarize Text card clicked!",
      path: "/listening/STA",
      base: "Academic",
      ai: true,
    },
    {
      label: "Missing Word",
      message: "Missing Word card clicked!",
      path: "/listening/MWQ",
    },
    {
      label: "Summarize Text",
      message: "Summarize Text card clicked!",
      base: "Core",
      ai: true,
    },
  ];

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Box>
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          centered
          TabIndicatorProps={{
            style: { display: "none" },
          }}
        >
          <Tab
            className="tabresponsive"
            icon={
              <CampaignIcon
                sx={{
                  backgroundColor: "white",
                  color: "white",
                  borderRadius: "5px",
                  width: "20%",
                  height: "30px",
                }}
              />
            }
            label="Speaking"
            sx={{
              minWidth: 80,
              textTransform: "none",
              height: 80,
              fontWeight: "500",
              fontSize: "20px",
              marginRight: "10px",
              backgroundColor: activeTab === 0 ? "#FC8A70" : "#FDC4B6",
            }}
          />

          <Tab
            className="tabresponsive"
            icon={
              <EditIcon
                sx={{
                  backgroundColor: "white",
                  color: "white",
                  borderRadius: "5px",
                  width: "20%",
                  height: "30px",
                }}
              />
            }
            label="Writing"
            sx={{
              minWidth: 80,
              textTransform: "none",
              height: 80,
              fontWeight: "500",
              fontSize: "20px",
              marginRight: "10px",
              backgroundColor: activeTab === 1 ? "#3DC4E3" : "#B5F0FA",
            }}
          />

          <Tab
            className="tabresponsive"
            icon={
              <AutoStoriesIcon
                sx={{
                  backgroundColor: "white",
                  color: "white",
                  borderRadius: "5px",
                  width: "20%",
                  height: "30px",
                }}
              />
            }
            label="Reading"
            sx={{
              minWidth: 80,
              textTransform: "none",
              height: 80,
              fontWeight: "500",
              fontSize: "20px",
              marginRight: "10px",
              backgroundColor: activeTab === 2 ? "#28A877" : "#2DCE89",
            }}
          />

          <Tab
            className="tabresponsive"
            icon={
              <HearingIcon
                sx={{
                  backgroundColor: "white",
                  color: "white",
                  borderRadius: "5px",
                  width: "20%",
                  height: "30px",
                }}
              />
            }
            label="Listening"
            sx={{
              minWidth: 80,
              textTransform: "none",
              height: 80,
              fontWeight: "500",
              fontSize: "20px",
              backgroundColor: activeTab === 3 ? "#85898F" : "#ACB0B9",
            }}
          />
        </Tabs>


        <Box sx={{ paddingTop: "10px" }}>
          {activeTab === 0 && (
            // , height: "385px"
            <Card
              sx={{
                marginX: "0px",
                padding: "14px",
                height: "80vh", // Adjust height relative to viewport
                minHeight: "400px", // Prevents it from being too small
                maxHeight: "90vh", // Prevents it from exceeding screen height
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography variant="h5">Speaking ( AI Scoring )</Typography>

              <Box
                className="scrollable-section"
                sx={{
                  overflowY: "auto", // Enable vertical scrolling
                  flexGrow: 1, // Makes it take available space
                  paddingRight: "10px", // Space for scrollbar
                  "&::-webkit-scrollbar": {
                    width: "10px",
                  },
                  "&::-webkit-scrollbar-track": {
                    background: "#f1f1f1",
                    borderRadius: "10px",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    background: "#FC8A70", // Red scrollbar thumb
                    borderRadius: "10px",
                  },
                  "&::-webkit-scrollbar-thumb:hover": {
                    background: "#cc0000", // Darker red on hover
                  },
                }}
              >
                {speakingItems.map((item, index) => (
                  <Link
                    onClick={() => {
                      localStorage.setItem("currentPage", 1);
                    }}
                    to={`/questions?category=Speaking&subcategory=${item.label}&base=${item.base}`}
                    key={index}
                  >
                    <HeaderContainer className="innersections">
                      <Box sx={{ width: "100%" }}>
                        <Box sx={{ mb: 2 }}>
                          {item.base && (
                            <AcademicChip
                              text={item.base}
                              label={item.base}
                              sx={{ backgroundColor: "#eeff00" }}
                            />
                          )}
                        </Box>
                        <Typography
                          variant="h4"
                          sx={{ color: "white", fontWeight: "medium" }}
                        >
                          {item.label}
                        </Typography>
                      </Box>
                      <AIChip label="AI" />
                    </HeaderContainer>
                  </Link>
                ))}
              </Box>
            </Card>
          )}

          {activeTab === 1 && (
            <Card sx={{
              marginX: "0px",
              padding: "14px",
              height: "80vh", // Adjust height relative to viewport
              minHeight: "400px", // Prevents it from being too small
              maxHeight: "90vh", // Prevents it from exceeding screen height
              display: "flex",
              flexDirection: "column",
            }}>
              <Typography variant="h5">Writing ( AI Scoring )</Typography>
              <Box className="scrollable-section"
                sx={{
                  overflowY: "auto", // Enable vertical scrolling
                  flexGrow: 1, // Makes it take available space
                  paddingRight: "10px", // Space for scrollbar
                  "&::-webkit-scrollbar": {
                    width: "10px",
                  },
                  "&::-webkit-scrollbar-track": {
                    background: "#f1f1f1",
                    borderRadius: "10px",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    background: "#3DC4E3", // Red scrollbar thumb
                    borderRadius: "10px",
                  },
                  "&::-webkit-scrollbar-thumb:hover": {
                    background: "#cc0000", // Darker red on hover
                  },
                }}>
                {writingItems.map((item, index) => (
                  <Link
                    onClick={() => {
                      localStorage.setItem("currentPage", 1);
                    }}
                    to={`/questions?category=Writing&subcategory=${item.label}&base=${item.base}`}
                  >
                    <HeaderContainer key={index} className="innersections">
                      <Box sx={{ width: "100%" }}>
                        <Box sx={{ mb: 2 }}>
                          {item.base && (
                            <AcademicChip
                              label={item.base}
                              sx={{ backgroundColor: "#eeff00" }}
                            />
                          )}
                        </Box>
                        <Typography
                          variant="h4"
                          sx={{ color: "white", fontWeight: "medium" }}
                        >
                          {item.label}
                        </Typography>
                      </Box>
                      <AIChip label="AI" />
                    </HeaderContainer>
                  </Link>
                ))}
              </Box>
            </Card>
          )}

          {activeTab === 2 && (
            <Card sx={{
              marginX: "0px",
              padding: "14px",
              height: "80vh", // Adjust height relative to viewport
              minHeight: "400px", // Prevents it from being too small
              maxHeight: "90vh", // Prevents it from exceeding screen height
              display: "flex",
              flexDirection: "column",
            }}>
              <Typography variant="h5">Reading</Typography>
              <Box className="scrollable-section"
                sx={{
                  overflowY: "auto", // Enable vertical scrolling
                  flexGrow: 1, // Makes it take available space
                  paddingRight: "10px", // Space for scrollbar
                  "&::-webkit-scrollbar": {
                    width: "10px",
                  },
                  "&::-webkit-scrollbar-track": {
                    background: "#f1f1f1",
                    borderRadius: "10px",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    background: "#28A877", // Red scrollbar thumb
                    borderRadius: "10px",
                  },
                  "&::-webkit-scrollbar-thumb:hover": {
                    background: "#cc0000", // Darker red on hover
                  },
                }}>
                {readingItems.map((item, index) => (
                  <Link
                    onClick={() => {
                      localStorage.setItem("currentPage", 1);
                    }}
                    to={`/questions?category=Reading&subcategory=${item.label}&base=${item.base}`}
                  >
                    <HeaderContainer key={index} className="innersections">
                      <Box sx={{ width: "100%" }}>
                        <Box sx={{ mb: 2 }}>
                          {item.base && (
                            <AcademicChip
                              label={item.base}
                              sx={{ backgroundColor: "#eeff00" }}
                            />
                          )}
                        </Box>
                        <Typography
                          variant="h4"
                          sx={{ color: "white", fontWeight: "medium" }}
                        >
                          {item.label}
                        </Typography>
                      </Box>
                      {item.ai && <AIChip label="AI" />}
                    </HeaderContainer>
                  </Link>
                ))}
              </Box>
            </Card>
          )}

          {activeTab === 3 && (
            <Card sx={{
              marginX: "0px",
              padding: "14px",
              height: "80vh", // Adjust height relative to viewport
              minHeight: "400px", // Prevents it from being too small
              maxHeight: "90vh", // Prevents it from exceeding screen height
              display: "flex",
              flexDirection: "column",
            }}>
              <Typography variant="h5">Listening</Typography>
              <Box className="scrollable-section"
                sx={{
                  overflowY: "auto", // Enable vertical scrolling
                  flexGrow: 1, // Makes it take available space
                  paddingRight: "10px", // Space for scrollbar
                  "&::-webkit-scrollbar": {
                    width: "10px",
                  },
                  "&::-webkit-scrollbar-track": {
                    background: "#f1f1f1",
                    borderRadius: "10px",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    background: "#85898F", // Red scrollbar thumb
                    borderRadius: "10px",
                  },
                  "&::-webkit-scrollbar-thumb:hover": {
                    background: "#cc0000", // Darker red on hover
                  },
                }}>
                {listeningItems.map((item, index) => (
                  <Link
                    onClick={() => {
                      localStorage.setItem("currentPage", 1);
                    }}
                    to={`/questions?category=Listening&subcategory=${item.label}&base=${item.base}`}
                  >
                    <HeaderContainer key={index} className="innersections">
                      <Box sx={{ width: "100%" }}>
                        <Box sx={{ mb: 2 }}>
                          {item.base && (
                            <AcademicChip
                              label={item.base}
                              sx={{ backgroundColor: "#eeff00" }}
                            />
                          )}
                        </Box>
                        <Typography
                          variant="h4"
                          sx={{ color: "white", fontWeight: "medium" }}
                        >
                          {item.label}
                        </Typography>
                      </Box>
                      {item.ai && <AIChip label="AI" />}
                    </HeaderContainer>
                  </Link>
                ))}
              </Box>
            </Card>
          )}
        </Box>
      </Box>
    </DashboardLayout>
  );
}

export default Dashboard;