import React, { useEffect, useState } from "react";
import { Box, Typography, Button, Container } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Padding, Send } from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import { postApi } from "services/axiosInstance";
import { API_PATH } from "services/apipath";

const ReorderParagraphsMock = ({ questionData, onNext }) => {
  const lastId = localStorage.getItem("lastId");
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  let fullTestId = location.pathname.split("/")[2];

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [sourceParagraphs, setSourceParagraphs] = useState([]);
  const [targetParagraphs, setTargetParagraphs] = useState([]);
  const [correctOrder, setCorrectOrder] = useState([]);
  const [submitedAns, setSubmitedAns] = useState(null);

  useEffect(() => {
    if (questionData) {
      const transformedChoices = questionData.choices.map((choice, index) => ({
        id: `${index}`,
        content: choice.choice_text,
      }));
      setSourceParagraphs(transformedChoices);
      setCorrectOrder(questionData?.correct_order);
    }
  }, [questionData]);

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const { source, destination } = result;

    if (source.droppableId === destination.droppableId) {
      const items = Array.from(
        source.droppableId === "source" ? sourceParagraphs : targetParagraphs
      );
      const [reorderedItem] = items.splice(source.index, 1);
      items.splice(destination.index, 0, reorderedItem);

      if (source.droppableId === "source") setSourceParagraphs(items);
      else setTargetParagraphs(items);
    } else {
      const sourceItems = Array.from(
        source.droppableId === "source" ? sourceParagraphs : targetParagraphs
      );
      const destItems = Array.from(
        destination.droppableId === "source"
          ? sourceParagraphs
          : targetParagraphs
      );
      const [movedItem] = sourceItems.splice(source.index, 1);
      destItems.splice(destination.index, 0, movedItem);

      if (source.droppableId === "source") {
        setSourceParagraphs(sourceItems);
        setTargetParagraphs(destItems);
      } else {
        setSourceParagraphs(destItems);
        setTargetParagraphs(sourceItems);
      }
    }
  };

  const handleSubmit = async () => {
    const transformedChoices = targetParagraphs.map((choice) =>
      parseInt(choice.id)
    );
    if (JSON.stringify(correctOrder) === JSON.stringify(transformedChoices)) {
      setSubmitedAns(true);
    } else {
      setSubmitedAns(false);
    }
    let correctCount = 0;
    transformedChoices.forEach((choice, index) => {
      if (choice === correctOrder[index]) {
        correctCount++;
      }
    });

    const newScores = {
      overall: correctCount,
      total: correctOrder.length,
    }
    const payload = {
      fullTestId: fullTestId,
      currentQuestionId: questionData._id,
      module: questionData.module_type[0],
      sub_module: questionData.module_subcategory[0],
      student_ans: transformedChoices,
      correctAnswers: correctOrder,
      score: JSON.stringify(newScores),
    };
    try {
      const answer = await postApi(
        API_PATH.MOCK_TEST.SUBMIT_MOCK_TEST_ANS,
        payload
      );
      if (answer.status === 200) {
        setSourceParagraphs([])
        setTargetParagraphs([])
        onNext();
      }
    } catch (error) { }
  };

  const navigate = useNavigate();
  return (
    <Box sx={{ bgcolor: "white" }}>
      {/* Main Content */}
      <Container maxWidth="lg" sx={{ mt: 2 }}>
        <Typography
          sx={{ color: "black", fontStyle: "italic", fontSize: "15px" }}
        >
          The text boxes in the left panel have been placed in a random order.
          Restore the original order by dragging the text boxes from the left
          panel to the right panel.
        </Typography>

        <Box sx={{ overflowY: "auto", px: 0 }}>
          <DragDropContext onDragEnd={handleDragEnd}>
            <Box sx={{ display: "flex", gap: 2 }}>
              <DroppableSection
                id="source"
                title="Source"
                items={sourceParagraphs}
                setItems={setSourceParagraphs}
                style={{ Padding: "8px" }}
              />
              <DroppableSection
                id="target"
                title="Target"
                items={targetParagraphs}
                setItems={setTargetParagraphs}
                correct={correctOrder}
                submitedAns={submitedAns}
                style={{ Padding: "8px" }}
              />
            </Box>
          </DragDropContext>
        </Box>
      </Container>

      <Container>
        <Box
          sx={{
            // p: 2,
            mt: 3,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            verticalAlign: "middle",
          }}
        >
          <Button
            variant="outlined"
            sx={{ color: "black !important" }}
            onClick={() => navigate("/mocktest")}
          >
            Save & Exit
          </Button>

          <Box
            sx={{ display: "flex", alignItems: "center", gap: 1, ml: "auto" }}
          >
            <Button
              variant="contained"
              sx={{ color: "white !important" }}
              endIcon={<Send />}
              onClick={handleSubmit}
            >
              {lastId === "true" ? "Submit" : "Next"}
            </Button>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

const DroppableSection = ({
  id,
  title,
  items,
  setItems,
  correct,
  submitedAns,
}) => {
  const renderItems = submitedAns
    ? correct
      .map((index) => {
        const item = items.find((item) => item.id === String(index));
        return item ? item : null;
      })
      .filter((item) => item !== null)
    : items;

  const bgcolor = "#c1c1c1";

  return (
    <Box sx={{ flex: 1 }}>
      <Typography
        sx={{ mb: 2, fontWeight: "bold", bgcolor: "#009788" }}
        textAlign="center"
      >
        {title}
      </Typography>
      <Droppable droppableId={id}>
        {(provided) => (
          <Box
            ref={provided.innerRef}
            {...provided.droppableProps}
            sx={{
              minHeight: 400,
              borderRadius: 1,
              p: 2,
              border: "solid 1px #009788",
            }}
          >
            {renderItems.map((item, index) => (
              <Draggable key={item.id} draggableId={item.id} index={index}>
                {(provided) => (
                  <Box
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    sx={{
                      p: 2,
                      mb: 2,
                      bgcolor: bgcolor,
                      borderRadius: 1,
                      "&:hover": { bgcolor: "#9d9d9d" },
                    }}
                  >
                    <Typography>{item.content}</Typography>
                  </Box>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </Box>
        )}
      </Droppable>
    </Box>
  );
};

export default ReorderParagraphsMock;
