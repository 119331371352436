import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  IconButton,
  Button,
  Modal,
  Select,
  MenuItem,
  Switch,
  FormControlLabel,
  Container,
} from "@mui/material";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useLocation, useNavigate } from "react-router-dom";
import { processQuestionText } from "utils/ProcessQuestionText";
import SendIcon from "@mui/icons-material/Send";
import borders from "assets/theme/base/borders";
import { Send } from "@mui/icons-material";
import { API_PATH } from "services/apipath";
import { postApi } from "services/axiosInstance";

// Static questions data

function FillInBlanksMock({ questionData, onNext }) {
  const lastId = localStorage.getItem("lastId");
  const navigate = useNavigate();
  const location = useLocation();
  let fullTestId = location.pathname.split("/")[2];
  const [openSearchModal, setOpenSearchModal] = useState(false);
  const [questions, setQuestions] = useState(questionData);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [showAnswer, setShowAnswer] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isCorrect, setIsCorrect] = useState(false);
  const [blankStatus, setBlankStatus] = useState({});
  const [availableWords, setAvailableWords] = useState([]);
  const [blanks, setBlanks] = useState({});
  const [scores, setScores] = useState({
    "content": 0,
    "form": 0,
    "grammar": 0,
    "vocabulary": 0,
    "spelling": 0,
    "development": 0,
    "conventions": 0,
    "organisation": 0
  });
  const currentQuestion = questionData;

  useEffect(() => {
    if (currentQuestion) {
      const { totalBlanks } = processQuestionText(
        currentQuestion.question_text
      );
      const initialBlanks = {};
      for (let i = 1; i <= totalBlanks; i++) {
        initialBlanks[`blank${i}`] = null;
      }
      setBlanks(initialBlanks);
      setAvailableWords(
        currentQuestion.choices.map((choice) => ({
          id: choice._id,
          content: choice.choice_text,
        }))
      );
    }
  }, [currentQuestion]);

  //change/24-02-2025
  const handleSubmit = async () => {
    // if (!currentQuestion || !currentQuestion.FillInTheBlanksAnswers) return;

    let score = 0;
    const newBlankStatus = {};

    const correctAnswers = currentQuestion.FillInTheBlanksAnswers;

    console.log({ blanks: blanks });

    // Validate each blank and calculate score
    Object.entries(blanks).forEach(([blankId, value], index) => {
      const isCorrect = value.content.trim().toLowerCase() === correctAnswers[index].toLowerCase();
      newBlankStatus[blankId] = isCorrect;
      if (isCorrect) {
        score += 1;
      }
    });

    setBlankStatus(newBlankStatus);
    // setIsSubmitted(true);

    // Check if all answers are correct
    const isAllCorrect = Object.values(newBlankStatus).every(
      (status) => status
    );
    setIsCorrect(isAllCorrect);


    const newScores = {
      content: 0,
      pronunciation: 0,
      fluency: 0,
      overall: score,
      total: correctAnswers.length
    }
    const payload = {
      fullTestId: fullTestId,
      currentQuestionId: currentQuestion._id,
      student_ans: JSON.stringify(blanks),
      module: currentQuestion.module_type[0],
      sub_module: currentQuestion.module_subcategory[0],
      score: JSON.stringify(newScores)
    }

    console.log({ scores: newScores, module: currentQuestion.module_type[0], sub_module: currentQuestion.module_subcategory[0], payload: payload });

    try {
      const answer = await postApi(
        API_PATH.MOCK_TEST.SUBMIT_MOCK_TEST_ANS,
        payload
      );
      if (answer.status === 200) {
        onNext();
      }
    } catch (error) { }
  };

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const { source, destination } = result;

    if (
      source.droppableId === "availableWords" &&
      destination.droppableId.startsWith("blank")
    ) {
      const word = availableWords[source.index];
      const newAvailableWords = [...availableWords];
      newAvailableWords.splice(source.index, 1);

      if (blanks[destination.droppableId]) {
        newAvailableWords.push(blanks[destination.droppableId]);
      }

      setAvailableWords(newAvailableWords);
      setBlanks({
        ...blanks,
        [destination.droppableId]: word,
      });
      // Reset submission state when user makes changes
      setBlankStatus({});
      setIsSubmitted(false);
    }

    if (
      source.droppableId.startsWith("blank") &&
      destination.droppableId === "availableWords"
    ) {
      const word = blanks[source.droppableId];
      setAvailableWords([...availableWords, word]);
      setBlanks({
        ...blanks,
        [source.droppableId]: null,
      });
      // Reset submission state when user makes changes
      setBlankStatus({});
      setIsSubmitted(false);
    }

  };

  const handleNextQuestion = () => {
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex((prev) => prev + 1);
    }
    if (currentQuestionIndex === questions.length - 1) {
      onNext();
    }
    setShowAnswer(false);
    setBlankStatus({});
    setIsSubmitted(false);
  };

  const handlePreviousQuestion = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex((prev) => prev - 1);
    }
    setShowAnswer(false);
    setBlankStatus({});
    setIsSubmitted(false);
  };

  const renderBlank = (blankId) => (
    <Droppable droppableId={blankId}>
      {(provided, snapshot) => (
        <Box
          ref={provided.innerRef}
          {...provided.droppableProps}
          sx={{
            display: "inline-block",
            mx: 1,
            minWidth: 120,
            minHeight: 32,
            border: "1px solid rgba(20, 20, 20, 0.875)",
            borderRadius: 1,
            bgcolor: snapshot.isDraggingOver
              ? "rgba(255,255,255,0.2)"
              : "rgba(255,255,255,0.1)",
            p: 0.5,
            transition: "background-color 0.2s ease",
          }}
        >
          {blanks[blankId] && (
            <Draggable draggableId={blanks[blankId].id} index={0}>
              {(provided, snapshot) => (
                <Box
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  sx={{
                    p: 1,
                    bgcolor: isSubmitted
                      ? blankStatus[blankId]
                        ? "#4CAF50" // Green for correct
                        : "#f44336" // Red for incorrect
                      : "#00A693", // Default color
                    borderRadius: 1,
                    boxShadow: snapshot.isDragging ? 3 : 0,
                  }}
                >
                  {blanks[blankId].content}
                </Box>
              )}
            </Draggable>
          )}
          {provided.placeholder}
        </Box>
      )}
    </Droppable>
  );

  return (
    <Container>
      <Box sx={{ bgcolor: "white" }}>

        <Box sx={{ pt: 3 }}>
          {currentQuestion && (
            <>
              <Typography
                sx={{
                  color: "Black",
                  mb: 2,
                  fontStyle: "italic",
                  fontSize: "17px",
                }}
              >
                In the text below some words are missing. Drag words from the
                box below to the appropriate place in the text.
              </Typography>

              <Box sx={{ border: "1px solid grey", p: 2, borderRadius: 1 }}>
                <DragDropContext onDragEnd={handleDragEnd}>
                  {/* Question Text */}
                  <Box sx={{ lineHeight: 1.8, fontSize: "16px" }}>
                    <Typography component="div" sx={{ color: "black" }}>
                      {currentQuestion &&
                        (() => {
                          const { parts } = processQuestionText(
                            currentQuestion.question_text
                          );
                          return parts.map((part, index, array) => (
                            <React.Fragment key={index}>
                              {part}
                              {index < array.length - 1 &&
                                renderBlank(`blank${index + 1}`)}
                            </React.Fragment>
                          ));
                        })()}
                    </Typography>
                  </Box>

                  {/* Draggable words section */}
                  <Box sx={{ backgroundColor: "#C4E1C5" }}>
                    <Droppable
                      droppableId="availableWords"
                      direction="horizontal"
                    >
                      {(provided) => (
                        <Box
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                          sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            gap: 1,
                            mt: 4,
                            p: 1,
                            color: "black",
                            borders: "1px solid black !important",
                            bgcolor: "white",
                            borderRadius: 1,
                          }}
                        >
                          {availableWords.map((word, index) => (
                            <Draggable
                              key={word.id}
                              draggableId={word.id}
                              index={index}
                            >
                              {(provided, snapshot) => (
                                <Box
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  sx={{
                                    px: 1,
                                    bgcolor: "white",
                                    border: "1px solid black !important",
                                    borderRadius: 1,
                                    cursor: "grab",
                                    boxShadow: snapshot.isDragging ? 3 : 0,
                                    "&:hover": {
                                      bgcolor: "#dadada",
                                    },
                                  }}
                                >
                                  {word.content}
                                </Box>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </Box>
                      )}
                    </Droppable>
                  </Box>
                </DragDropContext>
              </Box>
            </>
          )}
        </Box>
      </Box>
      <Container>
        <Box
          sx={{
            // p: 2,
            mt: 3,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            verticalAlign: "middle",
          }}
        >
          <Button
            variant="outlined"
            sx={{ color: "black !important" }}
            onClick={() => navigate("/mocktest")}
          >
            Save & Exit
          </Button>

          <Box
            sx={{ display: "flex", alignItems: "center", gap: 1, ml: "auto" }}
          >
            <Button
              variant="contained"
              sx={{ color: "white !important" }}
              endIcon={<Send />}
              onClick={handleSubmit}
            >
              {lastId === "true" ? "Submit" : "Next"}
            </Button>
          </Box>
        </Box>
      </Container>
    </Container>
  );
}

export default FillInBlanksMock;
