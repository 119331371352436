import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Modal,
  Switch,
  Typography,
} from "@mui/material";
import levenshtein from "js-levenshtein";
import { ReactMic } from "react-mic";
import { Mic, Stop } from "@mui/icons-material";
import AudioComponent from "components/Recorder/AudioComponent";
import { API_PATH } from "services/apipath";
import { postApi } from "services/axiosInstance";
import { getApi } from "services/axiosInstance";

const total = 90.0;
const PteDescribeImage = ({ keywords, id, SubCategory, answer, timer }) => {
  const [isListening, setIsListening] = useState(false);
  const [spokenWords, setSpokenWords] = useState([]);
  const [wordStatus, setWordStatus] = useState({});

  const [isRecordingStopped, setIsRecordingStopped] = useState(false);
  const [recordedAudio, setRecordedAudio] = useState(null);
  const [preparationTimer, setPreparationTimer] = useState(25);
  const [recordingTimer, setRecordingTimer] = useState(40);
  const [timerPhase, setTimerPhase] = useState("preparation"); // 'preparation' | 'recording' | 'completed'
  const [timerVisible, setTimerVisible] = useState(true);

  const [openAnalysisModal, setOpenAnalysisModal] = useState(false);
  const [scores, setScores] = useState({
    pronunciation: 0,
    fluency: 0,
    content: 0,
    totalScore: 0,
  });

  useEffect(() => {
    setIsListening(false);
    setIsRecordingStopped(false);
    setPreparationTimer(25);
    setTimerPhase("preparation");
    setChecked(false);
  }, [id]);
  // Refs
  const recognitionRef = useRef(null);
  const recordingTimeoutRef = useRef(null);

  // Utility function to normalize words
  const normalizeWord = (word) => {
    return word
      .toLowerCase()
      .replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, "")
      .trim();
  };

  const [questionDataApi, setQuestionDataApi] = useState(null);
  const [answerData, setAnswerData] = useState(null);

  const getQuestionByID = async (id) => {
    try {
      const res = await getApi(`${API_PATH.STUDENTS.GET_QUESTION_BY_ID}/${id}`);
      if (res.status === 200) {
        setQuestionDataApi(res.data.data);  // Set question data
        setAnswerData(res.data.answer);     // Set answer separately
      }
    } catch (error) {
      console.error("Error fetching question:", error);
    }
  };

  useEffect(() => {
    if (id) {
      getQuestionByID(id);
    }
  }, [id]);


  const createBeep = () => {
    try {
      const audioContext = new AudioContext();
      const oscillator = audioContext.createOscillator();
      const gainNode = audioContext.createGain();

      // Connect the nodes
      oscillator.connect(gainNode);
      gainNode.connect(audioContext.destination);

      // Configure oscillator
      oscillator.type = "sine";
      oscillator.frequency.setValueAtTime(800, audioContext.currentTime); // Frequency in hertz

      // Configure gain (volume)
      gainNode.gain.setValueAtTime(0.5, audioContext.currentTime); // Start at 10% volume

      // Start and stop the beep
      oscillator.start(audioContext.currentTime);
      oscillator.stop(audioContext.currentTime + 0.15); // Beep duration: 150ms

      // Cleanup
      setTimeout(() => {
        gainNode.disconnect();
        oscillator.disconnect();
      }, 200);
    } catch (error) {
      // console.error("Error creating beep:", error);
    }
  };

  // Timer logic for preparation phase
  useEffect(() => {
    if (timerPhase !== "preparation" || preparationTimer <= 0) return;

    const intervalId = setInterval(() => {
      setPreparationTimer((prev) => {
        if (prev === 2) {
          createBeep();
        }
        if (prev <= 1) {
          setTimerPhase("recording");
          startListening();
          return 0;
        }
        return prev - 1;
      });
    }, 1000);

    return () => clearInterval(intervalId);
  }, [preparationTimer, timerPhase]);

  // Timer logic for recording phase
  useEffect(() => {
    if (timerPhase !== "recording" || !isListening) return;

    const intervalId = setInterval(() => {
      setRecordingTimer((prev) => {
        if (prev <= 1) {
          stopListening();
          setTimerPhase("completed");
          setTimerVisible(false); // Hide timer when recording ends
          return 0;
        }
        return prev - 1;
      });
    }, 1000);

    return () => clearInterval(intervalId);
  }, [recordingTimer, timerPhase, isListening]);

  // Initialize Speech Recognition
  // useEffect(() => {
  //   if ("webkitSpeechRecognition" in window) {
  //     recognitionRef.current = new window.webkitSpeechRecognition();
  //     recognitionRef.current.continuous = false;
  //     recognitionRef.current.interimResults = false;
  //     recognitionRef.current.lang = "en-US";

  //     recognitionRef.current.onresult = (event) => {
  //       const transcript =
  //         event.results[event.results.length - 1][0].transcript.trim();
  //       const newSpokenWords = transcript.split(/\s+/).map(normalizeWord);
  //       setSpokenWords(newSpokenWords);
  //       analyzeKeywordMatch(newSpokenWords);
  //     };

  //     recognitionRef.current.onerror = (event) => {
  //       setIsListening(false);
  //       alert(`Speech recognition error: ${event.error}`);
  //     };

  //     recognitionRef.current.onend = () => {
  //       // Restart recognition if we're still in recording phase
  //       if (timerPhase === "recording" && isListening) {
  //         recognitionRef.current.start();
  //       }
  //     };
  //   } else {
  //     alert("Web Speech API is not supported in this browser");
  //   }

  //   return () => {
  //     if (recognitionRef.current) {
  //       recognitionRef.current.stop();
  //     }
  //   };
  // }, []);


  useEffect(() => {
    if ("webkitSpeechRecognition" in window) {
      recognitionRef.current = new window.webkitSpeechRecognition();
      recognitionRef.current.continuous = true;
      recognitionRef.current.interimResults = true;
      recognitionRef.current.lang = "en-US";

      recognitionRef.current.onresult = (event) => {
        console.log(event, "result");
        const transcript = Array.from(event.results)
          .map((result) => result[0].transcript)
          .join(" ")
          .trim();
        const newSpokenWords = transcript.split(/\s+/).map(normalizeWord);
        setSpokenWords(newSpokenWords);
        analyzeKeywordMatch(newSpokenWords);
      };

      recognitionRef.current.onerror = (event) => { };

      recognitionRef.current.onend = () => {
        // Restart recognition if we're still in recording phase
        if (timerPhase === "recording" && isListening) {
          recognitionRef.current.start();
        }
      };
    }

    return () => {
      if (recordingTimeoutRef.current) {
        clearTimeout(recordingTimeoutRef.current);
      }
    };
  }, [id]);



  // Analyze keyword match
  const analyzeKeywordMatch = (spokenWords) => {
    // const normalizedKeywords = keywords.map(normalizeWord);
    const normalizedKeywords = Array.isArray(keywords)
      ? keywords.map(normalizeWord)
      : [];

    const newWordStatus = {};

    // Mark all keywords as missing initially
    normalizedKeywords.forEach((keyword, index) => {
      newWordStatus[index] = "missing";
    });

    // Check spoken words against keywords (with Levenshtein distance)
    spokenWords.forEach((spokenWord) => {
      normalizedKeywords.forEach((keyword, index) => {
        const distance = levenshtein(spokenWord, keyword);
        if (distance <= 1) {
          // Allow minor typo tolerance
          newWordStatus[index] = "correct";
        }
      });
    });

    setWordStatus(newWordStatus);
    calculateScores(newWordStatus, spokenWords);
  };

  // Calculate scores
  // const calculateScores = (wordStatus, spokenWords) => {
  //   const correctWords = Object.values(wordStatus).filter(
  //     (status) => status === "correct"
  //   ).length;
  //   console.log(keywords.length ,"keywords.length")
  //   const totalKeywords = keywords.length;

  //   // Adjust pronunciation score based on correctly spoken keywords
  //   const pronunciationScore = Math.round((correctWords / totalKeywords) * 40);

  //   // Fluency score: Proportional to the number of words spoken
  //   const idealWordCount = 60; // Define the ideal number of words for fluency
  //   const fluencyScore = Math.min(
  //     Math.round((spokenWords.length / idealWordCount) * 30),
  //     30
  //   );

  //   // Content score: Based on the percentage of keywords mentioned
  //   const contentScore = Math.round((correctWords / totalKeywords) * 30);

  //   const totalScore = pronunciationScore + fluencyScore + contentScore;

  //   setScores({
  //     pronunciation: pronunciationScore,
  //     fluency: fluencyScore,
  //     content: contentScore,
  //     totalScore: totalScore,
  //   });
  // };

  const calculateScores = (wordStatus, spokenWords) => {
    const correctWords = Object.values(wordStatus).filter(
      (status) => status === "correct"
    ).length;

    console.log(keywords.length, "keywords.length");
    const totalKeywords = keywords.length || 1; // Avoid division by zero

    // Adjust pronunciation score based on correctly spoken keywords
    const pronunciationScore = Math.round((correctWords / totalKeywords) * 80);

    const idealWordCount = 60; // Define the ideal number of words for fluency
    const fluencyScore = Math.min(
      Math.round((spokenWords.length / idealWordCount) * 70),
      30
    );

    // Content score: Based on the percentage of keywords mentioned
    const contentScore = Math.round((correctWords / totalKeywords) * 60);

    // Total raw score (out of 100)
    const totalScore = pronunciationScore + fluencyScore + contentScore;

    // Normalize total score to be out of 90
    const scaledTotalScore = Math.round((totalScore / 100) * 90);

    setScores({
      pronunciation: pronunciationScore,
      fluency: fluencyScore,
      content: contentScore,
      totalScore: scaledTotalScore,
    });
  };


  // Start listening
  const startListening = () => {
    if (recognitionRef.current) {
      try {
        setSpokenWords([]);
        setWordStatus({});
        setIsRecordingStopped(false);
        setRecordedAudio(null);
        setScores({
          pronunciation: 0,
          fluency: 0,
          content: 0,
          totalScore: 0,
        });
        setIsListening(true);
        setTimerPhase("recording");
        setRecordingTimer(40);
        recognitionRef.current.start();

        recordingTimeoutRef.current = setTimeout(() => {
          stopListening();
        }, 40000);
      } catch (error) {
        setIsListening(false);
        alert(
          "Could not start speech recognition. Please check microphone permissions."
        );
      }
    }
  };

  // Stop listening
  const stopListening = (recordedBlob) => {
    if (recognitionRef.current) {
      recognitionRef.current.stop();
      setTimerPhase("completed");
      setIsListening(false);
      setIsRecordingStopped(true);
      setRecordedAudio(recordedBlob);
    }
  };

  const onStop = (recordedBlob) => {
    const audioURL = URL.createObjectURL(recordedBlob.blob);
    setRecordedAudio(audioURL);
  };

  const [checked, setChecked] = useState(
    SubCategory === "Repeat Sentence" ||
      SubCategory === "Retell Lecture" ||
      SubCategory === "Short Answer"
      ? false
      : true
  );

  const handleToggle = (event) => {
    setChecked(event.target.checked);
  };

  console.log("Checked:", checked);
  console.log("QuestionDataApi.answer:", questionDataApi?.answer);

  const handleSubmitAnalysis = async () => {
    if (!recordedAudio) {
      // alert("No recording available to analyze.");
      return;
    }

    try {
      // Prepare form data for submission
      const formData = new FormData();
      formData.append("questionId", id);
      formData.append("ansfile", recordedAudio.blob);
      //   formData.append("scores", scores);

      const score = {
        // category: Category,
        total: total,
        overall: scores.overall,
        content: scores.content,
        pronunciation: scores.pronunciation,
        fluency: scores.fluency,
        text: spokenWords.join(" "),
      };

      // Append score object as JSON
      formData.append("score", JSON.stringify(score));

      // Call the API to submit the answer and analyze the recording
      const response = await postApi(
        `${API_PATH.STUDENTS.SUBMIT_ANS}`,
        formData
      );

      if (response.status === 201) {
        setOpenAnalysisModal(true);
      } else {
        alert("Failed to analyze the recording. Please try again.");
      }
    } catch (error) {
      alert("An error occurred while analyzing the recording.");
    }
  };

  // <==========================>
  //   const [questionDataApi, setQuestionDataApi] = useState([]);
  //   const location = useLocation();
  //   let id = location.pathname.split("/")[2];

  //   const getQuestionBtID = async (id) => {
  //     try {
  //       const res = await getApi(`${API_PATH.STUDENTS.GET_QUESTION_BY_ID}/${id}`);
  //       if (res.status === 200) {
  //         setQuestionDataApi(res.data.data);
  //       }
  //     } catch (error) {}
  //   };

  //   useEffect(() => {
  //     if (id) {
  //       getQuestionBtID(id);
  //     }
  //   }, []);

  return (
    <CardContent
      sx={{
        paddingX: "0px",
        width: "100%",
      }}
    >
      {/* Keywords */}
      {/* <div className="mb-4">
                    <Typography variant="h6" gutterBottom>Keywords to Include:</Typography>
                    {keywords.map((keyWords, index) => (
                        <Typography
                            key={index}
                            sx={{
                                marginRight: 1,
                                fontWeight: wordStatus[index] === 'correct' ? 'bold' : 'normal',
                                color: wordStatus[index] === 'correct' ? 'green' : 'red',
                                display: 'inline-block',
                            }}
                        >
                            {keyWords}
                        </Typography>
                    ))}
                </div> */}

      {/* <Typography
          sx={{ color: "#fff", background: "#1A1A2E", fontSize: "17px" }}
        >
          {keywords}
        </Typography> */}

      {/* Spoken Words */}
      {/* <div className="mb-4">
                    <Typography variant="h6" gutterBottom>Your Speech:</Typography>
                    <Typography>
                        {spokenWords.join(' ') || 'No speech recorded'}
                    </Typography>
                </div> */}

      {/* Scores */}
      {/* <div className="mb-4">
                    <Typography variant="h6" gutterBottom>Scores:</Typography>
                    <Typography>Pronunciation: {scores.pronunciation}/90</Typography>
                    <Typography>Fluency: {scores.fluency}/90</Typography>
                    <Typography>Content: {scores.content}/90</Typography>
                    <Typography>Total Score: {scores.totalScore}/90</Typography>
                </div> */}

      {/* Controls */}
      {/* <div className="flex space-x-4">
                    <Button
                        onClick={startListening}
                        disabled={isListening}
                        variant="contained"
                    >
                        Start Speaking
                    </Button>
                    <Button
                        onClick={stopListening}
                        disabled={!isListening}
                        variant="outlined"
                    >
                        Stop Speaking
                    </Button>
                </div> */}

      <ReactMic
        record={isListening}
        visualSetting="none"
        className="hidden d-none"
        onStop={stopListening}
        mimeType="audio/webm"
      />

      {!isRecordingStopped && (
        <Box sx={{ textAlign: "center", paddingTop: "30px", my: "0px" }}>
          <IconButton
            onClick={isListening ? stopListening : startListening}
            sx={{
              bgcolor: isListening ? "#f44336" : "#4caf50",
              p: 4,
              mb: 2,
              "&:hover": {
                bgcolor: isListening ? "#d32f2f" : "#45a049",
              },
            }}
          >
            {isListening ? (
              <Stop sx={{ color: "white", fontSize: 40 }} />
            ) : (
              <Mic sx={{ color: "white", fontSize: 40 }} />
            )}
          </IconButton>
          {timerPhase !== "completed" && timerVisible && (
            <Typography
              variant="h6"
              sx={{ textAlign: "center", mb: 3, color: "#ffffff" }}
            >
              {timerPhase === "preparation"
                ? `Preparation time : ${preparationTimer} sec`
                : `Recording time : ${recordingTimer} sec`}
            </Typography>
          )}
        </Box>
      )}

      <div
        className="position-absolute bottom-0 w-100"
        style={{
          paddingRight: "49px",
        }}
      >
        <div className=" w-100">
          {isRecordingStopped && recordedAudio && (
            <Box sx={{ background: "white", borderRadius: "4px" }}>
              {checked && (
                <Typography
                  sx={{
                    color: "black",
                    fontSize: "16px",
                    padding: "12px",
                  }}
                >
                  {questionDataApi.answer}
                </Typography>
              )}
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                sx={{
                  padding: "10px 0px",
                  borderRadius: "8px",
                }}
              >
                <Switch
                  checked={checked}
                  onChange={handleToggle}
                  color="success"
                />
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "500",
                    marginLeft: "8px",
                  }}
                >
                  Show Answer
                </Typography>
              </Box>

              <Box
                sx={{
                  pr: 2,
                  display: "flex",
                  background: "white",
                  width: "100%",
                  alignItems: "center",
                  borderRadius: "6px",
                  justifyContent: "space-evenly",
                }}
              >
                <AudioComponent src={recordedAudio.blobURL} />

                <Button
                  variant="contained"
                  sx={{
                    mt: 0,
                    bgcolor: "#4caf50",
                    color: "#fff",
                    width: "100%",
                    "&:hover": {
                      bgcolor: "#357a38",
                    },
                    "&.MuiButton-contained": {
                      backgroundColor: "#4caf50",
                      "&:hover": {
                        backgroundColor: "#357a38",
                      },
                    },
                  }}
                  onClick={handleSubmitAnalysis}
                >
                  Analyze Recording
                </Button>
              </Box>
            </Box>
          )}
        </div>
      </div>

      <Modal
        open={openAnalysisModal}
        onClose={() => setOpenAnalysisModal(false)}
        aria-labelledby="analysis-modal"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "auto",
            bgcolor: "#FFFFFF",
            borderRadius: "12px",
            p: 3,
            outline: "none",
            textAlign: "center",
          }}
        >
          {/* Green Checkmark Circle */}

          <Box
            sx={{
              width: "64px",
              height: "64px",
              bgcolor: "#00E676",
              borderRadius: "50%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              margin: "0 auto 20px",
            }}
          >
            <i
              className="fas fa-check"
              style={{ color: "#FFFFFF", fontSize: "32px" }}
            />
          </Box>

          <Typography
            variant="h6"
            sx={{
              color: "#000000",
              fontSize: "24px",
              fontWeight: 500,
              mb: 3,
            }}
          >
            Score:{scores.totalScore}/{total}
          </Typography>
          {/* Score Categories */}

          {/* <div className="mb-4">
            <Typography variant="h6" gutterBottom>
              Scores:
            </Typography>
            <Typography>Pronunciation: {scores.pronunciation}/90</Typography>
            <Typography>Fluency: {scores.fluency}/90</Typography>
            <Typography>Content: {scores.content}/90</Typography>
            <Typography>Total Score: {scores.totalScore}/90</Typography>
          </div> */}

          <Box sx={{ mb: 3 }}>
            {[
              { label: "Content", score: scores.content, total: 90 },
              { label: "Fluency", score: scores.fluency, total: 90 },
              {
                label: "Pronunciation",
                score: scores.pronunciation,
                total: 90,
              },
            ].map((item, index) => (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center !important",
                  mb: 2,
                  width: "450px",
                }}
              >
                <Typography
                  sx={{
                    flex: 1,
                    textAlign: "left",
                    color: "#666666",
                    width: "20%",
                    fontSize: "18px",
                    fontWeight: "bold",
                  }}
                >
                  {item.label}
                </Typography>

                <Box
                  sx={{
                    flex: 2,
                    height: "6px",
                    bgcolor: "#F0F0F0",
                    borderRadius: "3px",
                    position: "relative",
                    mx: 2,
                    width: "60%",
                  }}
                >
                  <Box
                    sx={{
                      position: "absolute",
                      left: 0,
                      top: 0,
                      height: "100%",
                      paddingRight: `${(item.score / item.total) * 100}%`,
                      bgcolor: "#00E676",
                      borderRadius: "3px",
                    }}
                  />
                </Box>

                <Typography
                  sx={{ color: "#666666", minWidth: "70px", width: "20%" }}
                >
                  {item.score}/{item.total}
                </Typography>
              </Box>
            ))}
          </Box>

          {/* Transcription Display */}
          {scores.content && (
            <Box sx={{ mb: 3, textAlign: "left" }}>
              <Typography sx={{ color: "#666666", fontWeight: 500, mb: 1 }}>
                Your Speech:
              </Typography>

              <Typography sx={{ color: "#666666", fontSize: "14px", width: '450px' }}>
                <p>{spokenWords.join(" ") || "No speech recorded"}</p>
              </Typography>
            </Box>
          )}
          {/* OK Button */}
          <Button
            fullWidth
            sx={{
              bgcolor: "#00E676",
              color: "#FFFFFF",
              borderRadius: "25px",
              py: 1.5,
              textTransform: "none",
              fontSize: "16px",
              "&:hover": {
                bgcolor: "#00C853",
              },
            }}
            onClick={() => setOpenAnalysisModal(false)}
          >
            Ok
          </Button>
        </Box>
      </Modal>
    </CardContent>
  );
};
export default PteDescribeImage;