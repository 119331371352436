import React, { useEffect, useState, useRef } from 'react';
import { Box, Typography, IconButton, Button, Modal, Select, MenuItem, Switch } from '@mui/material';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useLocation, useNavigate } from 'react-router-dom';
import { getApi } from 'services/axiosInstance';
import { API_PATH } from 'services/apipath';
import { postApi } from 'services/axiosInstance';

function ReorderParagraphs({ questionData }) {
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const [openSearchModal, setOpenSearchModal] = useState(false);
  const [sourceParagraphs, setSourceParagraphs] = useState([]);
  const [targetParagraphs, setTargetParagraphs] = useState([]);
  const [correctOrder, setCorrectOrder] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentQuestion, setCurrentQuestion] = useState(1);
  const [questionid, setQuestionId] = useState();
  const [questionDataApi, setQuestionDataApi] = useState([]);
  const [checked, setChecked] = useState(false);
  const [orderStats, setOrderStats] = useState({ correct: 0, incorrect: 0 });

  const handleToggle = (event) => {
    setChecked(event.target.checked);
  };

  const Category = params.get("category");
  const id = location.pathname.split("/")[2];

  useEffect(() => {
    if (id) fetchQuestionById(id);
  }, []);


  const findIndex = () => {
    const questionIndex = questionData.questions?.findIndex(
      (question) => question.id === id
    );

    setCurrentIndex(questionIndex);
    setCurrentQuestion(questionIndex + 1);
    if (questionIndex === -1) {
    }
  };

  useEffect(() => {
    if (questionData) {
      findIndex()
    }

  }, [questionData]);

  const fetchQuestionById = async (id) => {
    setLoading(true);
    try {
      const res = await getApi(`${API_PATH.STUDENTS.GET_QUESTION_BY_ID}/${id}`);
      if (res.status === 200) {
        const transformedChoices = res.data.data.choices.map((choice, index) => ({
          id: `${index}`,
          content: choice.choice_text,
        }));
        setSourceParagraphs(transformedChoices);
        setCorrectOrder(res.data.data.correct_order);
        setQuestionDataApi(res.data.data);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const { source, destination } = result;

    if (source.droppableId === destination.droppableId) {
      const items = Array.from(
        source.droppableId === "source" ? sourceParagraphs : targetParagraphs
      );
      const [reorderedItem] = items.splice(source.index, 1);
      items.splice(destination.index, 0, reorderedItem);

      if (source.droppableId === "source") setSourceParagraphs(items);
      else setTargetParagraphs(items);
    } else {
      const sourceItems = Array.from(
        source.droppableId === "source" ? sourceParagraphs : targetParagraphs
      );
      const destItems = Array.from(
        destination.droppableId === "source" ? sourceParagraphs : targetParagraphs
      );
      const [movedItem] = sourceItems.splice(source.index, 1);
      destItems.splice(destination.index, 0, movedItem);

      if (source.droppableId === "source") {
        setSourceParagraphs(sourceItems);
        setTargetParagraphs(destItems);
      } else {
        setSourceParagraphs(destItems);
        setTargetParagraphs(sourceItems);
      }
    }
  };

  const [selectedAnswer, setSelectedAnswer] = useState([]);
  const [submitedAns, setSubmitedAns] = useState(null);
  const [showAnswer, setShowAnswer] = useState(false);

  const calculateOrderStats = (userOrder, correctOrder) => {
    let correct = 0;
    let incorrect = 0;

    // Compare each item in the order
    userOrder.forEach((item, index) => {
      if (index < correctOrder.length) {
        if (Number(item) === correctOrder[index]) {
          correct++;
        } else {
          incorrect++;
        }
      }
    });

    return { correct, incorrect };
  };

  const handleSubmit = async () => {
    setIsSubmitted(true)
    setTimeTaken(time)
    setIsTimerRunning(false);
    if (timerRef.current) {
      clearInterval(timerRef.current);
    }

    const transformedChoices = targetParagraphs.map((choice) => (
      JSON.parse(choice.id)
    ));

    setSelectedAnswer(transformedChoices);

    // Calculate correct and incorrect order counts
    const stats = calculateOrderStats(transformedChoices, correctOrder);
    setOrderStats(stats);

    if (JSON.stringify(correctOrder) === JSON.stringify(transformedChoices)) {
      setSubmitedAns(true);
    } else {
      setSubmitedAns(false);
    }

    const payload = {
      questionId: id,
      answers: transformedChoices,
      correctAnswers: correctOrder,
      timeSpent: time,
    };

    try {
      const answer = await postApi(API_PATH.STUDENTS.SUBMIT_ANS, payload);
      if (answer.status === 200) {
        // Handle success if needed
      }
    } catch (error) {
      // Handle error if needed
    }
  };

  const handleNext = async () => {
    setIsSubmitted(false)
    setTimeTaken(0)
    setTargetParagraphs([])
    if (currentQuestion === questionData.totalQuestions) {
      alert("Congratulations You have completed all questions!");
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
      return;
    } else {

      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
      setIsTimerRunning(true);

      setCurrentQuestion(currentQuestion + 1);
      setCurrentIndex(currentIndex + 1);
      fetchQuestionById(questionData.questions[currentIndex + 1].id);
      setSubmitedAns(null);
      setSelectedAnswer([]);
      setOrderStats({ correct: 0, incorrect: 0 });
    }
  };

  const handlePrev = async () => {
    setIsSubmitted(false)
    setTimeTaken(0)
    setTargetParagraphs([])
    if (currentQuestion > 1) {

      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
      setIsTimerRunning(true);

      setCurrentQuestion(currentQuestion - 1);
      setCurrentIndex(currentIndex - 1);
      setQuestionId(questionData.questions[currentIndex - 1].id);
      fetchQuestionById(questionData.questions[currentIndex - 1].id);
      setSubmitedAns(null);
      setSelectedAnswer([]);
      setOrderStats({ correct: 0, incorrect: 0 });
    }
  };

  // <================timer functionality==================>
  const [time, setTime] = useState(0);
  const [timeTaken, setTimeTaken] = useState(0);
  const [isTimerRunning, setIsTimerRunning] = useState(true);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const timerRef = useRef(null);
  const startTimeRef = useRef(Date.now());

  useEffect(() => {
    startTimeRef.current = Date.now();
    setTime(0);

    if (isTimerRunning) {
      timerRef.current = setInterval(() => {
        setTime(Math.floor((Date.now() - startTimeRef.current) / 1000));
      }, 1000);
    }

    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    };
  }, [currentIndex, isTimerRunning]);

  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = timeInSeconds % 60;
    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };


  return (
    <Box sx={{ bgcolor: "#1E2029", minHeight: "100vh", color: "#fff" }}>
      {/* Header */}
      <Box
        sx={{
          bgcolor: "#1A1B2E",
          p: 2,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          borderBottom: "1px solid rgba(255,255,255,0.1)",
          position: "sticky",
          top: 0,
          zIndex: 1000,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <IconButton sx={{ color: "#fff" }} onClick={() => navigate(-1)}>
            <i className="fas fa-arrow-left" />
          </IconButton>
          <Typography sx={{ ml: 2, fontSize: "20px" }}>Reorder Paragraphs</Typography>
        </Box>
        <Box
          sx={{ display: "flex", alignItems: "center", gap: 1, ml: "auto" }}
        >
          <Typography sx={{ color: "#fff", fontSize: "14px" }}>
            {currentQuestion}/{questionData.totalQuestions}
          </Typography>
          <IconButton sx={{ color: "#fff" }} onClick={() => handlePrev()}>
            <i className="fas fa-chevron-left" />
          </IconButton>
          <IconButton
            sx={{ color: "#fff" }}
            onClick={() => setOpenSearchModal(true)}
          >
            <i className="fas fa-search" />
          </IconButton>
          <IconButton sx={{ color: "#fff" }}>
            <i className="fas fa-rotate" />
          </IconButton>
          <IconButton sx={{ color: "#fff" }} onClick={() => handleNext()}>
            <i className="fas fa-chevron-right" />
          </IconButton>
        </Box>
      </Box>

      {/* Main Content */}
      <Box sx={{ pt: 2, overflowY: "auto", px: 3 }}>
        <Typography sx={{ color: "rgba(255,255,255,0.8)", mb: 2, fontStyle: "italic", fontSize: "16px" }}>
          The text boxes in the left panel have been placed in a random order. Restore the
          original order by dragging the text boxes from the left panel to the right panel.
        </Typography>

        <DragDropContext onDragEnd={handleDragEnd}>
          <Box sx={{ display: "flex", gap: 4, mt: 4, fontSize: '12px !important', }}>
            <DroppableSection
              id="source"
              title="Source"
              items={sourceParagraphs}
              setItems={setSourceParagraphs}
            />
            <DroppableSection
              id="target"
              title="Target"
              items={targetParagraphs}
              correct={correctOrder}
              submitedAns={submitedAns}
              showAnswer={showAnswer}
              setItems={setTargetParagraphs}
            />
          </Box>
        </DragDropContext>
      </Box>

      {/* Submit Button and Order Stats */}
      <Box
        sx={{
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          bgcolor: "#1E2029",
          pb: 2,
          pt: 4,
        }}
      >
        {/* Add the timer display here */}
        <Box sx={{
          display: "flex",
          alignItems: "center",
          bgcolor: "rgba(0,0,0,0.3)",
          p: 1,
          borderRadius: "8px",
          minWidth: "100px",
          justifyContent: "center",
          marginRight: "16px" // Add some spacing between timer and button
        }}>
          <i className="fas fa-clock" style={{ color: "#fff", marginRight: "8px" }} />
          <Typography sx={{ color: "#fff", fontSize: "16px", fontWeight: "bold" }}>
            {isSubmitted ? formatTime(timeTaken) : formatTime(time)}
          </Typography>
        </Box>

        {submitedAns === null ? (
          <Button
            onClick={handleSubmit}
            variant="contained"
            sx={{
              bgcolor: "#00A693",
              "&:hover": { bgcolor: "#009688" },
              textTransform: "none",
              px: 4,
              py: 1,
              fontSize: "16px",
              borderRadius: "4px",
              minWidth: "120px",
              color: "#fff",
            }}
          >
            Submit
          </Button>
        ) : (
          <Box sx={{ display: "flex", width: "100%", position: "relative" }}>
            <Box>
              {submitedAns !== null && (
                <Button
                  sx={{
                    bgcolor: "#00BFA6",
                    color: "#fff",
                    borderRadius: "8px",
                    fontSize: "14px",
                    px: 4,
                    py: 2,
                    "&:hover": { bgcolor: "#00BFA6" },
                  }}
                >
                  {orderStats.correct} / {targetParagraphs.length}
                </Button>
              )}
            </Box>
          </Box>
        )}

        {submitedAns !== null && (
          <Box
            sx={{
              position: "absolute",
              bottom: 16,
              right: 16,
              display: "flex",
              alignItems: "center",
              gap: 1,
            }}
          >
            <Typography sx={{ color: "#fff" }}>See Answer</Typography>
            <Switch
              checked={showAnswer}
              onChange={() => setShowAnswer(!showAnswer)}
              color="primary"
            />
          </Box>
        )}
      </Box>
    </Box>
  );
}

const DroppableSection = ({ id, title, items, setItems, correct, submitedAns, showAnswer }) => {
  const renderItems = showAnswer
    ? correct.map((index) => {
      const item = items.find((item) => item.id === String(index));
      return item ? item : null;
    }).filter((item) => item !== null)
    : items;
  const isCorrectOrder = items.map(item => item.id).join(",") === correct?.join(",");

  const bgcolor = showAnswer ? "green" : submitedAns === true
    ? "green"
    : submitedAns === false
      ? "red"
      : "#2F315A";

  return (
    <Box sx={{ flex: 1 }}>
      <Typography sx={{ mb: 2, fontWeight: "bold" }}>{title}</Typography>
      <Droppable droppableId={id}>
        {(provided) => (
          <Box
            ref={provided.innerRef}
            {...provided.droppableProps}
            sx={{
              minHeight: 350,
              bgcolor: '#ffffff05',
              borderRadius: 1,
              p: 1.5,
              fontSize: "15px !important",
              transition: "background-color 0.3s ease",

            }}
          >
            {renderItems.map((item, index) => (
              <Draggable key={item.id} draggableId={item.id} index={index}>
                {(provided) => (
                  <Box
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    sx={{
                      p: 1.5,
                      mb: 2,
                      bgcolor: bgcolor,
                      fontSize: "12px !important",
                      borderRadius: 1,
                      "&:hover": { bgcolor: "#2F315A" },
                    }}
                  >
                    <Typography>{item.content}</Typography>
                  </Box>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </Box>
        )}
      </Droppable>
    </Box>
  );
};

export default ReorderParagraphs;