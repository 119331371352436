import React, { useEffect, useState } from 'react';
import { Box, Typography, IconButton, Button, Paper, Select, Modal, MenuItem, Container, Switch } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { API_PATH } from 'services/apipath';
import { getApi } from 'services/axiosInstance';
import AudioPlayer from 'layouts/listening/AudioPlayer';
import RepeatParagraphAloud from 'components/AudioPlayer/AudioPlayer';
import { postApi } from 'services/axiosInstance';

function IncorrectWords({ questionData }) {
  const navigate = useNavigate()
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const Category = params.get("category");
  let id = location.pathname.split("/")[2];

  const [currentQuestion, setCurrentQuestion] = useState(1);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [submitedAns, setSubmitedAns] = useState(null);
  const [showAnswer, setShowAnswer] = useState(false);
  const [openAnalysisModal, setOpenAnalysisModal] = useState(false);
  const [scores, setScores] = useState({
    content: 0,
    pronunciation: 0,
    fluency: 0,
    overall: 0,
  });

  // Add states for tracking word counts
  const [totalCorrectWords, setTotalCorrectWords] = useState(0);
  const [correctIdentified, setCorrectIdentified] = useState(0);
  const [incorrectSelections, setIncorrectSelections] = useState(0);



  const [selectedWords, setSelectedWords] = useState([]);
  const [openSearchModal, setOpenSearchModal] = useState(false);
  const [questionid, setQuestionId] = useState();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [questionDataApi, setQuestionDataApi] = useState([]);
  const getQuestionBtID = async (id) => {
    try {
      const res = await getApi(`${API_PATH.STUDENTS.GET_QUESTION_BY_ID}/${id}`);
      if (res.status === 200) {
        setQuestionDataApi(res.data.data);

        if (res.data.data.mcq_ans && Array.isArray(res.data.data.mcq_ans)) {
          setTotalCorrectWords(res.data.data.mcq_ans.length);
        }
      }
    } catch (error) { }
  };

  useEffect(() => {
    if (id) {
      getQuestionBtID(id);
    }
  }, []);

  const text = questionDataApi?.question_text ? questionDataApi.question_text : '';

  const words = text.split(' ').map((word, index) => ({
    word,
    id: `${word}-${index}`,
  }));

  const handleWordClick = (wordId) => {
    if (selectedWords.includes(wordId)) {
      setSelectedWords(selectedWords.filter(id => id !== wordId));
    } else {
      setSelectedWords([...selectedWords, wordId]);
    }
  };

  const handleNext = async () => {
    if (currentQuestion === questionData.totalQuestions) {
      alert("Congratulations You have completed all questions!");
      return;
    } else {
      setCurrentQuestion(currentQuestion + 1);
      setCurrentIndex(currentIndex + 1);
      getQuestionBtID(questionData.questions[currentIndex + 1].id);
      setSubmitedAns(null);
      setSelectedAnswer(null);
      setSelectedWords([]);
      setCorrectIdentified(0);
      setIncorrectSelections(0);
      setShowAnswer(false);
    }
  };

  const handlePrev = async () => {
    if (currentQuestion > 1) {
      setCurrentQuestion(currentQuestion - 1);
      setCurrentIndex(currentIndex - 1);
      setQuestionId(questionData.questions[currentIndex - 1].id);
      getQuestionBtID(questionData.questions[currentIndex - 1].id);
      setSubmitedAns(null);
      setSelectedAnswer(null);
      setSelectedWords([]);
      setCorrectIdentified(0);
      setIncorrectSelections(0);
      setShowAnswer(false);
    }
  };

  const handleSubmit = async () => {
    if (!questionDataApi || !questionDataApi.mcq_ans) return;

    const correctWordIds = questionDataApi.mcq_ans.map((id) => parseInt(id, 10));

    const selectedWordIds = selectedWords.map((wordId) => {
      const [, id] = wordId.split('-');
      return parseInt(id, 10);
    });

    const correctSelections = selectedWordIds.filter((id) => correctWordIds.includes(id));
    const incorrectSelections = selectedWordIds.filter((id) => !correctWordIds.includes(id));
    const missedWords = correctWordIds.filter((id) => !selectedWordIds.includes(id));

    // Update count states
    setCorrectIdentified(correctSelections.length);
    setIncorrectSelections(incorrectSelections.length);

    setSubmitedAns({
      correctSelections,
      incorrectSelections,
      missedWords,
    });
    const payload = {
      questionId: id,
      answers: JSON.stringify(selectedWordIds),
      correctAnswers: JSON.stringify(correctSelections),
    };
    try {
      const answer = await postApi(API_PATH.STUDENTS.SUBMIT_ANS, payload);
      if (answer.status === 200) {
      }
    } catch (error) { }
  };




  return (
    <Box sx={{ bgcolor: "#1A1B2E", minHeight: "100vh", color: "#fff" }}>
      <Box
        sx={{
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bgcolor: "#1A1B2E",
          zIndex: 1000,
        }}
      >
        <Container maxWidth="lg">
          <Box
            sx={{
              p: 2,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              borderBottom: "1px solid rgba(255,255,255,0.1)",
            }}
          >
            <IconButton sx={{ color: "#fff" }} onClick={() => navigate(-1)}>
              <i className="fas fa-arrow-left" />
            </IconButton>

            <Typography variant="h6" sx={{ color: "#fff", ml: "20px" }}>
              Incorrect Words
            </Typography>

            <Box
              sx={{ display: "flex", alignItems: "center", gap: 1, ml: "auto" }}
            >
              <Typography sx={{ color: "#fff", fontSize: "14px" }}>
                {currentQuestion}/{questionData.totalQuestions}
              </Typography>
              <IconButton sx={{ color: "#fff" }} onClick={() => handlePrev()}>
                <i className="fas fa-chevron-left" />
              </IconButton>
              <IconButton
                sx={{ color: "#fff" }}
                onClick={() => setOpenSearchModal(true)}
              >
                <i className="fas fa-search" />
              </IconButton>
              <IconButton sx={{ color: "#fff" }}>
                <i className="fas fa-rotate" />
              </IconButton>
              <IconButton sx={{ color: "#fff" }} onClick={() => handleNext()}>
                <i className="fas fa-chevron-right" />
              </IconButton>
            </Box>
          </Box>
        </Container>
      </Box>
      {/* Instructions */}
      <Box sx={{
        flex: 1,
        overflowY: "auto",
        px: 14,
        pt: 10.5,
      }}>
        <Paper sx={{
          bgcolor: "#1A1B2E",
          px: 2,
          mb: 3,
          borderRadius: 2,
          color: "#fff"
        }}>
          <Typography sx={{ fontSize: "14px" }}>
            You will hear a recording. Below is a transcription of the recording. Some words in the transcription differ from what the speaker(s) said. Please click on the words that are different.
          </Typography>
        </Paper>

        {/* <Aution Text /> */}
        {/* <AudioPlayer
          audioText={JSON.stringify(questionDataApi.audio_text)}
          delay={3}
        /> */}

        {/* Word Stats Summary - Added */}
        {/* {submitedAns && (
          <Paper sx={{
            bgcolor: "#242538",
            p: 2,
            mb: 3,
            borderRadius: 2,
            color: "#fff"
          }}>
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <Box>
                <Typography sx={{ fontSize: "16px", fontWeight: "bold", mb: 1 }}>
                  Your Score
                </Typography>
                <Typography sx={{ fontSize: "14px", color: "#05cb05" }}>
                  Correct words identified: {correctIdentified}/{totalCorrectWords} ({Math.round((correctIdentified / totalCorrectWords || 0) * 100)}%)
                </Typography>
                <Typography sx={{ fontSize: "14px", color: "#c90c0c" }}>
                  Incorrect selections: {incorrectSelections}
                </Typography>
              </Box>
              <Box sx={{ textAlign: "right" }}>
                <Typography sx={{ fontSize: "16px", fontWeight: "bold" }}>
                  {correctIdentified} / {totalCorrectWords}
                </Typography>
                <Typography sx={{ fontSize: "14px", color: submitedAns.missedWords.length > 0 ? "#f39c12" : "#05cb05" }}>
                  {submitedAns.missedWords.length > 0 ? "Missed some words" : "All words found"}
                </Typography>
              </Box>
            </Box>
          </Paper>
        )} */}

        <RepeatParagraphAloud
          // onAudioComplete={handleAudioComplete}
          audioText={JSON.stringify(questionDataApi.audio_text)}
          audioFile={questionDataApi.audio_file}
          delay={3}
        />
        {/* Text Content */}
        <Box sx={{ mb: 4, lineHeight: 2 }}>
          {words.map((item, index) => {
            const isSelected = selectedWords.includes(item.id);
            const isCorrect = submitedAns?.correctSelections?.includes(index);
            const isMissed = submitedAns?.missedWords?.includes(index);
            const isIncorrect = submitedAns?.incorrectSelections?.includes(index);

            console.log(isSelected, isCorrect, isMissed, isIncorrect, "isSelected, isCorrect, isMissed, isIncorrect");

            let backgroundColor = "transparent";
            let color = "#fff";

            if (submitedAns && !showAnswer) {
              // After submission
              if (isCorrect) {
                backgroundColor = "#05cb05"; // Green for correctly selected words
                color = "#fff";
              } else if (isMissed) {
                backgroundColor = "#0b810bcf"; // Blue for missed words
                color = "#fff";
              } else if (isIncorrect) {
                backgroundColor = "#c90c0c"; // Red for incorrect words
                color = "#fff";
              }
            } else if (showAnswer) {

              // Show correct answers based on indices
              const wordIndex = item.id.split('-')[1]; // Keep as string
              if (questionDataApi.mcq_ans.includes(wordIndex)) {
                backgroundColor = "#05cb05"; // Green for correct words
              }

            } else if (isSelected) {
              // Default selection
              backgroundColor = "#FFB800"; // Yellow for selected words
            }

            return (
              <span
                key={item.id}
                onClick={() => handleWordClick(item.id)}
                style={{
                  cursor: "pointer",
                  backgroundColor,
                  color,
                  borderRadius: "4px",
                  padding: "4px",
                  transition: "all 0.2s ease",
                  fontSize: "18px",
                }}
              >
                {item.word}{" "}
              </span>
            );
          })}
        </Box>

        {/* Fixed Footer with Background */}
        <Box
          sx={{
            position: "relative",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            pb: 2,
          }}
        >
          {submitedAns === null && (
            <Button
              onClick={handleSubmit}
              variant="contained"
              sx={{
                bgcolor: "#00A693",
                "&:hover": { bgcolor: "#009688" },
                textTransform: "none",
                px: 4,
                py: 1,
                fontSize: "16px",
                borderRadius: "4px",
                minWidth: "100px",
                color: "#fff",
              }}
            >
              Submit
            </Button>
          )}

          {submitedAns !== null && (
            <>
              {/* Left Side Toggle */}
              <Box
                sx={{
                  position: "absolute",
                  bottom: 0,
                  left: 5,
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                <Button
                  sx={{
                    bgcolor: "#00BFA6",
                    color: "#fff",
                    borderRadius: "8px",
                    fontSize: "14px",
                    px: 3,
                    py: 1,
                    "&:hover": { bgcolor: "#00BFA6" },
                  }}
                >
                  {correctIdentified} / {totalCorrectWords}
                </Button>
              </Box>

              {/* Right Side Toggle */}
              <Box
                sx={{
                  position: "absolute",
                  bottom: 0,
                  right: 5, // Positioned on the right
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                <Typography sx={{ color: "#fff" }}>See Answer</Typography>
                <Switch
                  checked={showAnswer}
                  onChange={() => setShowAnswer(!showAnswer)}
                  color="primary"
                />
              </Box>
            </>
          )}
        </Box>

      </Box>





      {/* Search Modal */}
      <Modal
        open={openSearchModal}
        onClose={() => setOpenSearchModal(false)}
        aria-labelledby="search-modal"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "#1A1B2E",
            borderRadius: "8px",
            p: 3,
            outline: "none",
            maxHeight: "80vh",
            overflow: "auto",
          }}
        >
          {/* Modal Header */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 3,
              borderBottom: "1px solid rgba(255, 255, 255, 0.1)",
              pb: 2,
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
              <Select
                value="All"
                size="small"
                sx={{
                  color: "#fff",
                  height: "32px",
                  minWidth: "100px",
                  ".MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(255,255,255,0.3)",
                    borderRadius: "4px",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(255,255,255,0.5)",
                  },
                }}
              >
                <MenuItem value="All">All</MenuItem>
              </Select>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                  gap: 1,
                }}
              >
                <Typography sx={{ color: "#fff", fontSize: "14px" }}>
                  {79} Qs
                </Typography>
              </Box>
            </Box>
            <IconButton
              onClick={() => setOpenSearchModal(false)}
              sx={{
                color: "#fff",
                p: 1,
                "&:hover": { bgcolor: "rgba(255,255,255,0.1)" },
              }}
            >
              <i className="fas fa-times" />
            </IconButton>
          </Box>

          {/* Search Items */}
          {/* <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            {searchItems.map((item, index) => (
              <Box
                key={index}
                sx={{
                  bgcolor: "#262842",
                  borderRadius: "8px",
                  p: 2,
                  cursor: "pointer",
                  "&:hover": {
                    bgcolor: "#2F315A",
                  },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography sx={{ color: "#fff", fontSize: "14px" }}>
                    {index + 1}. {item.id}
                  </Typography>
                  <Box sx={{ display: "flex", gap: 1 }}>
                    <Box
                      sx={{
                        bgcolor: "#FFC107",
                        px: 1,
                        borderRadius: "4px",
                        fontSize: "12px",
                        fontWeight: 500,
                        color: "#000",
                      }}
                    >
                      New
                    </Box>
                    <Box
                      sx={{
                        bgcolor: "#FFC107",
                        px: 1,
                        borderRadius: "4px",
                        fontSize: "12px",
                        fontWeight: 500,
                        color: "#000",
                      }}
                    >
                      VIP
                    </Box>
                  </Box>
                </Box>
                <Box sx={{ mt: 1 }}>
                  <Typography
                    sx={{
                      color: "rgba(255,255,255,0.6)",
                      fontSize: "12px",
                      display: "flex",
                      alignItems: "center",
                      gap: 0.5,
                    }}
                  >
                    Attempted: {item.attempted}
                  </Typography>
                </Box>
              </Box>
            ))}
          </Box> */}
        </Box>
      </Modal>
    </Box >
  );
}

export default IncorrectWords;