import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  IconButton,
  Button,
  TextField,
  Switch,
  FormControlLabel,
} from "@mui/material";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { getApi } from "services/axiosInstance";
import { API_PATH } from "services/apipath";
import { processQuestionText } from "utils/ProcessQuestionText";
import AudioPlayer from "layouts/listening/AudioPlayer";
import RepeatParagraphAloud from "components/AudioPlayer/AudioPlayer";
import { postApi } from "services/axiosInstance";

function FillInBlanksAnswer({ questionData }) {
  console.log({ questionData, questions: questionData.questions });

  const navigate = useNavigate();
  const location = useLocation();
  const [questions, setQuestions] = useState(null);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [showAnswer, setShowAnswer] = useState(false);
  // const [loading, setLoading] = useState(true);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isCorrect, setIsCorrect] = useState(false);
  const [blanks, setBlanks] = useState({});
  const [correctAnswers, setCorrectAnswers] = useState([]);
  const [submitedAns, setSubmitedAns] = useState(null);
  const [totalQuestions, setTotalQuestions] = useState(1);

  const getQuestionIdFromUrl = () => {
    const pathSegments = location.pathname.split("/");
    return pathSegments[pathSegments.length - 1];
  };

  const idFromUrl = getQuestionIdFromUrl();
  console.log("Extracted idFromUrl:", idFromUrl);

  // const [questionDataApi, setQuestionDataApi] = useState([]);
  const [questionId, setQuestionId] = useState(idFromUrl);
  console.log("Extracted questionId:", questionId);

  let id = location.pathname.split("/")[2];
  const params = new URLSearchParams(location.search);
  const category = params.get("category");
  const subcategory = params.get("subcategory");

  const findIndex = () => {
    const questionIndex = questionData.questions?.findIndex(
      (question) => question.id === questionId
    );
    if (questionIndex !== -1) {
      setCurrentQuestionIndex(questionIndex);
    }
  };

  useEffect(() => {

    if (questionData && questionData.totalQuestions) {
      findIndex()

      setTotalQuestions(questionData.totalQuestions);
    }
  }, [questionData]);

  const getQuestionBtID = async (id) => {
    try {
      console.log(id);

      const res = await getApi(`${API_PATH.STUDENTS.GET_QUESTION_BY_ID}/${id}`);
      console.log(res.status);

      if (res.status === 200) {
        console.log({ data: res.data.data });
        const filteredQuestion = res.data.data;
        console.log({ filteredQuestion });

        setQuestions(filteredQuestion);

        // setQuestionDataApi(filteredQuestion);
        if (filteredQuestion) {
          const { totalBlanks } = processQuestionText(
            filteredQuestion.question_text
          );

          setTotalBlanks(totalBlanks);

          // Initialize blanks
          const initialBlanks = {};
          for (let i = 1; i <= totalBlanks; i++) {
            initialBlanks[`blank${i}`] = "";
          }
          setBlanks(initialBlanks);

          // Store correct answers for comparison and "Show Answer"
          setCorrectAnswers(filteredQuestion.FillInTheBlanksAnswers);
        }


      }
    } catch (error) { }
  };

  useEffect(() => {
    console.log({ questionId });

    if (questionId) {
      getQuestionBtID(questionId);
    }
  }, [questionId]);

  const [totalBlanks, setTotalBlanks] = useState(0);
  const [correctCount, setCorrectCount] = useState(0);
  const [incorrectCount, setIncorrectCount] = useState(0);

  const fetchQuestions = async (qId) => {
    try {
      const response = await getApi(
        `v2/student/get-questions?ids=${qId}`
      );

      if (response.data && response.data.data) {
        const questionListing = response.data.data.filter(
          (question) => question.module_type[0] === category && question.module_subcategory[0] === subcategory
        );
        console.log({ questionListing });

        setTotalQuestions(questionListing.length);

        const filteredQuestion = questionListing.filter((question) => question._id === qId);
        console.log({ filteredQuestion });
        setQuestions(filteredQuestion);

        if (filteredQuestion[0]) {
          const { totalBlanks } = processQuestionText(
            filteredQuestion[0].question_text
          );

          // Set total blanks count
          setTotalBlanks(totalBlanks);

          // Initialize blanks
          const initialBlanks = {};
          for (let i = 1; i <= totalBlanks; i++) {
            initialBlanks[`blank${i}`] = "";
          }
          setBlanks(initialBlanks);

          // Store correct answers for comparison and "Show Answer"
          setCorrectAnswers(filteredQuestion[0].FillInTheBlanksAnswers);
        }
      }
    } catch (error) {
      // Handle error
    }
  };

  // useEffect(() => {
  //   if (questionId) {
  //     fetchQuestions(questionId);
  //   }
  // }, [questionId]);

  // const currentQuestion = questions;
  console.log({ questions });

  const currentQuestion = questions;

  const handleInputChange = (event, blankId) => {
    setBlanks({
      ...blanks,
      [blankId]: event.target.value,
    });
    setIsSubmitted(false);
  };

  const handleSubmit = async () => {
    if (!currentQuestion || !correctAnswers.length) return;

    const newBlankStatus = {};
    let correctCounter = 0;
    let incorrectCounter = 0;

    // Validate each blank
    Object.entries(blanks).forEach(([blankId, value], index) => {
      const isCorrect = value.trim().toLowerCase() === correctAnswers[index].toLowerCase();
      newBlankStatus[blankId] = isCorrect;

      if (isCorrect) {
        correctCounter++;
      } else {
        incorrectCounter++;
      }
    });

    // Update counts
    setCorrectCount(correctCounter);
    setIncorrectCount(incorrectCounter);

    setIsSubmitted(true);

    // Check if all blanks are correct
    const isAllCorrect = Object.values(newBlankStatus).every((status) => status);

    setIsCorrect(isAllCorrect);
    const payload = {
      questionId: questionId,
      answers: JSON.stringify(newBlankStatus),
      correctAnswers: JSON.stringify(correctAnswers),
    };

    try {
      const answer = await postApi(API_PATH.STUDENTS.SUBMIT_ANS, payload);
      if (answer.status === 200) {
      }
    } catch (error) { }
  };

  // const handleNextQuestion = () => {
  //   if (currentQuestionIndex < questions.length - 1) {
  //     setCurrentQuestionIndex((prev) => prev + 1);
  //     const nextQuestion = questions[currentQuestionIndex + 1];
  //     if (nextQuestion) {
  //       const { totalBlanks } = processQuestionText(nextQuestion.question_text);
  //       const newBlanks = {};
  //       for (let i = 1; i <= totalBlanks; i++) {
  //         newBlanks[`blank${i}`] = "";
  //       }
  //       setBlanks(newBlanks);
  //     }
  //     setIsSubmitted(false);
  //     setIsCorrect(false);
  //     setShowAnswer(false);
  //   }
  // };

  const handleNextQuestion = () => {
    if (currentQuestionIndex === questionData.totalQuestions) {
      alert("Congratulations You have completed all questions!");
      return;
    } else {
      let nextIndex = currentQuestionIndex + 1;
      setCurrentQuestionIndex(nextIndex);
      let nextQuestion = questionData.questions[nextIndex];
      let nextId = nextQuestion.id
      // if (nextId === idFromUrl && nextIndex < questionData.questions.length - 1) {
      //   nextIndex = nextIndex + 1;
      //   nextQuestion = questionData.questions[nextIndex];
      //   nextId = nextQuestion.id;
      // }
      if (nextQuestion) {
        setQuestionId(nextId);
        // setCurrentQuestionIndex(nextIndex);
        // getQuestionBtID(nextQuestion.id);
        setSubmitedAns(null);
        // Reset UI state for the new question
        setBlanks({});
        setIsSubmitted(false);
        setIsCorrect(false);
        setCorrectCount(0);
        setIncorrectCount(0);
      }
    }
  };

  const handlePreviousQuestion = () => {

    if (currentQuestionIndex > 0) {
      const prevIndex = currentQuestionIndex - 1;
      setCurrentQuestionIndex(prevIndex);
      const prevQuestion = questionData.questions[prevIndex];
      if (prevQuestion) {
        setQuestionId(prevIndex === 0 ? idFromUrl : prevQuestion.id);
        setCurrentQuestionIndex(prevIndex);
        // getQuestionBtID(prevQuestion.id);
        setSubmitedAns(null);
        // Reset UI state for the new question
        setBlanks({});
        setIsSubmitted(false);
        setIsCorrect(false);
        setCorrectCount(0);
        setIncorrectCount(0);
      }

    }
  };

  return (
    <Box sx={{ bgcolor: "#1A1B2E", minHeight: "100vh", color: "#fff", paddingX: "20px" }}>
      {/* Header */}
      <Box
        sx={{
          p: 2,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          borderBottom: "1px solid rgba(255,255,255,0.1)",
          position: "sticky",
          top: 0,
          zIndex: 1000,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <IconButton sx={{ color: "#fff" }} onClick={() => navigate(-1)}>
            <i className="fas fa-arrow-left" />
          </IconButton>
          <Typography sx={{ ml: 2, fontSize: "20px" }}>Fill in Blanks</Typography>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <Typography>
            {currentQuestionIndex + 1}/{totalQuestions}
          </Typography>
          <IconButton sx={{ color: "#fff" }} onClick={() => handlePreviousQuestion()}>
            <i className="fas fa-chevron-left" />
          </IconButton>
          <IconButton sx={{ color: "#fff" }}>
            <i className="fas fa-rotate" />
          </IconButton>
          <IconButton sx={{ color: "#fff" }} onClick={() => handleNextQuestion()}>
            <i className="fas fa-chevron-right" />
          </IconButton>
        </Box>
      </Box>


      {/* Main Content */}
      <Box sx={{ px: 2, pt: 3 }}>
        {currentQuestion && (
          <>
            <Typography
              sx={{
                color: "rgba(255,255,255,0.8)",
                mb: 2,
                fontStyle: "italic",
              }}
            >
              In the text below some words are missing. Type the correct words
              in the input fields below.
            </Typography>

            {/* <AudioPlayer
              audioText={JSON.stringify(questionDataApi.audio_text)}
              delay={3}
            /> */}

            <RepeatParagraphAloud
              // onAudioComplete={handleAudioComplete}
              audioText={JSON.stringify(currentQuestion.audio_text)}
              audioFile={currentQuestion.audio_file}
              delay={3}
            />

            <Box sx={{ mt: 4, lineHeight: 1.8, fontSize: "19px" }}>
              {(() => {
                const { parts } = processQuestionText(
                  currentQuestion.question_text
                );
                return parts.map((part, index, array) => (
                  <React.Fragment key={index}>
                    {part}
                    {index < array.length - 1 && (
                      <TextField
                        variant="outlined"
                        size="small"
                        value={
                          showAnswer
                            ? correctAnswers[index]
                            : blanks[`blank${index + 1}`] || ""
                        }
                        onChange={(e) =>
                          handleInputChange(e, `blank${index + 1}`)
                        }
                        sx={{
                          mx: 1,
                          width: "120px",
                          backgroundColor: "white",
                        }}
                        disabled={showAnswer} // Disable input when showing answers
                      />
                    )}
                  </React.Fragment>
                ));
              })()}
            </Box>

            {isSubmitted && (
              <Box
                sx={{
                  mt: 3,
                  p: 2,
                  bgcolor: isCorrect
                    ? "rgba(76, 175, 80, 0.1)"
                    : "rgba(244, 67, 54, 0.1)",
                  borderRadius: 1,
                  border: `1px solid ${isCorrect ? "#4CAF50" : "#f44336"}`,
                }}
              >
                <Typography
                  sx={{
                    color: isCorrect ? "#4CAF50" : "#f44336",
                    fontWeight: "bold",
                  }}
                >
                  {isCorrect ? "Correct!" : "Incorrect. Try again!"}
                </Typography>
              </Box>
            )}
          </>
        )}
      </Box>
      {/* Footer */}
      <Box
        sx={{
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          paddingX: "40px",
          paddingBottom: "30px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          bgcolor: "#1A1B2E",
        }}
      >
        {submitedAns === null ? (
          <Button
            variant="contained"
            onClick={() => {
              handleSubmit();
              setSubmitedAns(true); // Ensure submitedAns is updated
            }}
            sx={{
              bgcolor: "#00A693",
              color: "#fff",
              "&:hover": { bgcolor: "#009688" },
            }}
          >
            Submit
          </Button>
        ) : (
          <>
            {/* Left Toggle */}
            <Box
              sx={{
                position: "absolute",
                left: 40,
                display: "flex",
                alignItems: "center",
              }}
            >
              <Button
                sx={{
                  bgcolor: "#00BFA6",
                  color: "#fff",
                  borderRadius: "8px",
                  fontSize: "14px",
                  px: 3,
                  py: 1,
                  "&:hover": { bgcolor: "#00BFA6" },
                }}
              >
                {correctCount} / {totalBlanks}
              </Button>
            </Box>
            {/* Right Toggle */}
            <Box
              sx={{
                position: "absolute",
                right: 40,
                display: "flex",
                alignItems: "center",
              }}
            >
              <FormControlLabel
                control={
                  <Switch
                    checked={showAnswer}
                    onChange={(e) => setShowAnswer(e.target.checked)}
                    sx={{
                      "& .MuiSwitch-switchBase.Mui-checked": {
                        color: "#00A693",
                      },
                    }}
                  />
                }
                label="Show Answer"
                sx={{ color: "#fff" }}
              />
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
}
export default FillInBlanksAnswer;