import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  IconButton,
  Container,
  Paper,
  MenuItem,
  Select,
  Modal,
  Button,
  LinearProgress,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import MicrophoneRecorder from "components/Recorder/MicrophoneRecording";

import AudionPlayer from "../../../../components/AudioPlayer";
import ReadAloudMicrophoneRecorder from "../../../../components/Recorder/ReadAloudMicrophone";
import AudioComponent from "components/Recorder/AudioComponent";
import { getApi, postApi } from "services/axiosInstance";
import { API_PATH } from "services/apipath";
import AudioPlayer from "layouts/listening/AudioPlayer";
import RepeatParagraphAloud from "components/AudioPlayer/AudioPlayer";

const searchItems = [
  { id: "SA #82", attempted: 0 },
  { id: "SA #81", attempted: 0 },
  { id: "SA #80", attempted: 0 },
  { id: "SA #78", attempted: 0 },
  { id: "SA #77", attempted: 0 },
];

const MissingWordAnswer = ({ questionData }) => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const Category = params.get("category");
  let id = location.pathname.split("/")[2];

  const navigate = useNavigate();

  const findIndex = () => {
    const questionIndex = questionData.questions?.findIndex(
      (question) => question.id === id
    );

    setCurrentIndex(questionIndex);
    setCurrentQuestion(questionIndex + 1);
    if (questionIndex === -1) {
    }
  };

  useEffect(() => {
    if (questionData) {
      findIndex();
    }
  }, [questionData]);

  const [currentQuestion, setCurrentQuestion] = useState(1);
  const [openSearchModal, setOpenSearchModal] = useState(false);
  const [recordedAudio, setRecordedAudio] = useState(null);
  const [choices, setChoices] = useState([]);
  const [openAnalysisModal, setOpenAnalysisModal] = useState(false);
  const [scores, setScores] = useState({
    content: 0,
    pronunciation: 0,
    fluency: 0,
    overall: 0,
  });

  const [questionid, setQuestionId] = useState();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [questionDataApi, setQuestionDataApi] = useState([]);

  const getQuestionBtID = async (id) => {
    try {
      const res = await getApi(`${API_PATH.STUDENTS.GET_QUESTION_BY_ID}/${id}`);
      if (res.status === 200) {
        setQuestionDataApi(res.data.data);
      }
    } catch (error) { }
  };

  useEffect(() => {
    if (id) {
      getQuestionBtID(id);
    }
  }, []);

  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [submitedAns, setSubmitedAns] = useState(null);

  const handleAnswerSelect = (index) => {
    setSelectedAnswer(index);
  };

  // const handleSubmit = async () => {
  //   const correctChoice = questionDataApi.choices.findIndex(
  //     (choice) => choice.is_correct
  //   );
  //   const choiceLabels = ["A", "B", "C", "D"];
  //   // Find the correct answer and its corresponding label
  //   const correctChoice1 = questionDataApi.choices.find(
  //     (choice) => choice.is_correct
  //   );
  //   const correctAnswerLabel =
  //     choiceLabels[questionDataApi.choices.indexOf(correctChoice1)];
  //   const selectedAnswerLabel = choiceLabels[selectedAnswer];

  //   if (selectedAnswer === correctChoice) {
  //     setSubmitedAns(true);
  //   } else {
  //     setSubmitedAns(false);
  //   }
  //   const payload = {
  //     questionId: id,
  //     answers: selectedAnswerLabel,
  //     correctAnswers: correctAnswerLabel,
  //   };
  //   // yeserror) {}
  // };

  useEffect(() => {
    return () => {
      if (recordedAudio?.url) {
        URL.revokeObjectURL(recordedAudio.url);
      }
    };
  }, []);

  const handleRecordingComplete = (recording) => {
    setRecordedAudio(recording);

    if (recording.scores) {
      setScores({
        content: recording.scores.content,
        pronunciation: recording.scores.pronunciation,
        fluency: recording.scores.fluency,
        overall: Math.round(
          (recording.scores.content +
            recording.scores.pronunciation +
            recording.scores.fluency) /
          3
        ),
      });
    }
  };

  const handleNext = async () => {
    if (currentQuestion === questionData.totalQuestions) {
      alert("Congratulations You have completed all questions!");
      return;
    } else {
      setCurrentQuestion(currentQuestion + 1);
      setCurrentIndex(currentIndex + 1);
      getQuestionBtID(questionData.questions[currentIndex + 1].id);
      setSubmitedAns(null);
      setSelectedAnswer(null);
    }
  };

  const handlePrev = async () => {
    if (currentQuestion > 1) {
      setCurrentQuestion(currentQuestion - 1);
      setCurrentIndex(currentIndex - 1);
      setQuestionId(questionData.questions[currentIndex - 1].id);
      getQuestionBtID(questionData.questions[currentIndex - 1].id);
      setSubmitedAns(null);
      setSelectedAnswer(null);
    }
  };

  const handleSubmit = async () => {
    const correctChoice = questionDataApi.choices.findIndex(
      (choice) => choice.is_correct
    );
    const choiceLabels = ["A", "B", "C", "D"];
    // Find the correct answer and its corresponding label
    const correctChoice1 = questionDataApi.choices.find(
      (choice) => choice.is_correct
    );
    const correctAnswerLabel =
      choiceLabels[questionDataApi.choices.indexOf(correctChoice1)];
    const selectedAnswerLabel = choiceLabels[selectedAnswer];

    if (selectedAnswer === correctChoice) {
      setSubmitedAns(true);
    } else {
      setSubmitedAns(false);
    }
    const payload = {
      questionId: id,
      answers: selectedAnswerLabel,
      correctAnswers: correctAnswerLabel,
    };
    try {
      const answer = await postApi(API_PATH.STUDENTS.SUBMIT_ANS, payload);
      if (answer.status === 200) {
      }
    } catch (error) { }
  };


  return (
    <Box sx={{ bgcolor: "#1a1a2e", minHeight: "100vh", p: 2 }}>
      {/* Fixed Header */}
      {/* <p>Current ID: {questionData.questions[currentIndex].id}</p> */}
      <Box
        sx={{
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bgcolor: "#1A1B2E",
          zIndex: 1000,
        }}
      >
        <Container maxWidth="lg">
          <Box
            sx={{
              p: 2,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              borderBottom: "1px solid rgba(255,255,255,0.1)",
            }}
          >
            <IconButton sx={{ color: "#fff" }} onClick={() => navigate(-1)}>
              <i className="fas fa-arrow-left" />
            </IconButton>

            <Typography variant="h6" sx={{ color: "#fff", ml: "20px" }}>
              Missing Words
            </Typography>

            <Box
              sx={{ display: "flex", alignItems: "center", gap: 1, ml: "auto" }}
            >
              <Typography sx={{ color: "#fff", fontSize: "14px" }}>
                {currentQuestion}/{questionData.totalQuestions}
              </Typography>
              <IconButton sx={{ color: "#fff" }} onClick={() => handlePrev()}>
                <i className="fas fa-chevron-left" />
              </IconButton>
              <IconButton
                sx={{ color: "#fff" }}
                onClick={() => setOpenSearchModal(true)}
              >
                <i className="fas fa-search" />
              </IconButton>
              <IconButton sx={{ color: "#fff" }}>
                <i className="fas fa-rotate" />
              </IconButton>
              <IconButton sx={{ color: "#fff" }} onClick={() => handleNext()}>
                <i className="fas fa-chevron-right" />
              </IconButton>
            </Box>
          </Box>
        </Container>
      </Box>

      <Container
        maxWidth="lg"
        sx={{
          pt: "80px",
          p: 2,
        }}
      >
        {/* Added Instructions Text */}
        <Typography
          sx={{
            color: "#fff",
            mt: 8,
            mb: 2,
            fontSize: "15px",
            fontStyle: "italic",
          }}
        >
          Listen to the recording and Choose the Missing Word.
        </Typography>


        {/* <Aution Text /> */}
        {/* <AudioPlayer
          audioText={JSON.stringify(questionDataApi.audio_text)}
          delay={3}
        /> */}

        <RepeatParagraphAloud
          // onAudioComplete={handleAudioComplete}
          audioText={JSON.stringify(questionDataApi.audio_text)}
          audioFile={questionDataApi.audio_file}
          delay={3}
        />

        {/* Answer Options */}
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          {questionDataApi.choices?.map((choice, index) => (
            <Box
              key={index}
              onClick={() => { submitedAns === null && handleAnswerSelect(index) }}
              sx={{
                bgcolor:
                  submitedAns === null // Before submission (no color change yet)
                    ? selectedAnswer === index // If this option is selected
                      ? "#00BFA6" // Keep selected option highlighted (light blue)
                      : "rgba(52, 58, 64, 0.8)" // Default neutral color for non-selected answers
                    : // After submission, update colors based on correctness
                    selectedAnswer === index // If this option is selected by the user
                      ? submitedAns && choice.is_correct
                        ? "green" // Correct answer: green
                        : "red" // Wrong answer: red
                      : choice.is_correct // Highlight correct answer
                        ? "green" // Correct answer highlighted in green
                        : "rgba(52, 58, 64, 0.8)", // Neutral color for other unselected answers
                p: 2.5,
                borderRadius: "8px",
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                transition: "all 0.2s ease",
                "&:hover": {
                  bgcolor:
                    submitedAns === null
                      ? selectedAnswer === index // If option is selected, it will remain highlighted on hover
                        ? "#00BFA6" // Keep selected option hover color same
                        : "rgba(52, 58, 64, 0.9)" // Darken other options on hover
                      : selectedAnswer === index // If this option is selected by the user
                        ? submitedAns && choice.is_correct
                          ? "green" // Correct answer: green
                          : "red" // Wrong answer: red
                        : choice.is_correct // Highlight correct answer
                          ? "green" // Correct answer highlighted in green
                          : "rgba(52, 58, 64, 0.8)", // Default hover color after submission (for non-selected)
                },
              }}
            >
              <Typography sx={{ fontSize: "14px", color: "#fff" }}>
                {choice.choice_text}
              </Typography>
            </Box>
          ))}
        </Box>
      </Container>

      {/* Fixed Footer with Background */}
      <Container>
        <Box
          sx={{
            bgcolor: "#1A1B2E",
            borderTop: "1px solid rgba(255,255,255,0.1)",
            py: 2,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            zIndex: 1000,
          }}
        >
          {/* Left side - Answer button (only shows after submission) */}
          <Box>
            {submitedAns !== null && (
              <Button
                sx={{
                  bgcolor: "#00BFA6",
                  color: "#fff",
                  borderRadius: "8px",
                  fontSize: "14px",
                  px: 4,
                  py: 2,
                  "&:hover": { bgcolor: "#00BFA6" },
                }}
              >
                {submitedAns ? "1" : "0"} / {1}
              </Button>
            )}
          </Box>

          <Box>
            <Button
              onClick={handleSubmit}
              sx={{
                bgcolor: "#00BFA6",
                color: "#fff",
                borderRadius: "8px",
                fontSize: "14px",
                p: 2,
                "&:hover": { bgcolor: "#009e8b" },
              }}
            >
              Submit Answer
            </Button>
          </Box>
        </Box>
      </Container>

      {/* Search Modal */}
      <Modal
        open={openSearchModal}
        onClose={() => setOpenSearchModal(false)}
        aria-labelledby="search-modal"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "#1A1B2E",
            borderRadius: "8px",
            p: 3,
            outline: "none",
            maxHeight: "80vh",
            overflow: "auto",
          }}
        >
          {/* Modal Header */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 3,
              borderBottom: "1px solid rgba(255, 255, 255, 0.1)",
              pb: 2,
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
              <Select
                value="All"
                size="small"
                sx={{
                  color: "#fff",
                  height: "32px",
                  minWidth: "100px",
                  ".MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(255,255,255,0.3)",
                    borderRadius: "4px",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(255,255,255,0.5)",
                  },
                }}
              >
                <MenuItem value="All">All</MenuItem>
              </Select>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                  gap: 1,
                }}
              >
                <Typography sx={{ color: "#fff", fontSize: "14px" }}>
                  {100} Qs
                </Typography>
              </Box>
            </Box>
            <IconButton
              onClick={() => setOpenSearchModal(false)}
              sx={{
                color: "#fff",
                p: 1,
                "&:hover": { bgcolor: "rgba(255,255,255,0.1)" },
              }}
            >
              <i className="fas fa-times" />
            </IconButton>
          </Box>

          {/* Search Items */}
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            {searchItems.map((item, index) => (
              <Box
                key={index}
                sx={{
                  bgcolor: "#262842",
                  borderRadius: "8px",
                  p: 2,
                  cursor: "pointer",
                  "&:hover": {
                    bgcolor: "#2F315A",
                  },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography sx={{ color: "#fff", fontSize: "14px" }}>
                    {index + 1}. {item.id}
                  </Typography>
                  <Box sx={{ display: "flex", gap: 1 }}>
                    <Box
                      sx={{
                        bgcolor: "#FFC107",
                        px: 1,
                        borderRadius: "4px",
                        fontSize: "12px",
                        fontWeight: 500,
                        color: "#000",
                      }}
                    >
                      New
                    </Box>
                    <Box
                      sx={{
                        bgcolor: "#FFC107",
                        px: 1,
                        borderRadius: "4px",
                        fontSize: "12px",
                        fontWeight: 500,
                        color: "#000",
                      }}
                    >
                      VIP
                    </Box>
                  </Box>
                </Box>
                <Box sx={{ mt: 1 }}>
                  <Typography
                    sx={{
                      color: "rgba(255,255,255,0.6)",
                      fontSize: "12px",
                      display: "flex",
                      alignItems: "center",
                      gap: 0.5,
                    }}
                  >
                    Attempted: {item.attempted}
                  </Typography>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default MissingWordAnswer;
