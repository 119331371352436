import { useEffect, useState } from "react";
import { Navigate, useLocation, Route, Routes } from "react-router-dom"; // Remove BrowserRouter

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle';

import ReadAloudComponent from "./layouts/dashboard/components/readaloud";
import Readaloudquestions from "./layouts/dashboard/questions/readaloudquestions";

import SignIn from "./layouts/authentication/sign-in"; // Import your SignIn component

import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";

import SoftBox from "components/SoftBox";
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";
import theme from "assets/theme";
import themeRTL from "assets/theme/theme-rtl";
import { CacheProvider } from "@emotion/react";
import routes from "routes";
import {
  useSoftUIController,
  setMiniSidenav,
  setOpenConfigurator,
} from "context";
import brand from "assets/images/global_wings.png";
import { ListeningMCQs } from "layouts/listening/MCQ";
import WritingFromDictationQs from "layouts/listening/WFD";
import { SingleMCQs } from "layouts/listening/SMCQ";
import MultipleAnswers from "layouts/listening/MCQ/answers";
import SingleAnswers from "layouts/listening/SMCQ/answers";
import HighlightAnswers from "layouts/listening/HSQ/answers"
import IncorrectWordsAnswers from "layouts/listening/IWQ/answers";
import FillInBlanksAnswers from "layouts/listening/FTBQ/answers";
import DictationAnswers from "layouts/listening/WFD/dictationanswers";
import MissingWordsAnswers from "layouts/listening/MWQ/answers";
import DescribeImageAnswers from "layouts/speaking/DIQ/answers";
import ShortAnswers from "layouts/speaking/SAQ/answers";
import FillInTheBlanksAnswers from "layouts/reading/FITB/answers";
import ReorderParagraphsAnswers from "layouts/reading/ROP/answers";
import RWfillUpsAnswers from "layouts/reading/RWF/answers";
import EssayAnswers from "layouts/writing/ESSAY/answers";
import ReadAloudAnswers from "layouts/speaking/RAQ/answers";
import { HighlightSQs } from "layouts/listening/HSQ";
import { IncorrectWQs } from "layouts/listening/IWQ";
import {FillInTheBlankQs} from "layouts/listening/FTBQ";
import { MissingWordsQs } from "layouts/listening/MWQ";
import DescribeImageQs from "layouts/speaking/DIQ";
import ShortAnswerQs from "layouts/speaking/SAQ";
import FillTheQuestions from "layouts/reading/FITB";
import ReorderParagraphs from "layouts/reading/ROP";
import RWfillUps from "layouts/reading/RWF";
import EssayQs from "layouts/writing/ESSAY";
import ReadAloudQs from "layouts/speaking/RAQ";
import RepeatSentence from "./layouts/speaking/RS";
import RepeatSentenceAnswers from "layouts/speaking/RS/answers";
import QuestionRender from "layouts/questionRender/QuestionRender";
import PTEWritingModule from "Writing";
import PteSpeakingPractice from "PteSpeakingPractice";
import PTEEmailWritingModule from "Email";

import TestingNew from "./PteDescribeImage";
import MockTestQuestionRender from "layouts/mocktest/MockTestQuestionRender";
import FullMockTest from "layouts/mocktest/fulltest/answers";
import Analysis from "layouts/mock-history/analysis";

// Helper function to check authentication
const isAuthenticated = () =>
  localStorage.getItem("isAuthenticated") === "true";

// Protected route component
function ProtectedRoute({ children }) {
  return isAuthenticated() ? children : <Navigate to="/signin" />;
}

export default function App() {
  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav, direction, layout, openConfigurator, sidenavColor } =
    controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();

  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  const handleConfiguratorOpen = () =>
    setOpenConfigurator(dispatch, !openConfigurator);

  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return (
          <Route
            exact
            path={route.route}
            element={<ProtectedRoute>{route.component}</ProtectedRoute>}
            key={route.key}
          />
        );
      }

      return null;
    });

    const isFullScreenRoute =
    pathname.startsWith("/readaloud") ||
    pathname.startsWith("/repeatsentence") ||
    pathname.startsWith("/questions") ||
    pathname.startsWith("/Listening") || pathname.startsWith("/Speaking") || pathname.startsWith("/Reading") || pathname.startsWith("/Writing") || pathname.startsWith("/mock-test") || pathname.startsWith("/analysis");

  const configsButton = (
    <SoftBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.5rem"
      height="3.5rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: "pointer" }}
      onClick={handleConfiguratorOpen}
    >
      <Icon fontSize="default" color="inherit">
        settings
      </Icon>
    </SoftBox>
  );

  return (
    <ThemeProvider theme={direction === "rtl" ? themeRTL : theme}>
      <CssBaseline />
      {direction === "rtl" ? (
        <CacheProvider value={rtlCache}>
          {!isFullScreenRoute && layout === "dashboard" && (
            <>
              <Sidenav
                color={sidenavColor}
                brand={brand}
                brandName="Institute Name"
                routes={routes}
                onMouseEnter={handleOnMouseEnter}
                onMouseLeave={handleOnMouseLeave}
              />

              <Configurator />
              {configsButton}
            </>
          )}
          <Routes>
            <Route path="/signin" element={<SignIn />} />
            {getRoutes(routes)}

            {/* <Route path="/testingnew" element={<TestingNew />} /> */}
            <Route path="/listening/MCQ" element={<ListeningMCQs />} />
            <Route path="/listening/SMCQ" element={<SingleMCQs />} />
            <Route path="/listening/HSQ" element={<HighlightSQs />} />
            <Route path="/listening/IWQ" element={<IncorrectWQs />} />
            <Route path="/listening/WFD" element={<WritingFromDictationQs />} />
            <Route path="/listening/MWQ" element={<MissingWordsQs />} />
            <Route path="/listening/FTBQ" element={<FillInTheBlankQs />} />
            <Route path="/speaking/DIQ" element={<DescribeImageQs />} />
            <Route path="/speaking/SAQ" element={<ShortAnswerQs />} />

            <Route path="/speaking/RS" element={<RepeatSentence />} />

            <Route path="/reading/FITB" element={<FillTheQuestions />} />
            <Route path="/reading/ROP" element={<ReorderParagraphs />} />
            <Route path="/reading/RWF" element={<RWfillUps />} />
            <Route path="/writing/ESSAY" element={<EssayQs />} />
            <Route path="/speaking/RAQ" element={<ReadAloudQs />} />
            <Route path="/question/:id" element={<MultipleAnswers />} />
            <Route path="/question/:id" element={<SingleAnswers />} />
            <Route path="/question/:id" element={<HighlightAnswers />} />
            <Route path="/question/:id" element={<DictationAnswers />} />
            <Route path="/question/:id" element={<FillInBlanksAnswers />} />
            <Route path="/question/:id" element={<IncorrectWordsAnswers />} />
            <Route path="/question/:id" element={<MissingWordsAnswers />} />
            <Route path="/question/:id" element={<DescribeImageAnswers />} />

            <Route path="/question/:id" element={<RepeatSentence />} />

            <Route path="/question/:id" element={<ShortAnswers />} />
            <Route path="/question/:id" element={<FillInTheBlanksAnswers />} />
            <Route path="/question/:id" element={<ReorderParagraphsAnswers />} />
            <Route path="/question/:id" element={<RWfillUpsAnswers />} />
            <Route path="/question/:id" element={<EssayAnswers />} />

            <Route path="/repeatsentence" element={<RepeatSentence />} />

            <Route path="*" element={<Navigate to="/signin" />} />
            {/* New Route */}
          </Routes>
        </CacheProvider>
      ) : (
        <>
          {!isFullScreenRoute && layout === "dashboard" && (
            <>
              <Sidenav
                color={sidenavColor}
                brand={brand}
                brandName="Institute Name"
                routes={routes}
                onMouseEnter={handleOnMouseEnter}
                onMouseLeave={handleOnMouseLeave}
              />
              <Configurator />
              {configsButton}
            </>
          )}
          <Routes>
            <Route path="/signin" element={<SignIn />} />
            {getRoutes(routes)}

            {/* <Route path="/ptespeakingmod" element={<PteSpeakingPractice />} /> */}
            
            {/* <Route path="/ptewritingmodule" element={<PTEWritingModule />} /> */}
            
            <Route path="/ptewritingmodule" element={<PTEEmailWritingModule />} />

            {/* listening MCQ Routes */}
            <Route path="/listening/MCQ" element={<ListeningMCQs />} />
            <Route path="/listening/SMCQ" element={<SingleMCQs />} />
            <Route path="/listening/HSQ" element={<HighlightSQs />} />
            <Route path="/listening/IWQ" element={<IncorrectWQs />} />
            <Route path="/listening/WFD" element={<WritingFromDictationQs />} />
            <Route path="/listening/MWQ" element={<MissingWordsQs />} />
            <Route path="/listening/FTBQ" element={<FillInTheBlankQs />} />
            <Route path="/speaking/DIQ" element={<DescribeImageQs />} />
            <Route path="/speaking/SAQ" element={<ShortAnswerQs />} />


            <Route path="/speaking/RS" element={<RepeatSentence />} />


            <Route path="/reading/FITB" element={<FillTheQuestions />} />
            <Route path="/reading/ROP" element={<ReorderParagraphs />} />
            <Route path="/reading/RWF" element={<RWfillUps />} />
            <Route path="/writing/ESSAY" element={<EssayQs />} />

            <Route path="/questions" element={<ReadAloudQs />} />

            <Route path="/listening/MCQ/:id" element={<MultipleAnswers />} />
            <Route path="/listening/SMCQ/:id" element={<SingleAnswers />} />
            <Route path="/listening/HSQ/:id" element={<HighlightAnswers />} />
            <Route path="/listening/WFD/:id" element={<DictationAnswers />} />
            <Route path="/listening/IWQ/:id" element={<IncorrectWordsAnswers />} />
            <Route path="/listening/FTBQ/:id" element={<FillInBlanksAnswers />} />
            <Route path="/listening/MWQ/:id" element={<MissingWordsAnswers />} />

           
            <Route path="/speaking/SAQ/:id" element={<ShortAnswers />} />
            <Route path="/reading/FITB/:id" element={<FillInTheBlanksAnswers />} />
            <Route path="/reading/ROP/:id" element={<ReorderParagraphsAnswers />} />
            <Route path="/reading/RWF/:id" element={<RWfillUpsAnswers />} />
            <Route path="/writing/ESSAY/:id" element={<EssayAnswers />} />

            <Route path='/questions/:id' element={<QuestionRender />} />

            <Route path="/speaking/RAQ/:id" element={<ReadAloudAnswers />} />

            <Route path="/speaking/RS/:id" element={<RepeatSentenceAnswers />} />

            <Route path="/speaking/DIQ/:id" element={<DescribeImageAnswers />} />

            <Route path="/mock-test/:id" element={<FullMockTest />} />
            
            <Route path="/mock-test/:category/:subcategory/:base" element={<MockTestQuestionRender />} />
            <Route path="/analysis/:id/:studentId" element={<Analysis />} />
            <Route path="*" element={<Navigate to="/signin" />} />
            
          </Routes>
        </>
      )}
    </ThemeProvider>
  );
}
