import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import DoneIcon from "@mui/icons-material/Done";
import colors from "assets/theme/base/colors";

const { error, success } = colors;

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 420,
    bgcolor: "background.paper",
    borderRadius: 4,
};

export default function FirstStepModal({ open, setOpen, setCanContinue }) {

    const handleClick = () => {
        setOpen(false);
        setCanContinue(true);
    }
    return (
        <Modal
            open={open}
            aria-labelledby="first-step-modal-title"
            aria-describedby="first-step-modal-description"
        >
            <Box sx={style}>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        position: "relative",
                    }}
                >
                    <Box
                        sx={{
                            flex: 1,
                            p: 2.5,
                            pt: 5,
                            position: "relative",
                            textAlign: "center",
                            display: "flex",
                            flexDirection: "column",
                            gap: 2,
                            backgroundColor: "transparent",
                        }}
                    >
                        {/* Icon Container */}
                        <Box
                            sx={{
                                position: "absolute",
                                top: "-10%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                                p: 3,
                                bgcolor: "#4daa54",
                                borderRadius: "50%",
                                borderWidth: 8,
                                borderColor: "#fff",
                                borderStyle: "solid",
                                // backgroundColor: "4daa54",
                            }}
                        >
                            <DoneIcon fontSize="large" sx={{ color: "#fff" }} />
                        </Box>

                        {/* Main Text */}
                        <Typography
                            id="first-step-modal-title"
                            sx={{ fontSize: "1.5rem", fontWeight: "bold" }}
                        >
                            Part 1: Speaking and Writing
                        </Typography>
                        <Typography id="first-step-modal-description">
                            Timing: 54 - 67 minutes
                        </Typography>
                        <Typography>Remember: Wear Headphones</Typography>

                        <Box sx={{ textAlign: "center", display: "flex", gap: 2, justifyContent: "center" }}>
                            <Button
                                onClick={handleClick}
                                variant="contained"
                                size="small"
                                sx={{
                                    bgcolor: "#4daa54",
                                    fontSize: "0.9rem",
                                    minWidth: "120px",
                                    fontWeight: "500",
                                    color: "#fff",
                                    textTransform: "capitalize",
                                    borderRadius: 5,
                                }}
                            >
                                Start
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Modal>
    );
}
