import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  IconButton,
  Button,
  TextField,
  Switch,
  FormControlLabel,
  Container,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { getApi } from "services/axiosInstance";
import { postApi } from "services/axiosInstance";
import { API_PATH } from "services/apipath";
import { processQuestionText } from "utils/ProcessQuestionText";
import AudioPlayer from "layouts/listening/AudioPlayer";
import RepeatParagraphAloud from "components/AudioPlayer/AudioPlayer";
import { Send } from "@mui/icons-material";

function FillInBlanksAnswerMock({ questionData, onNext }) {
  const lastId = localStorage.getItem("lastId");
  const navigate = useNavigate();
  const location = useLocation();
  let fullTestId = location.pathname.split("/")[2];
  const [questions, setQuestions] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [showAnswer, setShowAnswer] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isCorrect, setIsCorrect] = useState(false);
  const [blanks, setBlanks] = useState({});
  const [correctAnswers, setCorrectAnswers] = useState([]);

  const getQuestionIdFromUrl = () => {
    const pathSegments = location.pathname.split("/");
    return pathSegments[pathSegments.length - 1];
  };

  const questionId = getQuestionIdFromUrl();
  const [questionDataApi, setQuestionDataApi] = useState([]);
  const [questionid, setQuestionId] = useState();
  let id = location.pathname.split("/")[2];
  const getQuestionBtID = async (id) => {
    try {
      const res = await getApi(`${API_PATH.STUDENTS.GET_QUESTION_BY_ID}/${id}`);
      if (res.status === 200) {
        setQuestionDataApi(res.data.data);
      }
    } catch (error) { }
  };

  useEffect(() => {
    if (id && questionid === undefined) {
      // getQuestionBtID(id);
    }
  }, []);

  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        setLoading(true);
        const response = await getApi(
          `v2/student/get-questions?ids=${questionId}`
        );

        if (response.data && response.data.data) {
          const filteredQuestion = response.data.data.filter(
            (question) => question._id === questionId
          );

          setQuestions(filteredQuestion);

          if (filteredQuestion[0]) {
            const { totalBlanks } = processQuestionText(
              filteredQuestion[0].question_text
            );

            // Initialize blanks
            const initialBlanks = {};
            for (let i = 1; i <= totalBlanks; i++) {
              initialBlanks[`blank${i}`] = "";
            }
            setBlanks(initialBlanks);

            // Store correct answers for comparison and "Show Answer"
            setCorrectAnswers(filteredQuestion[0].FillInTheBlanksAnswers);
          }
        }
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };

    if (questionId) {
      fetchQuestions();
    }
  }, [questionId]);

  const currentQuestion = questionData;

  useEffect(() => {
    if (currentQuestion) {
      const { totalBlanks } = processQuestionText(
        currentQuestion.question_text
      );
      const initialBlanks = {};
      for (let i = 1; i <= totalBlanks; i++) {
        initialBlanks[`blank${i}`] = null;
      }
      setBlanks(initialBlanks);
      setCorrectAnswers(currentQuestion.FillInTheBlanksAnswers);
    }
  }, [currentQuestion]);

  const handleInputChange = (event, blankId) => {
    setBlanks({
      ...blanks,
      [blankId]: event.target.value,
    });
    setIsSubmitted(false);
  };

  const handleSubmit = async () => {
    // if (!currentQuestion || !correctAnswers.length) return;

    let score = 0;
    const newBlankStatus = {};

    const correctAnswers = currentQuestion.FillInTheBlanksAnswers;

    console.log({ blanks: blanks });

    // Validate each blank and calculate score
    Object.entries(blanks).forEach(([blankId, value], index) => {
      const userAnswer = value ? value.trim().toLowerCase() : ""; 
      const correctAnswer = correctAnswers[index]
        ? correctAnswers[index].trim().toLowerCase()
        : "";
  
      const isCorrect = userAnswer === correctAnswer;
      newBlankStatus[blankId] = isCorrect;
      if (isCorrect) {
        score += 1;
      }
    });
  
    setBlankStatus(newBlankStatus);
    // setIsSubmitted(true);

    // Check if all answers are correct
    const isAllCorrect = Object.values(newBlankStatus).every(
      (status) => status
    );
    setIsCorrect(isAllCorrect);


    const newScores = {
      content: 0,
      pronunciation: 0,
      fluency: 0,
      overall: score,
      total: correctAnswers.length
    }

    const payload = {
      fullTestId: fullTestId,
      currentQuestionId: currentQuestion._id,
      student_ans: JSON.stringify(blanks),
      module: currentQuestion.module_type[0],
      sub_module: currentQuestion.module_subcategory[0],
      score: JSON.stringify(newScores)
    }

    try {
      const answer = await postApi(
        API_PATH.MOCK_TEST.SUBMIT_MOCK_TEST_ANS,
        payload
      );
      if (answer.status === 200) {
        onNext();
      }
    } catch (error) { }
  };

  const handleNextQuestion = () => {
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex((prev) => prev + 1);
      const nextQuestion = questions[currentQuestionIndex + 1];
      if (nextQuestion) {
        const { totalBlanks } = processQuestionText(nextQuestion.question_text);
        const newBlanks = {};
        for (let i = 1; i <= totalBlanks; i++) {
          newBlanks[`blank${i}`] = "";
        }
        setBlanks(newBlanks);
      }
      setIsSubmitted(false);
      setIsCorrect(false);
      setShowAnswer(false);
    }
  };

  const handleAudioComplete = () => {
    // setStartRecording(true);
    // setTimer(15);
    // setIsListening(true);
    // setPreparationTimer(15);
  };

  const [blankStatus, setBlankStatus] = useState({});

  // const handleSubmit = () => {
  //   if (!currentQuestion || !currentQuestion.FillInTheBlanksAnswers) return;

  //   const correctAnswers = currentQuestion.FillInTheBlanksAnswers;
  //   const newBlankStatus = {};

  //   // Check each blank individually
  //   Object.entries(blanks).forEach(([blankId, value], index) => {
  //     if (value) {
  //       newBlankStatus[blankId] = value.content === correctAnswers[index];
  //     }
  //   });

  //   setBlankStatus(newBlankStatus);
  //   setIsSubmitted(true);

  //   // Check if all answers are correct
  //   const isAllCorrect = Object.values(newBlankStatus).every(
  //     (status) => status
  //   );
  //   setIsCorrect(isAllCorrect);
  // };


  // const handleSubmit = async () => {
  //   if (!currentQuestion || !currentQuestion.FillInTheBlanksAnswers) return;

  //   const choices = Object.entries(blanks).map(([key, choices]) => choices);

  //   const payload = {
  //     questionId: currentQuestion._id,
  //     choices: choices,
  //   };

  //   try {
  //     const response = await postApi(API_PATH.MOCK_TEST.SUBMIT_MOCK_TEST_ANS, payload);

  //     if (response.status === 200) {
  //       setIsSubmitted(true);
  //       setIsCorrect(response.data.isCorrect);

  //       if (onNext) {
  //         onNext();
  //       }
  //     } else {
  //       console.error("Error submitting answers:", response.data);
  //     }
  //   } catch (error) {
  //     console.error("Submission failed:", error);
  //   }
  // };

  return (
    <Container sx={{ p: "0 !important" }}>
      <Box sx={{ color: "#000", paddingX: "20px" }}>
        <Box sx={{ px: 2, pt: 2 }}>
          {currentQuestion && (
            <>
              <Typography
                sx={{
                  color: "black",
                  mb: 2,
                  fontStyle: "italic",
                }}
              >
                In the text below some words are missing. Type the correct words
                in the input fields below.
              </Typography>

              <RepeatParagraphAloud
                // onAudioComplete={handleAudioComplete}
                audioText={JSON.stringify(currentQuestion.audio_text)}
                audioFile={currentQuestion.audio_file}
                delay={3}
              />

              <Box sx={{ mt: 2, lineHeight: 1.6, fontSize: "19px" }}>
                {(() => {
                  const { parts } = processQuestionText(
                    currentQuestion.question_text
                  );
                  return parts.map((part, index, array) => (
                    <React.Fragment key={index}>
                      {part}
                      {index < array.length - 1 && (
                        <TextField
                          variant="outlined"
                          size="small"
                          value={
                            showAnswer
                              ? correctAnswers[index]
                              : blanks[`blank${index + 1}`] || ""
                          }
                          onChange={(e) =>
                            handleInputChange(e, `blank${index + 1}`)
                          }
                          sx={{
                            mx: 1,
                            width: "120px",
                            backgroundColor: "white",
                          }}
                          disabled={showAnswer} // Disable input when showing answers
                        />
                      )}
                    </React.Fragment>
                  ));
                })()}
              </Box>

              {/* {isSubmitted && (
                <Box
                  sx={{
                    mt: 3,
                    p: 2,
                    bgcolor: isCorrect
                      ? "rgba(76, 175, 80, 0.1)"
                      : "rgba(244, 67, 54, 0.1)",
                    borderRadius: 1,
                    border: `1px solid ${isCorrect ? "#4CAF50" : "#f44336"}`,
                  }}
                >
                  <Typography
                    sx={{
                      color: isCorrect ? "#4CAF50" : "#f44336",
                      fontWeight: "bold",
                    }}
                  >
                    {isCorrect ? "Correct!" : "Incorrect. Try again!"}
                  </Typography>
                </Box>
              )} */}
            </>
          )}
        </Box>
        <Container>
          <Box
            sx={{
              // p: 2,
              mt: 3,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              verticalAlign: "middle",
            }}
          >
            <Button
              variant="outlined"
              sx={{ color: "black !important" }}
              onClick={() => navigate("/mocktest")}
            >
              Save & Exit
            </Button>

            <Box
              sx={{ display: "flex", alignItems: "center", gap: 1, ml: "auto" }}
            >
              <Button
                variant="contained"
                sx={{ color: "white !important" }}
                endIcon={<Send />}
                onClick={handleSubmit}
              >
                {lastId === "true" ? "Submit" : "Next"}
              </Button>
            </Box>
          </Box>
        </Container>
      </Box>
    </Container>
  );
}

export default FillInBlanksAnswerMock;
