import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { Box, Chip, IconButton, Typography } from "@mui/material";
import { API_PATH } from "services/apipath";
import moment from "moment";
import { postApi } from "services/axiosInstance";
import Pagination from "components/Pagination/Pagination";
import Modal from "react-modal";
import { toast } from "react-toastify";
import { CloseOutlined } from "@mui/icons-material";
import TextToSpeechPlayer from "layouts/mock-history/QuestionPlayer";
import AudioPlayer from "components/AudioPlayer";

function BillingInformation() {
  const [currentPage, setCurrentPage] = useState(1);
  const ITEMS_PER_PAGE = 10;

  const [history, setHistory] = useState([]);
  const [totalHistory, setTotalHistory] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalData, setModalData] = useState({});
  const [showAnswer, setShowAnswer] = useState(false);

  const [feedbackModal, setFeedbackModal] = useState(false);
  const [feedbutton, setFeedbackButton] = useState(true);
  const [feedback, setfeedback] = useState("");

  const getQuestionsList = async () => {
    setIsLoading(true);
    try {
      const res = await postApi(`${API_PATH.STUDENTS.GET_ATTEMPT_PRACTICE}`, {
        page: currentPage,
        limit: ITEMS_PER_PAGE,
      });

      if (res.status === 200 && res.data && res.data.data) {
        setHistory(res.data.data.attempts || []);
        setTotalHistory(res.data.data.totalData || 0);
      } else {
        console.error("Failed to fetch attempt history:", res);
        setHistory([]);
        setTotalHistory(0);
      }
    } catch (error) {
      console.error("Error fetching attempt history:", error);
      setHistory([]);
      setTotalHistory(0);
    } finally {
      setIsLoading(false);
    }
  };

  // Fetch questions whenever the page changes or the component mounts
  useEffect(() => {
    getQuestionsList();
  }, [currentPage]);

  // Calculate total pages based on total questions and ITEMS_PER_PAGE
  const totalPages = Math.ceil(totalHistory / ITEMS_PER_PAGE);

  const handlePageChange = (newPage) => {
    // Ensure the new page is within valid range
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const processScore = (attempt) => {
    try {
      if (Array.isArray(attempt) && attempt.length === 1 && typeof attempt[0] === "string") {
        try {
          const parsedValue = JSON.parse(attempt[0]);

          if (Array.isArray(parsedValue)) {
            return parsedValue.length;
          } else if (typeof parsedValue === "object" && parsedValue !== null) {
            return Object.keys(parsedValue).length;
          } else {
            console.warn("Parsed value is neither an array nor an object:", parsedValue);
            return 1;
          }
        } catch (parseError) {
          return attempt.length;
        }
      } else if (Array.isArray(attempt)) {
        return attempt.length;
      } else if (typeof attempt === "object" && attempt !== null) {
        return Object.keys(attempt).length;
      } else {
        console.warn("Unexpected format:", attempt);
        return 0;
      }
    } catch (error) {
      console.error("Failed to process attempt:", error);
      return 0;
    }
  };

  const openModal = (data) => {
    console.log(JSON.stringify(data));
    console.log(data)

    setModalIsOpen(true);
    setModalData(data);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setModalData([]);
    setfeedback("");
    setFeedbackButton(true);
  };

  const submitFeedback = async () => {
    // setLoading(true);
    // try {
    //   const res = await postApi(`${API_PATH.ADMIN.FEEDBACK}`, {
    //     studentId: attempts.studentId._id,
    //     fullTestId: attempts.fullTestId._id,
    //     questionId: modalData.questionId._id,
    //     feedback: feedback,
    //   });
    //   if (res.status === 200) {
    //     toast.success("Feedback added successfully");
    //     setFeedbackModal(false);
    //     setFeedbackButton(false);
    //   } else {
    //     throw new Error("Failed to fetch data");
    //   }
    // } catch (err) {
    //   console.log(err, "Error in data ");
    // } finally {
    //   setLoading(false);
    // }
  };

  return (
    <Card id="attempt-history">
      <SoftBox pt={1} px={2}>
        <SoftTypography variant="h5" fontWeight="medium">
          Attempt History
        </SoftTypography>
      </SoftBox>

      <SoftBox pt={1} pb={2} px={2}>
        <SoftBox
          component="ul"
          display="flex"
          flexDirection="column"
          p={0}
          m={0}
          sx={{
            height: '70vh',
            overflowY: 'scroll'
          }}
        >
          {isLoading ? (
            <SoftTypography variant="body2" textAlign="center">
              Loading attempts...
            </SoftTypography>
          ) : history.length === 0 ? (
            <SoftTypography variant="body2" textAlign="center">
              No attempt history found.
            </SoftTypography>
          ) : (
            history.map((attempt, index) => (
              <SoftBox
                key={index}
                component="li"
                mb={3}
                mx={1}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                p={2}
                borderRadius="lg"
                sx={{
                  backgroundColor: "#F8F9FA",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                }}
              >
                <SoftBox display="flex" flexDirection="column">
                  <SoftTypography
                    fontSize="15px"
                    fontWeight="regular"
                    color="black"
                  >
                    Attempted:{" "}
                    {moment(attempt.attemptedAt).format("DD-MM-YYYY hh:mm a")}
                    <br />
                  </SoftTypography>
                  <SoftTypography
                    mt={1}
                    fontSize="15px"
                    fontWeight="regular"
                    color="black"
                  >
                    Attempted Count: {attempt.attemptCount}
                  </SoftTypography>

                  {/* Module Type and Subcategory */}
                  <SoftBox display="flex" gap={1} mt={1} flexWrap="wrap" alignItems="center">
                    {attempt.module_type && attempt.module_type.length > 0 && (
                      <Chip
                        label={attempt.module_type[0]}
                        size="small"
                        color="primary"
                        variant="outlined"
                        sx={{ fontSize: '15px', padding: "15px" }}
                      />
                    )}
                    {attempt.module_subcategory && attempt.module_subcategory.length > 0 && (
                      <Chip
                        label={attempt.module_subcategory[0]}
                        size="small"
                        color="secondary"
                        variant="outlined"
                        sx={{ fontSize: '15px', padding: "15px" }}
                      />
                    )}
                    {attempt.questionId.question_title && (
                      <Chip
                        label={attempt.questionId.question_title}
                        size="small"
                        color="info"
                        variant="outlined"
                        sx={{
                          fontSize: '15px',
                          padding: "15px",
                          maxWidth: '200px',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis'
                        }}
                      />
                    )}
                  </SoftBox>

                  {/* <SoftTypography
                    variant="button"
                    fontWeight="medium"
                    color="error"
                    mt={1}
                  >
                    {attempt.questionId.question_title
                      ? attempt.questionId.question_title
                      : `#${attempt.questionId._id}`}
                  </SoftTypography> */}
                </SoftBox>

                <SoftBox display="flex" alignItems="center">
                  <Chip
                    label={`${processScore(attempt.correctAnswers)}/${processScore(attempt.answers)}`}
                    color={
                      processScore(attempt.correctAnswers) === processScore(attempt.answers)
                        ? "success"
                        : "error"
                    }
                    variant="outlined"
                    sx={{
                      color: "white",
                      borderColor:
                        processScore(attempt.correctAnswers) === processScore(attempt.answers)
                          ? "#4CAF50"
                          : "#F44336",
                      mr: 2,
                    }}
                    onClick={() => openModal(attempt)}
                  />
                </SoftBox>
              </SoftBox>
            ))
          )}
        </SoftBox>
      </SoftBox>
      {totalPages > 1 && (
        <Box
          sx={{
            p: 2,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderTop: "1px solid rgba(255, 255, 255, 0.1)",
            bgcolor: "#1a1a2e",
          }}
        >
          <Pagination
            totalPages={totalPages}
            currentPage={currentPage}
            onPageChange={handlePageChange}
          />
        </Box>
      )}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Read Aloud Modal"
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "#2d2f3a",
            color: "#d1d5db",
            borderRadius: "8px",
            padding: "20px",
            width: "70vw",
          },
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.75)",
            zIndex:"10000"
          },
        }}
      >
        <div style={{ textAlign: "center" }}>
          <div className="d-flex justify-content-between">
            <Typography
              variant="h5"
              sx={{ color: "#d1d5db", marginBottom: "20px" }}
            >
              {modalData.module_subcategory}
            </Typography>

            <IconButton>
              <CloseOutlined
                onClick={closeModal}
                sx={{
                  color: "red",
                }}
              />
            </IconButton>
          </div>

          <div className="d-flex align-items-center justify-content-evenly mt-3">
            <div className=" ">
              {modalData.module_subcategory === "Describe Image" && (
                <img
                  src={`${process.env.REACT_APP_BASE_URL}/${modalData.questionId.image}`}
                  width={300}
                  alt=""
                />
              )}
              {/* Scoreing section  */}
              <div
                style={{
                  // display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "15px",
                }}
              >          
              </div>
              
            </div>
          </div>

          {/* New Listening Single/Multiple Answer UI */}
          {(
            modalData?.module_subcategory?.includes('Single Answer') || 
            modalData?.module_subcategory?.includes('Multiple Answers') || 
            modalData?.module_subcategory?.includes('Highlight Summary') || 
            modalData?.module_subcategory?.includes('Missing Word')
          ) && (
            <div className="listening-answer" style={{ 
              marginTop: "30px",
              padding: "20px",
              backgroundColor: "rgba(255, 255, 255, 0.05)",
              borderRadius: "8px",
              maxWidth: "70vw",
              width: "100%",
              margin: "0 auto"
            }}>
              {/* Add Reading Text Display */}
              {modalData?.module_type?.includes('Reading') && modalData?.questionId?.long_text_paragraph && (
                <div style={{ 
                  marginBottom: "20px",
                  padding: "15px",
                  backgroundColor: "rgba(255, 255, 255, 0.03)",
                  borderRadius: "8px",
                  border: "1px solid rgba(209, 213, 219, 0.3)"
                }}>
                  <Typography sx={{ 
                    color: "#d1d5db", 
                    textAlign: "left",
                    whiteSpace: "pre-wrap",
                    lineHeight: "1.6"
                  }}>
                    {modalData.questionId.long_text_paragraph}
                  </Typography>
                </div>
              )}

              <div style={{ marginBottom: "20px" }}>
                <Typography variant="h6" sx={{ color: "#d1d5db", marginBottom: "15px", textAlign: "left" }}>
                  {modalData?.questionId?.question_text}
                </Typography>
              </div>

              <div style={{ display: "flex", flexDirection: "column", gap: "12px" }}>
                {modalData?.questionId?.choices?.map((choice, index) => {
                  const choiceLabel = String.fromCharCode(65 + index);
                  const isSelected = modalData.answers?.includes(choiceLabel);
                  const isCorrect = modalData.correctAnswers?.includes(choiceLabel);
                  
                  // Show styling based on different conditions
                  const shouldHighlight = isSelected || (showAnswer && isCorrect);
                  const isIncorrectSelection = isSelected && !isCorrect;
                  
                  return (
                    <div 
                      key={choice._id}
                      style={{
                        padding: "12px 16px",
                        borderRadius: "8px",
                        backgroundColor: shouldHighlight
                          ? (isSelected 
                            ? (isCorrect ? "rgba(76, 175, 80, 0.1)" : (showAnswer ? "transparent" : "rgba(244, 67, 54, 0.1)"))
                            : "rgba(76, 175, 80, 0.1)") // for correct answers when showAnswer is true
                          : "transparent",
                        border: `1px solid ${shouldHighlight
                          ? (isSelected 
                            ? (isCorrect ? "#4CAF50" : (showAnswer ? "rgba(209, 213, 219, 0.3)" : "#F44336"))
                            : "#4CAF50") // for correct answers when showAnswer is true
                          : "rgba(209, 213, 219, 0.3)"}`,
                        display: "flex",
                        alignItems: "flex-start",
                        cursor: "default",
                        transition: "all 0.2s ease",
                        opacity: shouldHighlight ? 1 : 1,
                        width: "100%",
                        wordBreak: "break-word"
                      }}
                    >
                      <Typography 
                        sx={{ 
                          color: shouldHighlight
                            ? (isSelected 
                              ? (isCorrect ? "#4CAF50" : (showAnswer ? "#d1d5db" : "#F44336"))
                              : "#4CAF50") // for correct answers when showAnswer is true
                            : "#d1d5db",
                          marginRight: "12px",
                          fontWeight: "500",
                          flexShrink: 0
                        }}
                      >
                        {choiceLabel}.
                      </Typography>
                      <Typography 
                        sx={{ 
                          color: shouldHighlight
                            ? (isSelected 
                              ? (isCorrect ? "#4CAF50" : (showAnswer ? "#d1d5db" : "#F44336"))
                              : "#4CAF50") // for correct answers when showAnswer is true
                            : "#d1d5db",
                          textAlign: "left",
                          flex: 1,
                          wordBreak: "break-word",
                          whiteSpace: "pre-wrap"
                        }}
                      >
                        {choice.choice_text}
                      </Typography>
                    </div>
                  );
                })}
              </div>

              <div style={{ 
                marginTop: "20px", 
                display: "flex", 
                justifyContent: "space-between",
                alignItems: "center"
              }}>
                <div style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px"
                }}>
                  <Typography sx={{ color: "#d1d5db", fontSize: "0.9rem" }}>
                    Score: 
                  </Typography>
                  <Chip
                    label={`${modalData.answers?.filter(ans => 
                      modalData.correctAnswers.includes(ans)).length === modalData.correctAnswers?.length ? 
                      modalData.correctAnswers?.length : 0}/${modalData.correctAnswers?.length}`}
                    color={modalData.answers?.filter(ans => 
                      modalData.correctAnswers.includes(ans)).length === modalData.correctAnswers?.length ? "success" : "error"}
                    variant="outlined"
                    sx={{
                      fontSize: "16px",
                      padding: "15px",
                      borderWidth: "2px"
                    }}
                  />
                </div>

                <div style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px"
                }}>
                  <Typography sx={{ color: "#d1d5db", fontSize: "0.9rem" }}>
                    See Answer
                  </Typography>
                  <div className="form-check form-switch">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      role="switch"
                      id="seeAnswerSwitch"
                      checked={showAnswer}
                      onChange={(e) => setShowAnswer(e.target.checked)}
                      style={{
                        width: "40px",
                        height: "20px",
                        cursor: "pointer",
                        backgroundColor: showAnswer ? "#4CAF50" : "#d1d5db"
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
            
          {/* New Write From Dictation UI */}
          {modalData?.module_subcategory?.includes('Write From Dictation') && (
            <div className="write-from-dictation" style={{ 
              marginTop: "30px",
              padding: "20px",
              backgroundColor: "rgba(255, 255, 255, 0.05)",
              borderRadius: "8px",
              maxWidth: "70vw",
              width: "100%",
              margin: "0 auto"
            }}>
              <div style={{ marginBottom: "20px" }}>
                <Typography variant="h6" sx={{ color: "#d1d5db", marginBottom: "10px", textAlign: "left" }}>
                  Your Response:
                </Typography>
                <div style={{
                  padding: "15px",
                  borderRadius: "8px",
                  backgroundColor: "rgba(255, 255, 255, 0.03)",
                  border: "1px solid rgba(209, 213, 219, 0.3)",
                }}>
                  <Typography sx={{ color: "#d1d5db", textAlign: "left", whiteSpace: "pre-wrap" }}>
                    {modalData?.stringAns || "No response provided"}
                  </Typography>
                </div>
              </div>

              <div style={{ marginBottom: "20px" }}>
                <Typography variant="h6" sx={{ color: "#d1d5db", marginBottom: "10px", textAlign: "left" }}>
                  Correct Answer:
                </Typography>
                <div style={{
                  padding: "15px",
                  borderRadius: "8px",
                  backgroundColor: "rgba(255, 255, 255, 0.03)",
                  border: "1px solid rgba(209, 213, 219, 0.3)",
                }}>
                  <Typography sx={{ color: "#d1d5db", textAlign: "left", whiteSpace: "pre-wrap" }}>
                    {modalData?.correctAnswers?.[0] || "No answer available"}
                  </Typography>
                </div>
              </div>

              <div style={{ 
                marginTop: "20px", 
                display: "flex", 
                justifyContent: "flex-start",
                alignItems: "center",
                gap: "10px"
              }}>
                <Typography sx={{ color: "#d1d5db", fontSize: "0.9rem" }}>
                  Score: 
                </Typography>
                <Chip
                  label={`${modalData?.score?.overall || 0}/10`}
                  variant="outlined"
                  sx={{
                    fontSize: "16px",
                    padding: "15px",
                    borderWidth: "2px",
                    color: "#d1d5db",
                    borderColor: "rgba(209, 213, 219, 0.5)",
                    backgroundColor: "rgba(255, 255, 255, 0.03)"
                  }}
                />
              </div>
            </div>
          )}
            
          {/* New Short Answer UI */}
          {modalData?.module_subcategory?.includes('Short Answer') && (
            <div className="short-answer" style={{ 
              marginTop: "30px",
              padding: "20px",
              backgroundColor: "rgba(255, 255, 255, 0.05)",
              borderRadius: "8px",
              maxWidth: "70vw",
              width: "100%",
              margin: "0 auto"
            }}>
              {/* Answer Section */}
              <div style={{ marginBottom: "20px" }}>
                <Typography sx={{ 
                  color: "#d1d5db", 
                  textAlign: "left",
                  fontSize: "18px",
                  fontWeight: "500"
                }}>
                  {modalData?.score?.text || "No response provided"}
                </Typography>
              </div>

              {/* Score Display */}
              <div style={{ marginBottom: "20px", textAlign: "center" }}>
                <Typography variant="h6" sx={{ color: "#d1d5db", marginBottom: "20px" }}>
                  Score : {modalData?.score?.overall || 0}/1
                </Typography>
                
                {/* Content Score Bar */}
                <div style={{ 
                  display: "flex", 
                  alignItems: "center",
                  marginBottom: "10px",
                  gap: "10px"
                }}>
                  <Typography sx={{ 
                    color: "#d1d5db", 
                    minWidth: "80px",
                    fontSize: "14px"
                  }}>
                    Content {modalData?.score?.content || 0}/1
                  </Typography>
                  <Box sx={{
                    flex: 1,
                    height: "6px",
                    bgcolor: "rgba(255, 255, 255, 0.1)",
                    borderRadius: "3px",
                    position: "relative"
                  }}>
                    <Box sx={{
                      position: "absolute",
                      left: 0,
                      top: 0,
                      height: "100%",
                      width: `${(modalData?.score?.content || 0) * 100}%`,
                      bgcolor: "#00E676",
                      borderRadius: "3px"
                    }} />
                  </Box>
                </div>
              </div>

              {/* Question Section */}
              <div style={{ marginBottom: "20px" }}>
                <Typography sx={{ 
                  color: "#d1d5db", 
                  textAlign: "left",
                  fontSize: "16px"
                }}>
                  <div>{modalData?.questionId?.audio_text || "No question available"}</div>
                  <div style={{ marginTop: "8px" }}>
                    {'=>'} {modalData?.questionId?.answer?.split('=')[1] || "No answer available"}
                  </div>
                </Typography>
                {/* Audio Player */}
                <div className="d-flex justify-content-start mt-2">
                  <TextToSpeechPlayer
                    text={modalData?.questionId?.audio_text || ""}
                  />
                </div>
              </div>

              {/* Response Audio if available */}
              {modalData?.score.text && (
                <div className="d-flex justify-content-start mt-2">
                  <AudioPlayer
                    src={`${process.env.REACT_APP_BASE_URL}/${modalData?.answer_audio}`}
                  />
                </div>
              )}
            </div>
          )}

          {/* Speaking - Repeat Sentence UI */}
          {(modalData?.module_type?.includes('Speaking') && 
          modalData?.module_subcategory?.includes('Repeat Sentence') ||
          modalData?.module_subcategory?.includes('Read Aloud') ||
          modalData?.module_subcategory?.includes('Describe Image') ||
          modalData?.module_subcategory?.includes('Short Answer') ||
          modalData?.module_subcategory?.includes('Retell Lecture') ||
          modalData?.module_subcategory?.includes('Respond to Situation'))          
          && (
            <div className="speaking-repeat-sentence" style={{ 
              marginTop: "30px",
              padding: "20px",
              backgroundColor: "rgba(255, 255, 255, 0.05)",
              borderRadius: "8px",
              maxWidth: "70vw",
              width: "100%",
              margin: "0 auto"
            }}>
              {/* Question Image for Describe Image */}
              

              <div style={{ display: "flex", gap: "30px" }}>
                {/* Left Section - Scores and Audio */}
                <div style={{ flex: "0 0 300px" }}>
                  {/* Score Display */}
                  {modalData?.module_subcategory?.includes('Describe Image') && modalData?.questionId?.image && (
                  <div style={{ 
                    marginBottom: "20px",
                    textAlign: "center"
                  }}>
                      <img
                        src={`${process.env.REACT_APP_BASE_URL}/${modalData.questionId.image}`}
                        alt="Question Image"
                        style={{
                          maxWidth: "100%",
                          maxHeight: "300px",
                          borderRadius: "8px",
                          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)"
                        }}
                      />
                    </div>
                  )}
                  <Typography variant="h6" sx={{ 
                    color: "#d1d5db", 
                    marginBottom: "20px",
                    fontSize: "24px",
                    fontWeight: "500"
                  }}>
                    Score : {Math.floor((modalData?.score?.content + modalData?.score?.pronunciation + modalData?.score?.fluency) / 3)}/90.0
                  </Typography>

                  {/* Score Bars */}
                  <div style={{ marginBottom: "30px" }}>
                    {/* Content Score Bar */}
                    <div style={{ marginBottom: "15px" }}>
                      <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "5px" }}>
                        <Typography sx={{ color: "#d1d5db", fontSize: "14px" }}>Content</Typography>
                        <Typography sx={{ color: "#d1d5db", fontSize: "14px" }}>{modalData?.score?.content || 0}/90</Typography>
                      </div>
                      <Box sx={{
                        height: "8px",
                        bgcolor: "rgba(255, 255, 255, 0.1)",
                        borderRadius: "4px",
                        position: "relative"
                      }}>
                        <Box sx={{
                          position: "absolute",
                          left: 0,
                          top: 0,
                          height: "100%",
                          width: `${((modalData?.score?.content || 0) / 90) * 100}%`,
                          bgcolor: "#00E676",
                          borderRadius: "4px"
                        }} />
                      </Box>
                    </div>

                    {/* Pronunciation Score Bar */}
                    <div style={{ marginBottom: "15px" }}>
                      <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "5px" }}>
                        <Typography sx={{ color: "#d1d5db", fontSize: "14px" }}>Pronun</Typography>
                        <Typography sx={{ color: "#d1d5db", fontSize: "14px" }}>{modalData?.score?.pronunciation || 0}/90</Typography>
                      </div>
                      <Box sx={{
                        height: "8px",
                        bgcolor: "rgba(255, 255, 255, 0.1)",
                        borderRadius: "4px",
                        position: "relative"
                      }}>
                        <Box sx={{
                          position: "absolute",
                          left: 0,
                          top: 0,
                          height: "100%",
                          width: `${((modalData?.score?.pronunciation || 0) / 90) * 100}%`,
                          bgcolor: "#00E676",
                          borderRadius: "4px"
                        }} />
                      </Box>
                    </div>

                    {/* Fluency Score Bar */}
                    <div style={{ marginBottom: "15px" }}>
                      <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "5px" }}>
                        <Typography sx={{ color: "#d1d5db", fontSize: "14px" }}>Fluency</Typography>
                        <Typography sx={{ color: "#d1d5db", fontSize: "14px" }}>{modalData?.score?.fluency || 0}/90</Typography>
                      </div>
                      <Box sx={{
                        height: "8px",
                        bgcolor: "rgba(255, 255, 255, 0.1)",
                        borderRadius: "4px",
                        position: "relative"
                      }}>
                        <Box sx={{
                          position: "absolute",
                          left: 0,
                          top: 0,
                          height: "100%",
                          width: `${((modalData?.score?.fluency || 0) / 90) * 100}%`,
                          bgcolor: "#00E676",
                          borderRadius: "4px"
                        }} />
                      </Box>
                    </div>
                  </div>

                  {/* Audio Players Section */}
                  <div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
                    <div>
                      <Typography sx={{ color: "#d1d5db", fontSize: "14px", marginBottom: "8px" }}>Question</Typography>
                      <TextToSpeechPlayer text={modalData?.questionId?.audio_text || ""} />
                    </div>
                    <div>
                      <Typography sx={{ color: "#d1d5db", fontSize: "14px", marginBottom: "8px" }}>Response</Typography>
                      <AudioPlayer src={`${process.env.REACT_APP_BASE_URL}/${modalData?.answer_audio}`} />
                    </div>
                  </div>
                </div>

                {/* Right Section - Text Display */}
                <div style={{ 
                  flex: 1,
                  backgroundColor: "rgba(255, 255, 255, 0.03)",
                  borderRadius: "8px",
                  padding: "20px",
                  minHeight: "300px",
                  display: "flex",
                  alignItems: "center"
                }}>
                  <Typography sx={{ 
                    color: "#d1d5db", 
                    fontSize: "18px",
                    lineHeight: "1.6",
                    whiteSpace: "pre-wrap"
                  }}>
                    {modalData?.score?.text || "No response text available"}
                  </Typography>
                </div>
              </div>
            </div>
          )}

          {/* Writing Essay UI */}
          {modalData?.module_type?.includes('Writing') && 
          (modalData?.module_subcategory?.includes('Essay') ||
          modalData?.module_subcategory?.includes('Summarize Text') ||
          modalData?.module_subcategory?.includes('Write Email')
          ) && (
            <div className="writing-essay" style={{ 
              marginTop: "30px",
              padding: "20px",
              backgroundColor: "rgba(255, 255, 255, 0.05)",
              borderRadius: "8px",
              width: "100%",
              margin: "0 auto"
            }}>
              {/* Question Text */}
              <div style={{
                padding: "15px",
                backgroundColor: "rgba(255, 255, 255, 0.03)",
                borderRadius: "8px",
                marginBottom: "20px"
              }}>
                <Typography sx={{ 
                  color: "#d1d5db",
                  fontSize: "16px",
                  fontWeight: "500"
                }}>
                  {modalData?.questionId?.question_text}
                </Typography>
              </div>

              <div style={{ display: "flex", gap: "30px" }}>
                {/* Left Section - Scores */}
                <div style={{ flex: "0 0 300px" }}>
                  {/* Score Display */}
                  <Typography variant="h6" sx={{ 
                    color: "#d1d5db", 
                    marginBottom: "20px",
                    fontSize: "24px",
                    fontWeight: "500"
                  }}>
                    Score : {modalData?.score?.overall || 0}/{modalData?.score?.total || 15}
                  </Typography>

                  {/* Score Bars */}
                  <div style={{ marginBottom: "30px" }}>
                    {Object.entries(modalData?.score || {}).map(([key, value]) => {
                      // Skip overall and total properties as they're not score bars
                      if (key === 'overall' || key === 'total' || key === 'text') return null;

                      // Get max score for each category
                      const maxScore = {
                        content: 3,
                        form: 2,
                        grammar: 2,
                        vocabulary: 2,
                        spelling: 2,
                        linguistic: 2,
                        development: 2
                      }[key] || 2; // default to 2 if not specified

                      // Format the label
                      const label = {
                        content: 'Content',
                        form: 'Form',
                        grammar: 'Grammar',
                        vocabulary: 'Vocab',
                        spelling: 'Spelling',
                        linguistic: 'Linguist',
                        development: 'Develop'
                      }[key] || key;

                      return (
                        <div key={key} style={{ marginBottom: "15px" }}>
                          <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "5px" }}>
                            <Typography sx={{ color: "#d1d5db", fontSize: "14px" }}>{label}</Typography>
                            <Typography sx={{ color: "#d1d5db", fontSize: "14px" }}>{value}/{maxScore}</Typography>
                          </div>
                          <Box sx={{
                            height: "8px",
                            bgcolor: "rgba(255, 255, 255, 0.1)",
                            borderRadius: "4px",
                            position: "relative"
                          }}>
                            <Box sx={{
                              position: "absolute",
                              left: 0,
                              top: 0,
                              height: "100%",
                              width: `${(value / maxScore) * 100}%`,
                              bgcolor: "#00E676",
                              borderRadius: "4px"
                            }} />
                          </Box>
                        </div>
                      );
                    })}
                  </div>
                </div>

                {/* Right Section - Essay Text */}
                <div style={{ 
                  flex: 1,
                  backgroundColor: "rgba(255, 255, 255, 0.03)",
                  borderRadius: "8px",
                  padding: "20px",
                  minHeight: "300px"
                }}>
                  <Typography sx={{ 
                    color: "#d1d5db", 
                    fontSize: "16px",
                    lineHeight: "1.6",
                    whiteSpace: "pre-wrap"
                  }}>
                    {modalData?.score?.text || "No essay text available"}
                  </Typography>
                </div>
              </div>

              {/* Suggestions Section */}
              {modalData?.suggestions && (
                <div style={{ 
                  marginTop: "20px",
                  padding: "15px",
                  backgroundColor: "rgba(255, 255, 255, 0.03)",
                  borderRadius: "8px"
                }}>
                  <Typography sx={{ 
                    color: "#d1d5db",
                    fontSize: "14px"
                  }}>
                    Suggestions: {modalData.suggestions}
                  </Typography>
                </div>
              )}
            </div>
          )}

          <div className="d-flex justify-content-between mt-4">
            {modalData.feedback ? (
              <p>
                {modalData.feedback && "Feedback -"} {modalData.feedback}
              </p>
            ) : (
              <p>
                {feedback && "Feedback -"} {feedback}
              </p>
            )}

            {feedbutton === true && modalData.feedback === "" && (
              <button
                className="btn btn-success"
                onClick={() => setFeedbackModal(true)}
              >
                Add Feedback
              </button>
            )}
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={feedbackModal}
        onRequestClose={() => {
          setFeedbackModal(false);
          setfeedback("");
          setFeedbackButton(true);
        }}
        contentLabel="Read Aloud Modal"
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "#2d2f3a",
            color: "#d1d5db",
            borderRadius: "8px",
            padding: "20px",
            width: "70%",
          },
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.75)",
          },
        }}
      >
        <div style={{ textAlign: "center" }}>
          <div className="d-flex justify-content-between">
            <Typography
              variant="h5"
              sx={{ color: "#d1d5db", marginBottom: "20px" }}
            >
              Add Feedback
            </Typography>

            <IconButton>
              <CloseOutlined
                onClick={() => setFeedbackModal(false)}
                sx={{
                  color: "red",
                }}
              />
            </IconButton>
          </div>

          <div className="d-flex align-items-center justify-content-evenly mt-3">
            <textarea
              className="w-100 bg-transparent text-white"
              name=""
              id=""
              rows={9}
              resize="none"
              onChange={(e) => setfeedback(e.target.value)}
            />
          </div>

          <div className="d-flex justify-content-end mt-4">
            <button
              className="btn btn-success"
              onClick={() => submitFeedback()}
            >
              Submit Feedback
            </button>
          </div>
        </div>
      </Modal>
    </Card>
  );
}

export default BillingInformation;