import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  IconButton,
  Container,
  Modal,
  Button,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import AudioComponent from "components/Recorder/AudioComponent";
import RepeatParagraphAloud from "components/AudioPlayer/AudioPlayer";
import { getApi } from "services/axiosInstance";
import { API_PATH } from "services/apipath";
import { postApi } from "services/axiosInstance";
import PteSpeakingPractice from "./PteSpeakingPractice";
import SendIcon from "@mui/icons-material/Send";
import { Send } from "@mui/icons-material";

const ReadAloudMock = ({ questionData, onNext }) => {
  const lastId = localStorage.getItem("lastId");
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const Category = params.get("category");
  const SubCategory = params.get("subcategory");
  let id = location.pathname.split("/")[2];
  const childRef = React.useRef();

  const navigate = useNavigate();

  const findIndex = () => {
    const questionIndex = questionData.questions?.findIndex(
      (question) => question.id === id
    );

    setCurrentIndex(questionIndex);
    setCurrentQuestion(questionIndex + 1);
    if (questionIndex === -1) {
    } else {
    }
    // setPath(`/mocktest/${option.label}/${subCategory.subCategory}/${base}`);
  };
  useEffect(() => {
    if (questionData) {
      findIndex();
    }
  }, [questionData]);

  const [currentQuestion, setCurrentQuestion] = useState(1);
  const [openSearchModal, setOpenSearchModal] = useState(false);
  const [recordedAudio, setRecordedAudio] = useState(null);
  const [openAnalysisModal, setOpenAnalysisModal] = useState(false);
  
  const [scores, setScores] = useState({
    content: "",
    pronunciation: 0,
    fluency: 0,
    overall: 0,
    vocabulary: 0,
    grammar: 0,
  });

  const [questionid, setQuestionId] = useState();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [questionDataApi, setQuestionDataApi] = useState([]);
  useEffect(() => {
    setQuestionDataApi(questionData);
  }, [questionData]);

  const getQuestionBtID = async (id) => {
    try {
      const res = await getApi(`${API_PATH.STUDENTS.GET_QUESTION_BY_ID}/${id}`);
      if (res.status === 200) {
        setQuestionDataApi(res.data.data);
        setRecordedAudio(null);
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (id && questionid === undefined) {
      // getQuestionBtID(id);
    }
  }, []);

  useEffect(() => {
    return () => {
      if (recordedAudio?.url) {
        URL.revokeObjectURL(recordedAudio.url);
      }
    };
  }, []);

  const handleRecordingComplete = async (recording) => {
    setRecordedAudio(recording);
    const formData = new FormData();
    formData.append("questionId", id);

    formData.append("ansfile", recording.blob);
    const ans_sub = await postApi(`${API_PATH.MOCK_TEST.SUBMIT_MOCK_TEST_ANS}`, formData);
    if (ans_sub.status === 201) {
      if (ans_sub?.data?.data?.score?.text !== "") {
        setScores({
          content: ans_sub?.data?.data?.score?.text || "",
          pronunciation: ans_sub?.data?.data?.score?.pronunciation || 0,
          fluency: ans_sub?.data?.data?.score?.fluency || 0,
          overall: ans_sub?.data?.data?.score?.overall || 0,
          vocabulary: ans_sub?.data?.data?.score?.vocabulary || 0,
          grammar: ans_sub?.data?.data?.score?.grammar || 0,
        });
      }
    }
  };

  // Countdown logic

  const [timeRemaining, setTimeRemaining] = useState(30 * 60); // 30 minutes in seconds
  useEffect(() => {
    if (timeRemaining > 0) {
      const timer = setTimeout(
        () => setTimeRemaining((prev) => prev - 1),
        1000
      );
      return () => clearTimeout(timer); // Cleanup on unmount or update
    }
  }, [timeRemaining]);

  // Convert timeRemaining (in seconds) to minutes and seconds
  const formatTime = () => {
    const minutes = Math.floor(timeRemaining / 60);
    const seconds = timeRemaining % 60;
    return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(
      2,
      "0"
    )}`;
  };


  const onNextClicked = () => {
    console.log("Button clicked, calling child API...");
    // Triggering the child component's API function
    // childRef.current.handleSubmitAnalysis();
    if (childRef.current) {
      console.log("childRef is defined:", childRef.current);
      childRef.current.handleSubmitAnalysis();
    } else {
      console.error("childRef is not defined!");
    }
    onNext()
  };

  return (
    <Box sx={{ bgcolor: "white", p: 2 }}>
      <Container
        maxWidth="lg"
        sx={{
          // pt: "70px",
          // p: 2,
          // minHeight: "95vh",
          position: "relative",
          width: "100% !important",
        }}
      >
        <Typography
          sx={{
            // mt: 8,
            // mb: 2,
            fontSize: "15px",
            fontStyle: "italic",
            color: "black",
          }}
        >
          Look at the text below. In 40 seconds, you must read this text aloud
          as naturally and clearly as possible. You have 40 seconds to Read
          Aloud.
        </Typography>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingY: "35px",
          }}
        >   
          <PteSpeakingPractice
            originalText={questionDataApi.question_text}
            module={questionDataApi?.module_type}
            sub_module={questionDataApi?.module_subcategory}
            id={questionDataApi._id}
            SubCategory={SubCategory}
            timer={40}
            ref={childRef}
          />
        </Box>

        <Typography sx={{ color: "black !important", fontSize: "16px" }}>
          {questionDataApi.question_text}{" "}
        </Typography>
      </Container>
      <Container>
        <Box
          sx={{
            // p: 2,
            mt: 3,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            verticalAlign: "middle",
          }}
        >
          <Button
            variant="outlined"
            sx={{ color: "black !important" }}
            onClick={() => navigate("/mocktest")}
          >
            Save & Exit
          </Button>

          <Box
            sx={{ display: "flex", alignItems: "center", gap: 1, ml: "auto" }}
          >
            <Button
              variant="contained"
              sx={{ color: "white !important" }}
              endIcon={<Send />}
              onClick={onNextClicked}
            >
               {lastId  === "true" ? "Submit" : "Next"}  
            </Button>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default ReadAloudMock;