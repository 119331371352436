// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CircularProgress from "@mui/material/CircularProgress";

// Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import AlertModal from "components/Modal/AlertModal";

// Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

// React and third-party components
import React, { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import { API_PATH } from "services/apipath";
import { getApi } from "services/axiosInstance";
import { useNavigate } from "react-router-dom";

function PlanDetails() {
  const navigate = useNavigate();
  const [planDetails, setPlanDetails] = useState({
    student_name: "",
    student_username: "",
    plan_expiry_date: "",
    plan_registration_date: "",
    plan_type: "",
    plan_status: "",
    institute_name: "",
    institute_location: "",
  });

  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState("Please contact the institute.");
  const handleClose = () => setOpen(false);
  // Extract the question ID from the URL

  const formatDate = (isoString) => {
    const date = new Date(isoString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  // Function to fetch plan details
  const getStudentPlanDetails = async () => {
    try {
      const res = await getApi(API_PATH.STUDENTS.GET_STUDENT_PROFILE, {});
      if (res.status === 200 && res.data) {
        const data = res.data || {};

        setPlanDetails({
          student_name: data.name || "N/A",
          student_username: data.username || "N/A",
          plan_expiry_date: formatDate(data.expiryDate) || "N/A",
          plan_registration_date:
            formatDate(data.instituteId?.createdAt) || "N/A",
          plan_type: data.studentPlanName || "N/A",
          plan_status: data.disableLogin ? "Inactive" : "Active",
          institute_name: data.instituteId?.instituteName || "N/A",
          institute_location: data.instituteId?.location || "N/A",
        });
      }
    } catch (error) {
    }
  };

  useEffect(() => {
    getStudentPlanDetails();
  }, []);

  useEffect(() => {
    const currentDate = new Date();
    const hasPlanExpired = (currentDate, expiryDate) => {
      // Convert the expiry date from DD-MM-YYYY to a Date object to compare them
      const [day, month, year] = expiryDate.split("-");
      const expiry = new Date(year, month - 1, day); // Month is 0-based in Date

      return currentDate > expiry;
    };
    //check if the plan is inactive or expired
    if (planDetails.plan_status === "Inactive") {
      // plan inactive
      setMsg("Your login has been disabled. Please contact the institute.");
    } else if (hasPlanExpired(currentDate, planDetails.plan_expiry_date)) {
      // plan expired
      setMsg("Your plan has expired.Please contact the institute.");
    }

    if (
      planDetails.plan_status === "Inactive" ||
      hasPlanExpired(currentDate, planDetails.plan_expiry_date)
    ) {
      setOpen(true);
      localStorage.setItem("isAuthenticated", "false");
      localStorage.setItem("token", "");
      navigate("/signin");
    }
  }, [planDetails, navigate]);

  return (
    <DashboardLayout>
      {planDetails.student_name ? (
        <SoftBox mb={3}>
          <Card>
            <SoftBox pt={2} px={2}>
              <SoftBox mb={0.5}>
                <SoftTypography variant="h4" fontWeight="medium">
                  Plan Details
                </SoftTypography>
              </SoftBox>
            </SoftBox>
            <Grid
              container
              spacing={0}
              sx={{ paddingX: "16px", paddingBottom: "16px" }}
            >
              <Grid item xs={12} md={6} sx={{ marginTop: "15px" }}>
                {/* <Typography sx={{ fontSize: "18px", marginTop: "8px" }}>
                  Plan Registration Date: {planDetails.plan_registration_date}
                </Typography> */}
                <Typography sx={{ fontSize: "18px", marginTop: "8px" }}>
                  Plan Type: {planDetails.plan_type}
                </Typography>
                <Typography sx={{ fontSize: "18px", marginTop: "8px" }}>
                  Plan Status: {planDetails.plan_status}
                </Typography>
                <Typography sx={{ fontSize: "18px", marginTop: "8px" }}>
                  Plan Expiry Date: {planDetails.plan_expiry_date}
                </Typography>
              </Grid>
            </Grid>
          </Card>
        </SoftBox>
      ) : (
        <SoftBox
          mb={3}
          sx={{
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100px",
          }}
        >
          <CircularProgress />
        </SoftBox>
      )}
      <AlertModal open={open} handleClose={handleClose} msg={msg} />
    </DashboardLayout>
  );
}

export default PlanDetails;
