import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  Typography,
  IconButton,
  Button,
  Container,
  Modal,
  Select,
  MenuItem,
} from "@mui/material";
import { Switch } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { getApi } from "services/axiosInstance";
import { API_PATH } from "services/apipath";
import { postApi } from "services/axiosInstance";
import AudioPlayer from "layouts/listening/AudioPlayer";
import { Send } from "@mui/icons-material";


const total = 9.0;
function EssayAnswersAcedimicMockWrite({ questionData, onNext, timerStart }) {
  const lastId = localStorage.getItem("lastId");
  const timeLimit = 10;
  const wordLimit = { min: 200, max: 300 };
  const [studentText, setStudentText] = useState("");
  const [sampleText, setSampleText] = useState("");
  const [errors, setErrors] = useState([]);
  const [relevantKeywords, setRelevantKeywords] = useState([]);
  const [wordCount, setWordCount] = useState(0);
  const [timeRemaining, setTimeRemaining] = useState(timeLimit * 60);
  const [isPaused, setIsPaused] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [scoreButton, setScoreButton] = useState(false);

  useEffect(() => {
    if (isPaused || timeRemaining <= 0) return;

    const timer = setInterval(() => {
      setTimeRemaining((prevTime) => {
        if (prevTime <= 0) {
          clearInterval(timer);
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, [isPaused, timeRemaining]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds.toString().padStart(2, "0")}`;
  };

  const pauseTimer = () => {
    setIsPaused(true);
  };

  const handleTextChange = (e) => {
    const newText = e.target.value;
    setStudentText(newText);
    const words = newText.trim().split(/\s+/).filter(Boolean).length;
    setWordCount(words);
  };

  const location = useLocation();
  let fullTestId = location.pathname.split("/")[2];
  const params = new URLSearchParams(location.search);
  const Category = params.get("category");
  let id = location.pathname.split("/")[2];

  const navigate = useNavigate();

  const findIndex = () => {
    const questionIndex = questionData.questions?.findIndex(
      (question) => question.id === id
    );

    setCurrentIndex(questionIndex);
    setCurrentQuestion(questionIndex + 1);
    if (questionIndex === -1) {
    }
  };

  useEffect(() => {
    if (questionData) {
      findIndex();
    }
  }, [questionData]);

  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [submitedAns, setSubmitedAns] = useState(null);

  const [currentQuestion, setCurrentQuestion] = useState(1);
  const [openSearchModal, setOpenSearchModal] = useState(false);
  const [openAnalysisModal, setOpenAnalysisModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const [scores, setScores] = useState({
    overall: total,
    content: 0,
    form: 0,
    grammar: 0,
    vocabulary: 0,
    spelling: 0,
  });

  const [questionid, setQuestionId] = useState();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [questionDataApi, setQuestionDataApi] = useState([]);

  useEffect(() => {
    setQuestionDataApi(questionData);
  }, [questionData]);

  const getQuestionBtID = async (id) => {
    try {
      const res = await getApi(`${API_PATH.STUDENTS.GET_QUESTION_BY_ID}/${id}`);
      if (res.status === 200) {
        setQuestionDataApi(res.data.data);
        setSampleText(res.data.data.answer);
        setRelevantKeywords(res.data.data.keyWords);
      }
    } catch (error) { }
  };

  useEffect(() => {
    if (id) {
      // getQuestionBtID(id);
    }
  }, []);

  const handleSubmit = async () => {
    const newScores = await evaluateText();
    const payload = {
      fullTestId: fullTestId,
      currentQuestionId: questionData._id,
      student_ans: JSON.stringify(studentText),
      module: questionData.module_type[0],
      sub_module: questionData.module_subcategory[0],
      score: JSON.stringify(newScores),
    };

    try {
      const answer = await postApi(
        API_PATH.MOCK_TEST.SUBMIT_MOCK_TEST_ANS,
        payload
      );
      if (answer.status === 200) {
        // Reset the timer to 10 minutes (600 seconds)
        setTimeLeft(10 * 60);

        setWordCount(0);

        JSON.parse(lastId) ? navigate("/mocktest") : onNext();
        setStudentText("");
      }
    } catch (error) { }
  };

  const [checked, setChecked] = useState(false);

  const handleToggle = (event) => {
    setChecked(event.target.checked);
  };

  // checking
  const checkRelevancy = (text) => {
    const textLower = text.toLowerCase();
    const foundKeywords = relevantKeywords.filter((keyword) =>
      textLower.includes(keyword.toLowerCase())
    );
    return {
      isRelevant: foundKeywords.length >= 2,
      keywordCount: foundKeywords.length,
      foundKeywords,
    };
  };

  const evaluateGrammarSpelling = async (text) => {
    try {
      setLoading(true);
      try {
        const response = await postApi(API_PATH.STUDENTS.CHECK_GRAMMAR, {
          text,
        });
        setLoading(false);

        const result = response.data.result;
        setErrors(result.edits);

        setWordCount(
          text.split(/\s+/).filter((word) => word.length > 0).length
        );
        setLoading(false);
        return result || [];
      } catch (error) {
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const [highlightedText, setHighlightedText] = useState(false);
  // Component to highlight mistakes based on API response
  const HighlightedText = ({ text, errors }) => {
    if (!text) return null;

    let displayText = text;
    const textParts = [];
    let lastIndex = 0;

    // Sort errors by offset to process them in order
    const sortedErrors = [...errors].sort((a, b) => a.start - b.start);

    sortedErrors.forEach((error) => {
      const { start, end, replace, err_cat } = error;
      const errorType = err_cat === "GRMR" ? "text-warning" : "text-danger";

      // Add text before error
      if (start > lastIndex) {
        textParts.push(
          <span key={`normal-${lastIndex}`} className="text-success">
            {text.slice(lastIndex, start)}
          </span>
        );
      }

      // Add error text with modification or deletion
      textParts.push(
        <span key={`error-${start}`} className={`p-1 ${errorType}`}>
          {replace || text.slice(start, end)}{" "}
          {/* If replace exists, show the replacement */}
        </span>
      );

      lastIndex = end;
    });

    // Add remaining text after the last error
    if (lastIndex < text.length) {
      textParts.push(
        <span key={`normal-end`} className="text-success">
          {text.slice(lastIndex)}
        </span>
      );
    }

    return <div className=" p-2 rounded highLightText">{textParts}</div>;
  };

  const evaluateText = async () => {
    setLoading(true);
    const apiResponse = await evaluateGrammarSpelling(studentText);
    const grammarErrors = apiResponse.edits;
    const relevancy = checkRelevancy(studentText);

    let newScores = {
      overall: 0,
      content: 0,
      form: 0,
      grammar: 0,
      vocabulary: 0,
      spelling: 0,
    };

    if (relevancy.isRelevant) {
      const baseScore = 90;
      const errorPenalty = grammarErrors.length * 5;

      // Calculate content score out of 100
      const contentScore = Math.max(0, baseScore - errorPenalty);
      const formScore = Math.max(0, baseScore - errorPenalty / 2);
      const grammarScore = Math.max(
        0,
        baseScore -
        grammarErrors.filter((e) => e.rule?.category === "GRMR").length * 10
      );
      const vocabularyScore = Math.max(
        0,
        (relevancy.keywordCount / relevantKeywords.length) * 100
      );
      const spellingScore = Math.max(
        0,
        baseScore -
        grammarErrors.filter((e) => e.rule?.category === "SPELL").length * 10
      );
      // Scale all scores to be out of 2
      const contentScoreOutOf2 = Math.round((contentScore / 100) * 2);
      const formScoreOutOf2 = Math.round((formScore / 100) * 1);
      const grammarScoreOutOf2 = Math.round((grammarScore / 100) * 2);
      const vocabularyScoreOutOf2 = Math.round((vocabularyScore / 100) * 2);
      const spellingScoreOutOf2 = Math.round((spellingScore / 100) * 2);

      newScores = {
        overall:
          parseFloat(contentScoreOutOf2) +
          parseFloat(formScoreOutOf2) +
          parseFloat(grammarScoreOutOf2) +
          parseFloat(vocabularyScoreOutOf2) +
          parseFloat(spellingScoreOutOf2),
        content: contentScoreOutOf2, // out of 2
        form: formScoreOutOf2, // out of 2
        grammar: grammarScoreOutOf2, // out of 2
        vocabulary: vocabularyScoreOutOf2, // out of 2
        spelling: spellingScoreOutOf2, // out of 2
      };
    }

    setScores(newScores);
    setErrors(grammarErrors);
    setWordCount(
      studentText.split(/\s+/).filter((word) => word.length > 0).length
    );

    setLoading(false);
    return newScores
  };

  // <==============count down================>
  const [timeLeft, setTimeLeft] = useState(20 * 60); // 20 minutes in seconds
  const [open, setOpen] = useState(false); // Controls modal visibility

  useEffect(() => {
    if (!timerStart) {
      return;
    }
    if (timeLeft === 0) {
      setOpen(true); // Open the modal when time is up
      return;
    }

    const timer = setInterval(() => {
      setTimeLeft((prevTime) => prevTime - 1);
    }, 1000);

    return () => clearInterval(timer); // Cleanup interval on component unmount
  }, [timeLeft, timerStart]);

  // Format time as mm:ss
  const formatTimeWriting = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes}:${secs < 10 ? "0" : ""}${secs}`;
  };

  return (
    <Box sx={{ bgcolor: "white" }}>
      <Container maxWidth="lg" sx={{ mt: 1 }}>
        {/* Instructions */}
        <Typography
          sx={{
            color: "black",
            fontStyle: "italic",
            mb: 1,
            fontSize: "17px",
          }}
        >
          You will have 20 minutes to plan, write and revise an essay about the topic below. Your response will be judged on how well you develop a position, organize your ideas, present supporting details, and control the elements of standard written English. You should write 200-300 words.
        </Typography>

        {/* Question */}
        <Box
          sx={{
            bgcolor: "rgba(255,255,255,0.1)",
            p: 1,
            borderRadius: 1,
            mb: 2,
            width: "100%",
          }}
        >
          <Typography
            sx={{
              color: "black",
              wordWrap: "break-word",
              overflowWrap: "break-word",
              fontSize: "15px",
            }}
          >
            {questionDataApi.question_text}
          </Typography>
        </Box>

        {/* <AudioPlayer
          audioText={JSON.stringify(questionDataApi.audio_text)}
          delay={3}
        /> */}

        {/* Answer Box */}
        <Box
          sx={{
            // bgcolor: "#fff",
            borderRadius: 1,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 1,
            }}
          >
            <Typography>Answer</Typography>
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <Typography sx={{ marginRight: "25px" }}>
                Words: {wordCount}
              </Typography>

              <Typography variant="h6">
                Time Remaining: {formatTimeWriting(timeLeft)}
              </Typography>

              {/* Modal for Time Over Message */}
              <Modal open={open} onClose={() => setOpen(false)}>
                <Box
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: 400,
                    bgcolor: "background.paper",
                    // boxShadow: 24,
                    p: 4,
                    textAlign: "center",
                    // borderRadius: 2
                  }}
                >
                  <Typography variant="h6" color="error">
                    Time is Up!
                  </Typography>
                  <Typography>Please move to the next question.</Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setOpen(false)}
                    sx={{ mt: 2 }}
                  >
                    OK
                  </Button>
                </Box>
              </Modal>
            </Box>
          </Box>

          {!highlightedText && (
            <TextField
              fullWidth
              multiline
              rows={10}
              value={studentText}
              placeholder="Type the answer here"
              onChange={(e) => handleTextChange(e)}
              disabled={timeRemaining === 0}
              spellCheck={false} // Disables spellchecking
              sx={{
                "& .MuiOutlinedInput-root": {
                  backgroundColor: "#E8E9F3 !important",
                  width: "100% !important",
                  "&:hover": {
                    backgroundColor: "#E8E9F3 !important",
                  },
                  "& fieldset": {
                    borderColor: "#E0E0E0",
                  },
                },
                "& .MuiOutlinedInput-input": {
                  width: "100% !important",
                  backgroundColor: "#E8E9F3 !important",
                },
              }}
            />
          )}

          {highlightedText && (
            <HighlightedText text={studentText} errors={errors} />
          )}
          {/* <Box
            display="flex"
            alignItems="center"
            sx={{
              padding: "10px 20px",
              borderRadius: "8px",
            }}
          >
            <Switch
              checked={checked}
              onChange={handleToggle}
              color="success" // Green color when toggled
            />
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: "500",
                marginLeft: "8px",
              }}
            >
              Show Answer
            </Typography>
          </Box>
          {checked && (
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: "400",
                marginTop: "16px",
                backgroundColor: "#f9f9f9",
                padding: "12px",
                borderRadius: "8px",
                border: "1px solid #e0e0e0",
              }}
            >
              {questionDataApi.answer}
            </Typography>
          )} */}
        </Box>
      </Container>

      <Container>
        <Box
          sx={{
            // p: 2,
            mt: 3,
            mb: 2,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            verticalAlign: "middle",
          }}
        >
          <Button
            variant="outlined"
            sx={{ color: "black !important" }}
            onClick={() => navigate("/mocktest")}
          >
            Save & Exit
          </Button>

          <Box
            sx={{ display: "flex", alignItems: "center", gap: 1, ml: "auto" }}
          >
            <Button
              variant="contained"
              sx={{ color: "white !important" }}
              endIcon={<Send />}
              onClick={handleSubmit}
            >
              {lastId === "true" ? "Submit" : "Next"}
            </Button>
          </Box>
        </Box>
      </Container>

    </Box>
  );
}

export default EssayAnswersAcedimicMockWrite;