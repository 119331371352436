import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  IconButton,
  Container,
  Modal,
  Button,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import AudioComponent from "components/Recorder/AudioComponent";
import RepeatParagraphAloud from "components/AudioPlayer/AudioPlayer";

import { getApi } from "services/axiosInstance";
import { API_PATH } from "services/apipath";
import { postApi } from "services/axiosInstance";
import PteSpeakingPractice from "./PteSpeakingPractice";
import { Send } from "@mui/icons-material";

const RetellLectureMockTest = ({ questionData, onNext }) => {
  const lastId = localStorage.getItem("lastId");
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const SubCategory = params.get("subcategory");
  let id = location.pathname.split("/")[2];
  const childRef = React.useRef();

  const navigate = useNavigate();

  const findIndex = () => {
    const questionIndex = questionData.questions?.findIndex(
      (question) => question.id === id
    );

    setCurrentIndex(questionIndex);
    setCurrentQuestion(questionIndex + 1);
    if (questionIndex === -1) {
    }
  };

  useEffect(() => {
    if (questionData) {
      // findIndex();
    }
  }, [questionData]);

  const [currentQuestion, setCurrentQuestion] = useState(1);
  const [recordedAudio, setRecordedAudio] = useState(null);
  const [openAnalysisModal, setOpenAnalysisModal] = useState(false);
  const [scores, setScores] = useState({
    content: "",
    pronunciation: 0,
    fluency: 0,
    overall: 0,
    vocabulary: 0,
    grammar: 0,
  });

  const [questionid, setQuestionId] = useState();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [questionDataApi, setQuestionDataApi] = useState([]);

  const getQuestionBtID = async (id) => {
    try {
      const res = await getApi(`${API_PATH.STUDENTS.GET_QUESTION_BY_ID}/${id}`);
      if (res.status === 200) {
        setQuestionDataApi(res.data.data);
        setRecordedAudio(null)
        setStartRecording(false);
      }
    } catch (error) { }
  };

  // useEffect(() => {
  //   if (id && questionid === undefined) {
  //     getQuestionBtID(id);
  //   }
  // }, []);

  useEffect(() => {
    return () => {
      if (recordedAudio?.url) {
        URL.revokeObjectURL(recordedAudio.url);
      }
    };
  }, []);

  const [timer, setTimer] = useState(3);
  // const [startRecording, setStartRecording] = useState(() => {
  //   const savedRecording = localStorage.getItem("startRecording");
  //   return savedRecording ? JSON.parse(savedRecording) : false;
  // });

  const [startRecording, setStartRecording] = useState(false);

  const handleAudioComplete = () => {
    console.log(timer, "Audio Complete");
    // setTimeout(() => {
    setStartRecording(true);
    setTimer(15);
    // }, 15000);

  };

  const onNextClicked = () => {
    console.log({ childRef });

    if (childRef.current) {
      console.log("childRef is defined:", childRef.current);
      childRef.current.handleSubmitAnalysis();
    } else {
      console.error("childRef is not defined!");
    }
    onNext();
  };

  return (
    <Box sx={{ p: 2 }}>
      <Container
        maxWidth="lg"
        sx={{
          pt: "80px",
          p: 2,
          height: "95vh",
          position: "relative",
          width: "100%",
        }}
      >
        <Typography
          sx={{
            fontSize: "15px",
            fontStyle: "italic",
            color: "black",
          }}
        >
          You will hear a speech. After listening to the speech, in 15 seconds,
          please speak into the microphone and retell what you have just heard
          from the speech in your own words. You will have 40 seconds to give
          your response.
        </Typography>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingY: "35px",
          }}
        >
          {/* <RepeatParagraphAloud
            onAudioComplete={handleAudioComplete}
            audioText={JSON.stringify(questionDataApi.audio_text)}
            audioFile={questionDataApi.audio_file}
            delay={3}
          /> */}

          <PteSpeakingPractice
            originalText={questionData.audio_text}
            id={questionData._id}
            SubCategory={questionData.module_subcategory[0]}
            timer={startRecording ? timer : 3}
            startRecording={startRecording}
            audioFile={questionData.audio_file}
            ref={childRef}
          />
        </Box>
      </Container>

      <Container>
        <Box
          sx={{
            // p: 2,
            mt: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            verticalAlign: "middle",
          }}
        >
          <Button
            variant="outlined"
            sx={{ color: "black !important" }}
            onClick={() => navigate("/mocktest")}
          >
            Save & Exit
          </Button>

          <Box
            sx={{ display: "flex", alignItems: "center", gap: 1, ml: "auto" }}
          >
            <Button
              variant="contained"
              sx={{ color: "white !important" }}
              endIcon={<Send />}
              onClick={onNextClicked}
            >
              {lastId === "true" ? "Submit" : "Next"}
            </Button>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};
export default RetellLectureMockTest;