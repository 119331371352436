//  Dashboard React components
import React, { useState } from 'react';

import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";

import { useNavigate } from "react-router-dom";
import CoverLayout from "layouts/authentication/components/CoverLayout";
import curved9 from "assets/images/banda.png";
import { Eye, EyeOff } from 'lucide-react';

import "../../../style.css";

import { postApi } from "services/axiosInstance";
import { API_PATH } from "services/apipath";
import { useForm } from "react-hook-form";

function SignIn() {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [loginError, setLoginError] = useState('');

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const onSubmit = async (data) => {
    try {
      const response = await postApi(API_PATH.STUDENTS.LOGIN, data);

      if (response.data.token) {
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("isAuthenticated", "true");
        navigate("/dashboard");
      }
    } catch (err) {
      setLoginError(err.response?.data?.message || "Invalid Username or Password Please Check Again");
    }
  };

  return (
    <CoverLayout
      title="Welcome"
      description="To PTE Success Hub – Log In to Learn, Practice, and Achieve!"
      image={curved9}
      sx={{ 
        pb: "10px !important",
      }}
    >
      <SoftBox component="form" role="form" onSubmit={handleSubmit(onSubmit)}>
      {loginError && (
        <SoftTypography 
          color="error" 
          variant="caption" 
          sx={{ 
            display: "block",
            fontSize: "14px",
          }}
        >
          {loginError}
        </SoftTypography>
      )}

      <SoftBox mb={2}>
        <SoftBox mb={1} ml={0.5}>
          <SoftTypography component="label" variant="h6" fontWeight="bold">
            Username
          </SoftTypography>
        </SoftBox>
        <SoftInput
          type="username"
          placeholder="username"
          {...register("username", {
            required: "Username is required",
          })}
          error={!!errors.username}
        />
        {errors.username && (
          <SoftTypography color="error" variant="caption">
            {errors.username.message}
          </SoftTypography>
        )}
      </SoftBox>

      <SoftBox mb={2}>
        <SoftBox mb={1} ml={0.5}>
          <SoftTypography component="label" variant="h6" fontWeight="bold">
            Password
          </SoftTypography>
        </SoftBox>
        <SoftBox position="relative">
          <SoftInput
            type={showPassword ? "text" : "password"}
            placeholder="Password"
            {...register("password", { required: "Password is required" })}
            error={!!errors.password}
          />
          <SoftBox
            position="absolute"
            top="50%"
            right="10px"
            sx={{
              transform: 'translateY(-50%)',
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center'
            }}
            onClick={togglePasswordVisibility}
          >
            {showPassword ? (
              <EyeOff size={20} className="text-gray-500" />
            ) : (
              <Eye size={20} className="text-gray-500" />
            )}
          </SoftBox>
        </SoftBox>
        {errors.password && (
          <SoftTypography color="error" variant="caption">
            {errors.password.message}
          </SoftTypography>
        )}
      </SoftBox>

      <SoftBox mt={4} mb={1}>
        <SoftButton variant="gradient" color="info" fullWidth type="submit">
          Sign In
        </SoftButton>
      </SoftBox>
    </SoftBox>
    </CoverLayout>
  );
}

export default SignIn;
