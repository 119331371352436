import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useImperativeHandle,
  forwardRef,
} from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Button,
  Box,
  Typography,
  Modal,
  IconButton,
  Switch,
} from "@mui/material";
import { ReactMic } from "react-mic";
import AudioComponent from "components/Recorder/AudioComponent";
import { Mic, Stop } from "@mui/icons-material";
import { getApi, postApi } from "services/axiosInstance";
import { API_PATH } from "services/apipath";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { useLocation } from "react-router-dom";
import RepeatParagraphAloud from "components/AudioPlayer/AudioPlayer";

const total = 90.0;

const PteSpeakingPractice = forwardRef(
  (
    {
      originalText = "",
      module,
      sub_module,
      id,
      SubCategory,
      audioFile
      // timer,
      // onTimerComplete,
    },
    ref
  ) => {
    const location = useLocation();
    let fullTestId = location.pathname.split("/")[2];
    // const initalPrepTimer = timer;

    const [isListening, setIsListening] = useState(false);
    const [isRecordingStopped, setIsRecordingStopped] = useState(false);
    const [spokenWords, setSpokenWords] = useState([]);
    const [wordStatus, setWordStatus] = useState({});
    const [openAnalysisModal, setOpenAnalysisModal] = useState(false);
    const [recordedAudio, setRecordedAudio] = useState(null);
    const [preparationTimer, setPreparationTimer] = useState(3);
    const [recordingTimer, setRecordingTimer] = useState(15);
    const [timerPhase, setTimerPhase] = useState("pre-preparation"); // 'preparation' | 'recording' | 'completed'
    const [timerVisible, setTimerVisible] = useState(true);
    const [questionDataApi, setQuestionDataApi] = useState([]);
    const [scores, setScores] = useState({
      overall:0,
      pronunciation: 0,
      fluency: 0,
      content: 0,
      totalScore: 0,
    });

    // useEffect(() => {
    //   setPreparationTimer(timer);
    // }, [timer]);

    const originalWordsRef = useRef(originalText.split(/(\s+)/));
    const recognitionRef = useRef(null);
    const recordingTimeoutRef = useRef(null);

    useEffect(() => {
      setIsListening(false);
      setIsRecordingStopped(false);
      setTimerPhase("preparation");
      setTimerVisible(true);
      setChecked(true);

    }, [id]);

    // const originalWordsRef = useRef(originalText.split(/(\s+)/));
    // const recognitionRef = useRef(null);
    // const recordingTimeoutRef = useRef(null);

    // const createBeep = () => {
    //   try {
    //     const audioContext = new AudioContext();
    //     const oscillator = audioContext.createOscillator();
    //     const gainNode = audioContext.createGain();

    //     // Connect the nodes
    //     oscillator.connect(gainNode);
    //     gainNode.connect(audioContext.destination);

    //     // Configure oscillator
    //     oscillator.type = "sine";
    //     oscillator.frequency.setValueAtTime(800, audioContext.currentTime); // Frequency in hertz

    //     // Configure gain (volume)
    //     gainNode.gain.setValueAtTime(0.3, audioContext.currentTime); // Start at 10% volume

    //     // Start and stop the beep
    //     oscillator.start(audioContext.currentTime);
    //     oscillator.stop(audioContext.currentTime + 0.1); // Beep duration: 150ms

    //     // Cleanup
    //     setTimeout(() => {
    //       gainNode.disconnect();
    //       oscillator.disconnect();
    //     }, 200);
    //   } catch (error) {
    //     console.error("Error creating beep:", error);
    //   }
    // };

    useEffect(() => {
      if (timerPhase !== "pre-preparation" || preparationTimer <= 0) return;

      const intervalId = setInterval(() => {
        setPreparationTimer((prev) => {
          if (prev <= 1) {
            setTimerPhase("preparation");
            return 0;
          }
          return prev - 1;
        });
      }, 1000);

      return () => clearInterval(intervalId);
    }, [preparationTimer, timerPhase]);

    // Timer logic for preparation phase
    // useEffect(() => {
    //   if (timerPhase !== "preparation" || preparationTimer <= 0) return;

    //   const intervalId = setInterval(() => {
    //     setPreparationTimer((prev) => {
    //       if (prev === 2) {
    //         createBeep();
    //       }
    //       if (prev <= 1) {
    //         setTimerPhase("recording");
    //         startListening();
    //         return 0;
    //       }
    //       return prev - 1;
    //     });
    //   }, 1000);

    //   return () => clearInterval(intervalId);
    // }, [preparationTimer, timerPhase]);

    // useEffect(() => {
    //   if (startRecording === true) {
    //     createBeep();
    //     setTimerPhase("recording");
    //     setTimeout(() => {
    //       startListening();
    //     }, 1000);
    //   }
    // }, [timer, startRecording]);

    // Timer logic for recording phase
    useEffect(() => {
      if (timerPhase !== "recording" || !isListening) return;

      const intervalId = setInterval(() => {
        setRecordingTimer((prev) => {
          if (prev <= 1) {
            stopListening();
            setTimerPhase("completed");
            setTimerVisible(false); // Hide timer when recording ends
            return 0;
          }
          return prev - 1;
        });
      }, 1000);

      return () => clearInterval(intervalId);
    }, [recordingTimer, timerPhase, isListening]);

    // Initialize Speech Recognition
    useEffect(() => {
      if ("webkitSpeechRecognition" in window) {
        recognitionRef.current = new window.webkitSpeechRecognition();
        recognitionRef.current.continuous = true;
        recognitionRef.current.interimResults = true;
        recognitionRef.current.lang = "en-US";

        recognitionRef.current.onresult = (event) => {
          console.log(event, "result");
          const transcript = Array.from(event.results)
            .map((result) => result[0].transcript)
            .join(" ")
            .trim();
          const newSpokenWords = transcript.split(/\s+/).map(normalizeWord);
          setSpokenWords(newSpokenWords);
          analyzeWordAccuracy(newSpokenWords);
        };

        recognitionRef.current.onerror = (event) => { };

        recognitionRef.current.onend = () => {
          // Restart recognition if we're still in recording phase
          if (timerPhase === "recording" && isListening) {
            recognitionRef.current.start();
          }
        };
      }

      return () => {
        if (recordingTimeoutRef.current) {
          clearTimeout(recordingTimeoutRef.current);
        }
      };
    }, []);

    const normalizeWord = (word) => {
      return word
        .toLowerCase()
        .replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, "")
        .trim();
    };

    // Update `originalWordsRef` when `originalText` changes
    useEffect(() => {
      originalWordsRef.current = originalText.split(/(\s+)/);
      setWordStatus({});
      setSpokenWords([]);
      setScores({
        overall:0,
        pronunciation: 0,
        fluency: 0,
        content: 0,
        totalScore: 0,
      });
    }, [originalText]);

    const analyzeWordAccuracy = (spokenWords) => {
      const normalizedOriginalWords = originalWordsRef.current
        .filter((word) => /\S/.test(word))
        .map(normalizeWord);

      const newWordStatus = {};
      normalizedOriginalWords.forEach((word, index) => {
        newWordStatus[index] = "missing";
      });

      const matchedIndexes = new Set();
      spokenWords.forEach((spokenWord) => {
        let found = false;
        normalizedOriginalWords.forEach((originalWord, index) => {
          if (!matchedIndexes.has(index) && spokenWord === originalWord) {
            newWordStatus[index] = "correct";
            matchedIndexes.add(index);
            found = true;
          }
        });
        if (!found) {
          newWordStatus[spokenWords.length + normalizedOriginalWords.length] =
            "incorrect";
        }
      });

      setWordStatus(newWordStatus);
      calculateScores(newWordStatus);
    };

    const calculateScores = (wordStatus) => {
      const normalizedOriginalWords = originalWordsRef.current.filter((word) =>
        /\S/.test(word)
      );
      const totalWords = normalizedOriginalWords.length || 1; // Avoid division by zero

      const correctWords = Object.values(wordStatus).filter(
        (status) => status === "correct"
      ).length;

      const pronunciationScore = Math.round((correctWords / totalWords) * 60);

      const spokenWordsCount = Object.values(wordStatus).filter(
        (status) => status === "correct" || status === "incorrect"
      ).length;

      const fluencyScore = Math.round(
        Math.min(1, spokenWordsCount / totalWords) * 70
      );

      const contentScore = Math.round((correctWords / totalWords) * 80);

      // Calculate total raw score (out of 255)
      const totalScore = pronunciationScore + fluencyScore + contentScore;

      // Normalize total score to be out of 90
      const scaledTotalScore = Math.round((totalScore / 255) * 90);

      const overallScore = Math.round((pronunciationScore + fluencyScore + contentScore) / 3);

      setScores({
        overall:overallScore,
        pronunciation: pronunciationScore,
        fluency: fluencyScore,
        content: contentScore,
        totalScore: scaledTotalScore, // Adjusted total score out of 90
      });
    };

    const startListening = () => {
      if (recognitionRef.current) {
        try {
          setSpokenWords([]);
          setWordStatus({});
          setIsRecordingStopped(false);
          setRecordedAudio(null);
          setScores({
            overall:0,
            pronunciation: 0,
            fluency: 0,
            content: 0,
            totalScore: 0,
          });
          setIsListening(true);
          // try {
          //   playBeep();
          // } catch (error) {
          //   console.warn('Audio playback failed:', error);
          //   provideAlternativeNotification();
          // }
          setPreparationTimer(0);
          setTimerPhase("recording");
          setRecordingTimer(15);
          recognitionRef.current.start();

          // Set timeout to stop recording after 40 seconds
          recordingTimeoutRef.current = setTimeout(() => {
            stopListening();
          }, 40000);
        } catch (error) {
          console.log(error);
          // alert(
          //   "Could not start speech recognition. Please check microphone permissions."
          // );
        }
      }
    };

    const stopListening = () => {
      if (recognitionRef.current) {
        recognitionRef.current.stop();
        setIsListening(false);
        setIsRecordingStopped(true);
        setTimerPhase("completed");

        if (recordingTimeoutRef.current) {
          clearTimeout(recordingTimeoutRef.current);
        }
      }
    };

    const onStop = (recordedBlob) => {
      setRecordedAudio(recordedBlob);
    };

    const [color, setColor] = useState(false);

    const [checked, setChecked] = useState(
      SubCategory === "Repeat Sentence" ||
        SubCategory === "Retell Lecture" ||
        SubCategory === "Short Answer"
        ? false
        : true
    );

    const handleToggle = (event) => {
      setChecked(event.target.checked);
    };

    useImperativeHandle(ref, () => ({
      handleSubmitAnalysis: async () => {
        if (!recordedAudio) {
          // alert("No recording available to analyze.");
          return;
        }

        try {
          const formData = new FormData();

          formData.append("fullTestId", fullTestId);
          formData.append("currentQuestionId", id);
          formData.append("ansfile", recordedAudio.blob);

          formData.append("module", module[0]);
          formData.append("sub_module", sub_module[0]);
          const score = {
            // category: Category,
            total: total,
            overall: scores.overall,
            content: scores.content,
            pronunciation: scores.pronunciation,
            fluency: scores.fluency,
            text: spokenWords.join(" "),
          };
          const text = spokenWords.join(" ");
          formData.append("student_ans", text);
          formData.append("score", JSON.stringify(score));

          const response = await postApi(
            API_PATH.MOCK_TEST.SUBMIT_MOCK_TEST_ANS,
            formData
          );

          if (response.status === 201 || response.status === 200) {
            // alert("data aagya");
            setOpenAnalysisModal(true);
            setColor(true);
          } else {
            alert("Failed to analyze the recording. Please try again.");
          }
        } catch (error) {
          alert("An error occurred while analyzing the recording.");
        }
      },
    }));

    const handleAudioComplete = () => {
      // setStartRecording(true);
      // setTimer(15);
      // createBeep();
      setTimerPhase("recording");
      setTimeout(() => {
        startListening();
      }, 1000);
      // setIsListening(true);
      // setPreparationTimer(15);
    };

    return (
      <CardContent
        sx={{
          paddingX: "0px",
          width: "45%",
          border: "1px solid grey",
          borderRadius: "8px",
        }}
      >
        <RepeatParagraphAloud
          onAudioComplete={handleAudioComplete}
          // audioText={JSON.stringify(questionDataApi.audio_text)}
          audioFile={audioFile}
          delay={3}
        />

        <ReactMic
          record={isListening}
          visualSetting="none"
          className="hidden d-none"
          onStop={onStop}
          mimeType="audio/webm"
        />

        {!isRecordingStopped && (
          <Box sx={{ textAlign: "center" }}>
            <Typography>Voice Recorder</Typography>
          </Box>
        )}
        {timerPhase !== "completed" && (
          <Typography
            variant="h6"
            sx={{ textAlign: "center", mb: 3, color: "black" }}
          >
            {timerPhase === "preparation"
              ? `Preparation time :  Be ready recording will start after beep`
              : timerPhase === "recording"
                ? `Recording time : ${recordingTimer} sec`
                : ""}


          </Typography>
        )}
        {timerPhase === "completed" && <Typography
          variant="h6"
          sx={{ textAlign: "center", mb: 3, color: "black" }}
        >
          Recording Done
        </Typography>}
        {/* {isRecordingStopped && recordedAudio && (
        <Typography
          variant="h6"
          sx={{ textAlign: "center", mb: 3, color: "Red" }}
        >
          Recorded
        </Typography>
      )} */}
      </CardContent>
    );
  }
);

export default PteSpeakingPractice;
