import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  IconButton,
  Container,
  Paper,
  MenuItem,
  Select,
  Modal,
  Button,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { getApi } from "services/axiosInstance";
import { API_PATH } from "services/apipath";
import AudioPlayer from "layouts/listening/AudioPlayer";
import { Send } from "@mui/icons-material";
import { postApi } from "services/axiosInstance";
import RepeatParagraphAloud from "components/AudioPlayer/AudioPlayer";

const searchItems = [
  { id: "SA #82", attempted: 0 },
  { id: "SA #81", attempted: 0 },
  { id: "SA #80", attempted: 0 },
  { id: "SA #78", attempted: 0 },
  { id: "SA #77", attempted: 0 },
];

const SingleAnswerListeningMock = ({ questionData, onNext }) => {

  const lastId = localStorage.getItem("lastId");
  const location = useLocation();
  let fullTestId = location.pathname.split("/")[2];
  const params = new URLSearchParams(location.search);
  const Category = params.get("category");
  let id = location.pathname.split("/")[2];

  const navigate = useNavigate();

  const findIndex = () => {
    const questionIndex = questionData.questions?.findIndex(
      (question) => question.id === id
    );

    setCurrentIndex(questionIndex);
    setCurrentQuestion(questionIndex + 1);
    if (questionIndex === -1) {
    }
  };

  useEffect(() => {
    if (questionData) {
      findIndex();
    }
  }, [questionData]);

  const [currentQuestion, setCurrentQuestion] = useState(1);
  const [openSearchModal, setOpenSearchModal] = useState(false);
  const [recordedAudio, setRecordedAudio] = useState(null);
  const [choices, setChoices] = useState([]);
  const [openAnalysisModal, setOpenAnalysisModal] = useState(false);
  const [scores, setScores] = useState({
    content: 0,
    pronunciation: 0,
    fluency: 0,
    overall: 0,
    total: 0
  });

  const [questionid, setQuestionId] = useState();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [questionDataApi, setQuestionDataApi] = useState([]);

  useEffect(() => {
    setQuestionDataApi(questionData);
  }, [questionData]);

  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [submitedAns, setSubmitedAns] = useState(null);

  const handleAnswerSelect = (index) => {
    setSelectedAnswer(index);
  };

  const handleSubmit = async () => {
    const choiceLabels = ["A", "B", "C", "D"];

    // Find the correct answer choice
    const correctChoice = questionDataApi.choices.findIndex(
      (choice) => choice.is_correct
    );
    const correctAnswerLabel = choiceLabels[correctChoice];
  
    // Get the selected answer label
    const selectedAnswerLabel = choiceLabels[selectedAnswer];
  
    // Determine the overall score: 1 if correct, 0 if incorrect
    const overallScore = correctAnswerLabel === selectedAnswerLabel ? 1 : 0;
  
    // Update the scores state
    setScores({
      content: 0,      
      pronunciation: 0, 
      fluency: 0,       
      overall: overallScore,
      total: 1,
    });
  
    const newScores = {
      content: 0,
      pronunciation: 0,
      fluency: 0,
      overall: overallScore,
      total: 1,
    };

    const payload = {
      fullTestId: fullTestId,
      currentQuestionId: questionData._id,
      student_ans: JSON.stringify(selectedAnswer),
      module: questionData.module_type[0],
      sub_module: questionData.module_subcategory[0],
      score: JSON.stringify(newScores)
    }

    try {
      const answer = await postApi(
        API_PATH.MOCK_TEST.SUBMIT_MOCK_TEST_ANS,
        payload
      );
      if (answer.status === 200) {
        setSubmitedAns(null)
        setSelectedAnswer(null)
        onNext();
      }
    } catch (error) { }
  };

  useEffect(() => {
    return () => {
      if (recordedAudio?.url) {
        URL.revokeObjectURL(recordedAudio.url);
      }
    };
  }, []);

  const handleRecordingComplete = (recording) => {
    setRecordedAudio(recording);

    if (recording.scores) {
      setScores({
        content: recording.scores.content,
        pronunciation: recording.scores.pronunciation,
        fluency: recording.scores.fluency,
        overall: Math.round(
          (recording.scores.content +
            recording.scores.pronunciation +
            recording.scores.fluency) /
          3
        ),
      });
    }
  };

  return (
    <Box sx={{ p: 2 }}>
      <Container
        maxWidth="lg"
        sx={{
          // pt: "20px",
          p: 2,
        }}
      >
        {/* Added Instructions Text */}
        <Typography
          sx={{
            color: "#000",
            mt: 0,
            mb: 1,
            fontSize: "15px",
            fontStyle: "italic",
          }}
        >
          Listen to the recording and answer the multiple-choice question by
          selecting the correct response. Only one response is correct.
        </Typography>

        {/* Instructions */}
        <Paper sx={{ p: 1, my: 1, color: "#000", boxShadow: "none" }}>
          <Typography>{questionDataApi.question_text}</Typography>
        </Paper>

        {/* <Aution Text /> */}
        {/* <AudioPlayer
          audioText={JSON.stringify(questionDataApi.audio_text)}
          delay={3}
        /> */}

        <RepeatParagraphAloud
          // onAudioComplete={handleAudioComplete}
          audioText={JSON.stringify(questionDataApi.audio_text)}
          audioFile={questionDataApi.audio_file}
          delay={3}
        />

        {/* Answer Options */}
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          {questionDataApi.choices?.map((choice, index) => (
            <Box
              key={index}
              onClick={() => {
                submitedAns === null && handleAnswerSelect(index);
              }}
              sx={{
                bgcolor:
                  submitedAns === null // Before submission (no color change yet)
                    ? selectedAnswer === index // If this option is selected
                      ? "#00BFA6" // Keep selected option highlighted (light blue)
                      : "rgba(212, 212, 212, 0.8)" // Default neutral color for non-selected answers
                    : // After submission, update colors based on correctness
                    selectedAnswer === index // If this option is selected by the user
                      ? submitedAns && choice.is_correct
                        ? "green" // Correct answer: green
                        : "red" // Wrong answer: red
                      : choice.is_correct // Highlight correct answer
                        ? "green" // Correct answer highlighted in green
                        : "rgba(52, 58, 64, 0.8)", // Neutral color for other unselected answers
                p: 2.5,
                borderRadius: "8px",
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                transition: "all 0.2s ease",
                "&:hover": {
                  bgcolor:
                    submitedAns === null
                      ? selectedAnswer === index // If option is selected, it will remain highlighted on hover
                        ? "#00BFA6" // Keep selected option hover color same
                        : "rgba(52, 58, 64, 0.9)" // Darken other options on hover
                      : selectedAnswer === index // If this option is selected by the user
                        ? submitedAns && choice.is_correct
                          ? "green" // Correct answer: green
                          : "red" // Wrong answer: red
                        : choice.is_correct // Highlight correct answer
                          ? "green" // Correct answer highlighted in green
                          : "rgba(52, 58, 64, 0.8)", // Default hover color after submission (for non-selected)
                },
              }}
            >
              <Typography sx={{ fontSize: "14px", color: "black" }}>
                {choice.choice_text}
              </Typography>
            </Box>
          ))}
        </Box>
      </Container>
      <Container>
        <Box
          sx={{
            // p: 2,
            mt: 3,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            verticalAlign: "middle",
          }}
        >
          <Button
            variant="outlined"
            sx={{ color: "black !important" }}
            onClick={() => navigate("/mocktest")}
          >
            Save & Exit
          </Button>

          <Box
            sx={{ display: "flex", alignItems: "center", gap: 1, ml: "auto" }}
          >
            <Button
              variant="contained"
              sx={{ color: "white !important" }}
              endIcon={<Send />}
              onClick={handleSubmit}
            >
              {lastId === "true" ? "Submit" : "Next"}
            </Button>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default SingleAnswerListeningMock;
